import { useEffect } from 'react';

const ScrollToTopOnMount = () => {
    useEffect(() => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return null;
};

export default ScrollToTopOnMount;
