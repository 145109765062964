import React, { useEffect } from 'react';

import { 
    LessonPreview,
    Page
} from 'components';

import { Button, Loader } from 'UI';

import LessonsPageHeader from './LessonsPageHeader';

import { useDispatch, useSelector } from 'react-redux';
import { selectDifficultyLevels, selectLessonsCategories, selectLessonsData, selectLessonsProgress, selectLessonsNoMoreData, selectTeachers } from 'store/strapi/selector';
import { getDifficultyLevels, getLessons, getLessonsCategories, getMoreLessons, getTeachers } from 'store/strapi/actions';

// const LIMIT = 24;

const LessonsPage = () => {
    const dispatch = useDispatch();
    const lessons = useSelector(selectLessonsData);
    const inProgress = useSelector(selectLessonsProgress);
    const difficultyLevels = useSelector(selectDifficultyLevels);
    const teachers = useSelector(selectTeachers);
    const lessonsCategories = useSelector(selectLessonsCategories);
    const noMoreData = useSelector(selectLessonsNoMoreData);

    useEffect(() => {
        if (!lessons) {
            dispatch(getLessons());
        }
        if (!difficultyLevels) {
            dispatch(getDifficultyLevels());
        }
        if (!teachers) {
            dispatch(getTeachers());
        }
        if (!lessonsCategories) {
            dispatch(getLessonsCategories());
        }
    }, []);

    return (
        <Page wrapperClassName="flex flex-col flex-auto" className="flex-grow bg-fake-black px-4 pb-8" 
            withPartials title="Lessons">
            <Loader loading={inProgress ? inProgress : false} />
            <LessonsPageHeader />
            <ul className="flex flex-col py-4 gap-4 items-center">
                {lessons?.map((lesson, i) => {
                    return (
                        <li key={i}>
                            <LessonPreview lesson={lesson} />
                        </li>
                    );
                })}
            </ul>
            { !noMoreData ? <Button 
                size="xl"
                weight="medium"
                clicked={() => dispatch(getMoreLessons())}
                className="mt-4">Load more</Button> : null }
        </Page>
    );    
};

export default LessonsPage;
