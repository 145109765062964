import { useState, useEffect } from 'react';
import { Button, Head } from 'UI';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'store/util/actions';

const CookiesBanner = () => {
    const [ isVisible, setIsVisible ] = useState(typeof localStorage._k_sqr_cookies_sb === 'undefined');
    const [ style, setStyle ] = useState({
        bottom: '-100vh'
    });
    const dispatch = useDispatch();

    const setAcceptCookies = (value) => {
        const consent =  localStorage.getItem('_k_sqr_c_acc');
        const firstUse = typeof localStorage.getItem('_k_sqr_cookies_sb') === 'undefined';

        localStorage.setItem('_k_sqr_c_acc', JSON.stringify({
            statistics: value,
            media: value
        }));

        localStorage.setItem('_k_sqr_cookies_sb', false);
        
        if ((firstUse && value === true) || (!firstUse && consent && consent.statistics !== value)) {
            window.location.reload();
        } else {
            setIsVisible(false);
        }
    };

    const showBanner = () => {
        document.body.style.overflow = 'hidden';
        setStyle({
            bottom: '0'
        });
    };

    const hideBanner = () => {
        setStyle({
            bottom: '-100vh'
        });
    };

    useEffect(() => {
        if (!isVisible) {
            hideBanner();
        } else {
            setTimeout(showBanner, 1000);
        }
        return () => (document.body.style.overflow = 'unset');
    }, [isVisible]);

    return (
        <div className="fixed bottom-0 bg-primary text-white p-4 z-40 transition-all duration-800 w-full" 
            style={style}>
            <div className="pb-5">
                <Head level={5}>We use cookies on this website to enhance your experience</Head>
                <p>Cookies are collected for analytics and metrics about our visitors. To find out more about the cookies we use, see our <Button noStyle={true} className="underline" clicked={() => dispatch(toggleModal('privacy'))}>Privacy Policy</Button>.</p>
            </div>
            <div className="flex justify-evenly items-center">
                <Button
                    clicked={() => setAcceptCookies(false)}
                    textColor="primary"
                    bgColor="white"
                >
                    No, thanks
                </Button>
                <Button
                    clicked={() => setAcceptCookies(true)}
                    textColor="primary"
                    bgColor="white"
                >
                    Yes, I accept
                </Button>
            </div>
        </div>
    );
};

export default CookiesBanner;
