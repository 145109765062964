import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getSingleArticleRequest } from 'store/strapi/actions';
import { getSingleArticleSelector, getAllArticlesSelector } from 'store/strapi/selector';
import { toast } from 'react-toastify';

import { 
    Page
} from 'components';

import {
    Head,
    Text,
    Loader
} from 'UI';

import { formatDateFromISO } from 'shared/date';
import { ReactComponent as Clock } from 'assets/icons/common/clock.svg';
import { ReactComponent as ClockDark } from 'assets/icons/common/clock_dark.svg';

const Article = ({ match }) => {
    const dispatch = useDispatch();
    const article = useSelector(getSingleArticleSelector, shallowEqual);
    const { inProgress, error } = useSelector(getAllArticlesSelector, shallowEqual);
    const { id: articleID } = useParams();
    const [imageStyle, setImageStyle] = useState({});
    const imageVariation = 'medium';

    useEffect(() => {
        dispatch(getSingleArticleRequest(articleID));
    }, [articleID]);

    useEffect(() => {
        setImageStyle({
            backgroundImage: `url(${article?.hero_image.formats[imageVariation].url})`
        });
    }, [article]);

    useEffect(() => {
        if (error) {
            toast.error({ 
                type: 'article', 
                action: 'fetch',
                key: 'one',
            });
        }
    }, [error]);

    return (
        <Page className="flex-grow bg-fake-black" withPartials>
            <Loader loading={inProgress ? inProgress : false} />
            {article &&
                <>
                    <section className="px-8 pt-6 pb-20">
                        <Head size="md" weight="medium">{article.title}</Head>
                        <div className="pt-2">
                            <Text size="xs" weight="medium">
                                Written by {article.author?.name} • {formatDateFromISO(article.date_posted)} •
                            </Text>
                            <Text size="xs" weight="normal" className="opacity-60">
                                <Clock className="inline" /> {article.time_to_read} min
                            </Text>
                        </div>
                    </section>

                    <section className="mt-6 mb-8 mx-8 relative">
                        <div className="absolute h-40 -top-20 w-full bg-no-repeat bg-cover bg-center" style={imageStyle}></div>
                    </section>

                    <section className="p-8 bg-white pt-24 text-black flex flex-col gap-8">
                        <Head level="2" size="lg" weight="medium">{article.lede}</Head>
                        <Text textColor="black" size="xl" className="leading-8 rich-text">
                            <ReactMarkdown
                                escapeHtml={false}>{article.content}</ReactMarkdown>
                        </Text>
                    </section>

                    {article.author &&
                        <section className="bg-white px-8 pt-8 pb-16 text-black">
                            <div className="flex gap-3 items-center mb-6">
                                <div className="h-12 w-12 bg-cover bg-center rounded-full" style={{backgroundImage: `url(${article.author?.avatar.formats.thumbnail.url})`}}></div>
                                <div className="flex flex-col gap-1">
                                    <Text size="xs" weight="medium" textColor="black">
                                        Written by {article.author.name}
                                    </Text>
                                    <Text size="xs" weight="normal" textColor="black" opacity="light">
                                        {formatDateFromISO(article.date_posted)} • <ClockDark className="inline" aria-hidden="true" /> {article.time_to_read} min
                                    </Text>
                                </div>
                            </div>
                            {article.author.bio &&
                                <Text size="md" textColor="black" opacity="light">
                                    {article.author.bio}
                                </Text>
                            }
                        </section>
                    }
                </>
            }
        </Page>
    );
};

export default Article;
