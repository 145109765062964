import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

import { ReactComponent as ChevronBottom } from 'assets/icons/arrows/arrow_bot.svg';
import { Text } from 'UI';

const Panel = ({
		label,
		activeTab,
		index,
		activateTab,
		content,
	}) => {

	const isActive = activeTab === index;
	const [style, setStyle] = useState({
		height: 0,
	});
	const wrapper = useRef(null);

	useEffect(() => {
		const timeout = window.setTimeout(() => {
			const height = wrapper.current.querySelector('#panel').scrollHeight;
			setStyle({
				height,
			});
		}, 333);

		return (() => {
			clearTimeout(timeout);
		});
	}, []);

	return (
		<div role='tabpanel' className="overflow-hidden border-t border-grey-dark" ref={wrapper}>
			<span role='tab' onClick={activateTab} className="flex items-center justify-between" aria-expanded={isActive}>
				<Text size="md" className="py-4">{label}</Text>
				<ChevronBottom className={`transition-all${isActive ? ' transform rotate-180' : ''}`} />
			</span>
			<div
				id='panel'
				style={{ height: `${isActive ? style.height : 0}px` }}
				className={`transition-all transform origin-top-center ${isActive ? ' scale-y-1' : ' scale-0'}`}
				aria-hidden={!isActive}
			>
				<div>
					{content}
				</div>
			</div>
		</div>
	);
};

Panel.propTypes = {
	label: PropTypes.string,
	content: PropTypes.any.isRequired,
	activeTab: PropTypes.number,
	index: PropTypes.number,
	activateTab: PropTypes.func,
};

export default Panel;
