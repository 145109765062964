import { withRouter, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getLoginRequest } from 'store/user/selector';
import { getHomeRequest } from'store/strapi/actions';
import { getHomePageData } from'store/strapi/selector';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getIsGuest } from 'shared/localStorage';
import { toggleModal } from 'store/util/actions';

import { 
    MASTERCLASS_URLS,
    ARTICLES_URLS,
    LESSONS_URLS,
    PROGRAMS_URLS
} from 'shared/urlList';

import { 
    Head, 
    Text, 
    Carousel,
    CarouselHome,
    ButtonLink,
    Loader,
    Button
} from 'UI';

import {
    ArticlePreview,
    MasterclassPreview,
    LessonPreview,
    DocumentaryPreview,
    JourneyPreview,
    Page,
    DownloadApp,
    ProgramCarouselPreview
} from 'components';

const Home = ({ ...props }) => {
    const dispatch = useDispatch();
    const isGuest = getIsGuest();
    const { homeState, data: homeData } = useSelector(getHomePageData, shallowEqual);
    const { isSuccessful: loginIsSuccessful } = useSelector(getLoginRequest, shallowEqual);
    const [width] = useState(window.innerWidth);
    const [masterclass, setMasterclass] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [documentaries, setDocumentaries] = useState([]);
    const [journeys, setJourneys] = useState([]);
    const [articles, setArticles] = useState([]);
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
        if (!homeState || !homeState.data) {
            fetchHomeHub();
        }
    }, []);

    useEffect(() => {
        if (loginIsSuccessful) {
            fetchHomeHub();
        }
    }, [loginIsSuccessful]);

    const fetchHomeHub = async () => {
        dispatch(getHomeRequest());
    };

    const setHomeData = (data) => {
        data.sections.forEach(section => {
            if (section.target_type) {
                switch(section.target_type) {
                    case 'masterclass_episodes':
                        setMasterclass(section.items);
                        break;
                    case 'lessons':
                        setLessons(section.items);
                        break;
                    case 'documentaries':
                        setDocumentaries(section.items);
                        break;
                    case 'journeys':
                        setJourneys(section.items);
                        break;
                    case 'news':
                        if (section.byRanking && section.byRanking.data) {
                            setArticles(section.byRanking?.data);
                        }
                        break;
                    case 'programs':
                        setPrograms(section.items);
                        break;
                    default:
                }
            }
        });
    };

    useEffect(() => {
        if (homeData) {
            setHomeData(homeData);
        }
    }, [homeData]);
 
    return (
        <Page className="flex-grow relative" title="Home" withPartials>
            <Loader loading={homeState.inProgress ? homeState.inProgress : false} isFixed={true} />
            <div className="absolute top-0 right-0 left-0 z-0">
                <CarouselHome screenWidth={width}/>
            </div>
            <div className="relative z-5 bg-gradient-home mt-60">
                <section className="pb-8 px-6 text-center">
                    <Head size="3xl" weight="medium" className="mb-2">
                        Learn from the best
                    </Head>
                    {isGuest &&
                        <Button clicked={() => dispatch(toggleModal('register'))} size="lg"
                            weight="medium">Sign up</Button>
                    }
                </section>

                <section className="pb-6">
                    <div className="px-8">
                        <Head level="2" size="xl" className="my-2" weight="medium">Masterclass</Head>
                        <Text size="md" className="mt-2 mb-8">
                            Garry Kasparov shares with you the knowledge that made him one of the greatest players of all times. 
                        </Text>
                    </div>
                    <div className="pl-8">
                        {masterclass && masterclass.length &&
                            <Carousel screenWidth={width} alignToLeft={true}>
                                {masterclass.map((masterclass, i) => {
                                    return (<MasterclassPreview masterclass={masterclass} key={i} />);
                                })}
                            </Carousel>
                        }
                        <Link to={MASTERCLASS_URLS.ALL} className="block text-primary text-md mt-8 mb-10">See all ></Link>
                    </div>
                </section>

                {programs && programs?.length > 0 &&
                    <section className="bg-fake-black pt-8 pb-6">
                        <Head level="2" size="xl" className="mt-2 mb-4 px-8 text-center" weight="medium">Develop your personal playing style</Head>
                        <div className="pl-8">
                            <Carousel screenWidth={width} alignToLeft={true}>
                                {programs.map((program, i) => {
                                    return (<ProgramCarouselPreview program={program} key={i} headingLevel={3} />);
                                })}
                            </Carousel>
                        </div>
                        <ButtonLink size="lg" rounded="md" to={PROGRAMS_URLS.ALL} weight="medium">Discover all programs</ButtonLink>
                    </section>
                }

                <section className="bg-fake-black pt-8 pb-6">
                    <Head level="2" size="xl" className="mt-2 mb-4 px-8" weight="medium">Lessons library</Head>
                    <div className="pl-8">
                        <Carousel screenWidth={width} alignToLeft={true}>
                            {lessons.map((lesson, i) => {
                                return (<LessonPreview lesson={lesson} key={i} headingLevel={3} className="mr-5" />);
                            })}
                        </Carousel>
                    </div>
                    <Link to={LESSONS_URLS.ALL} className="block text-primary text-md mt-8 mb-10 px-8">See all ></Link>
                </section>

                <section className="pt-8 pb-6">
                    <div className="mx-8">
                        <Head level="2" size="xl" className="my-5" weight="medium">Watch</Head>
                        <Head level="3" size="md" weight="medium" className="my-5">Documentaries</Head>
                    </div>
                    <div className="ml-8">
                        <Carousel screenWidth={width}>
                            {documentaries.map((documentary, i) => {
                                return (<DocumentaryPreview documentary={documentary} key={i} headingLevel={4} />);
                            })}
                        </Carousel>
                    </div>
                </section>

                <section className="pt-8 pb-6 bg-fake-black">
                    <div className="mx-8">
                        <Head level="3" size="md" weight="bold" className="my-5">Chess journeys</Head>
                    </div>
                    <div className="ml-8">
                        <Carousel screenWidth={width}>
                            {journeys.map((journey, i) => {
                                return (<JourneyPreview journey={journey} key={i} headingLevel={4} />);
                            })}
                        </Carousel>
                    </div>
                </section>

                <section className="pt-8 pb-14 mx-8">
                    <Head level="2" size="md" weight="bold" className="my-5">In the news</Head>
                    <div>
                        {articles.map((article, i) => {
                            return (<ArticlePreview article={article} key={i} headingLevel={3} />);
                        })}
                    </div>
                    <ButtonLink size="lg" rounded="md" to={ARTICLES_URLS.ALL} weight="medium">Discover all the news</ButtonLink>
                </section>

                <section className="p-4 text-center bg-white">
                    <DownloadApp headingLevel={2} />
                </section>
            </div>
        </Page>
    );
};

export default withRouter(Home);
