import React from 'react';

import { Head, Text } from 'UI';

const Terms = (props) => {
    return (
        <section className="py-8 px-4 flex flex-col gap-3 break-words">
            <div>
                <Head level={2} size="md" weight="medium" className="text-center">Terms and conditions</Head>
                <Text tagName="p">These terms and conditions ("Agreement") sets forth the general terms and conditions of your use of the kasparovchess.com website ("Website" or "Service") and any of its related products and services (collectively, "Services"). This Agreement is legally binding between you ("User", "you" or "your") and KEYSQUARE ("KEYSQUARE", "we", "us" or "our"). By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms "User", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Website and Services. You acknowledge that this Agreement is a contract between you and KEYSQUARE, even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Accounts and membership</Head>
                <Text tagName="p">You must be at least 18 years of age to use the Website and Services. By using the Website and Services and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age. If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">User content</Head>
                <Text tagName="p">We do not own any data, information or material (collectively, "Content") that you submit on the Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may monitor and review the Content on the Website submitted or created using our Services by you. You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable. You also grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Billing and payments</Head>
                <Text tagName="p">You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. Where Services are offered on a free trial basis, payment may be required after the free trial period ends, and not when you enter your billing details (which may be required prior to the commencement of the free trial period). If auto-renewal is enabled for the Services you have subscribed for, you will be charged automatically in accordance with the term you selected. Sensitive and private data exchange happens over a SSL secured communication channel and is encrypted and protected with digital signatures, and the Website and Services are also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users. Scans for malware are performed on a regular basis for additional security and protection. If, in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Accuracy of information</Head>
                <Text tagName="p">Occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, availability, promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Website or Services is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or Services has been modified or updated.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Backups</Head>
                <Text tagName="p">We perform regular backups of the Website and its Content, however, these backups are for our own administrative purposes only and are in no way guaranteed. You are responsible for maintaining your own backups of your data. We do not provide any sort of compensation for lost or incomplete data if backups do not function properly. We will do our best to ensure complete and accurate backups but assume no responsibility for this duty.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Advertisements</Head>
                <Text tagName="p">During your use of the Website and Services, you may enter correspondence with or participate in promotions of advertisers or sponsors showing their goods or services through the Website and Services. Any such activity, and any terms, conditions, warranties, or representations associated with such activity, is solely between you and the applicable third party. We shall have no liability, obligation, or responsibility for any such correspondence, purchase or promotion between you and any such third party.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Links to other resources</Head>
                <Text tagName="p">Although the Website and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. Some of the links on the Website may be "affiliate links". This means if you click on the link and purchase an item, KEYSQUARE will receive an affiliate commission. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Website and Services. Your linking to any other off-site resources is at your own risk.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Prohibited uses</Head>
                <Text tagName="p">In addition to other terms as set forth in the Agreement, you are prohibited from using the Website and Services or Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Website and Services, third party products and services, or the Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the Website and Services, third party products and services, or the Internet. We reserve the right to terminate your use of the Website and Services for violating any of the prohibited uses.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Intellectual property rights</Head>
                <Text tagName="p">"Intellectual Property Rights" means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world. This Agreement does not transfer to you any intellectual property owned by KEYSQUARE or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with KEYSQUARE. All trademarks, service marks, graphics and logos used in connection with the Website and Services, are trademarks or registered trademarks of KEYSQUARE or its licensors. Other trademarks, service marks, graphics and logos used in connection with the Website and Services may be the trademarks of other third parties. Your use of the Website and Services grants you no right or license to reproduce or otherwise use any of KEYSQUARE or third-party trademarks.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Disclaimer of warranty</Head>
                <Text tagName="p">You agree that such Service is provided on an "as is" and "as available" basis and that your use of the Website and Services is solely at your own risk. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage or loss of data that results from the download of such material and/or data. We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service unless stated otherwise. No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Limitation of liability</Head>
                <Text tagName="p">To the fullest extent permitted by applicable law, in no event will KEYSQUARE, its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of KEYSQUARE and its affiliates, officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to KEYSQUARE for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Indemnification</Head>
                <Text tagName="p">You agree to indemnify and hold KEYSQUARE and its affiliates, directors, officers, employees, agents, suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Website and Services or any willful misconduct on your part.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Severability</Head>
                <Text tagName="p">All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Changes to the Services</Head>
                <Text tagName="p">We reserve the right to enhance, upgrade, improve, discontinue or modify our Services as we deem appropriate and at our discretion. We may offer additional features to our standard services or enhancements to pro features at an additional cost. If we discontinue services, we will give you 90 days prior notice. After that time your subscription to the service will be terminated and we will refund any unapplied, prepaid fees on a pro rata basis.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Dispute resolution</Head>
                <Text tagName="p">The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of France without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of France. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in France, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Assignment</Head>
                <Text tagName="p">You may not assign, resell, sub-license or otherwise transfer or delegate any of your rights or obligations hereunder, in whole or in part, without our prior written consent, which consent shall be at our own sole discretion and without obligation; any such assignment or transfer shall be null and void. We are free to assign any of its rights or obligations hereunder, in whole or in part, to any third party as part of the sale of all or substantially all of its assets or stock or as part of a merger.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Changes and amendments</Head>
                <Text tagName="p">We reserve the right to modify this Agreement or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Agreement on the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website and Services after any such changes shall constitute your consent to such changes.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Acceptance of these terms</Head>
                <Text tagName="p">You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Website and Services.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Contacting us</Head>
                <Text tagName="p">If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may do so via the contact form</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Refund policy</Head>
                <Text tagName="p">Since the Website offers non-tangible, irrevocable goods we do not provide refunds after the product is purchased, which you acknowledge prior to purchasing any product on the Website.</Text>
                <Text tagName="p">You may however sign up for a free 7-day trial and try the service before making a purchase.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Contacting us</Head>
                <Text tagName="p">If you would like to contact us concerning any matter relating to this Refund Policy, you may do so via the contact form</Text>
            </div>

            <div>
                <Head level={2} size="md" weight="medium">Disclaimer</Head>
                <Text tagName="p">This disclaimer ("Disclaimer") sets forth the general guidelines, disclosures, and terms of your use of the kasparovchess.com website ("Website" or "Service") and any of its related products and services (collectively, "Services"). This Disclaimer is a legally binding agreement between you ("User", "you" or "your") and KEYSQUARE ("KEYSQUARE", "we", "us" or "our"). By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Disclaimer. If you are entering into this Disclaimer on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Disclaimer, in which case the terms "User", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Disclaimer, you must not accept this Disclaimer and may not access and use the Website and Services. You acknowledge that this Disclaimer is a contract between you and KEYSQUARE, even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Representation</Head>
                <Text tagName="p">Any views or opinions represented on the Website reflect the views and opinions of KEYSQUARE, its affiliates, content creators or employees. Any views or opinions are not intended to malign any religion, ethnic group, club, organization, company, or individual.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Content and postings</Head>
                <Text tagName="p">You may not modify, print or copy any part of the Website and Services. Inclusion of any part of the Website and Services in another work, whether in printed or electronic or another form or inclusion of any part of the Website and Services on another resource by embedding, framing or otherwise without the express permission of KEYSQUARE is prohibited.</Text>
                <Text tagName="p">You may submit new content and comment on the existing content on the Website. By uploading or otherwise making available any information to KEYSQUARE, you grant KEYSQUARE the unlimited, perpetual right to distribute, display, publish, reproduce, reuse, and copy the information contained therein. You may not impersonate any other person through the Website and Services. You may not post content that is defamatory, fraudulent, obscene, threatening, invasive of another person's privacy rights or that is otherwise unlawful. You may not post content that infringes on the intellectual property rights of any other person or entity. You may not post any content that includes any computer virus or other code designed to disrupt, damage, or limit the functioning of any computer software or hardware. By submitting or posting content on the Website, you grant KEYSQUARE the right to edit and, if necessary, remove any content at any time and for any reason.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Compensation and sponsorship</Head>
                <Text tagName="p">The Website and Services accepts forms of advertising. Advertising space will always be identified as such. Some of the links on the Website may be "affiliate links". This means if you click on the link and purchase an item, KEYSQUARE will receive an affiliate commission.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Indemnification and warranties</Head>
                <Text tagName="p">While we have made every attempt to ensure that the information contained on the Website is correct, KEYSQUARE is not responsible for any errors or omissions, or for the results obtained from the use of this information. All information on the Website is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied. In no event will KEYSQUARE, or its partners, employees or agents, be liable to you or anyone else for any decision made or action taken in reliance on the information on the Website, or for any consequential, special or similar damages, even if advised of the possibility of such damages. Information on the Website is for general information purposes only and is not intended to provide any type of professional advice. Please seek professional assistance should you require it. Furthermore, information contained on the Website and any pages linked to and from it are subject to change at any time and without warning.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Changes and amendments</Head>
                <Text tagName="p">We reserve the right to modify this Disclaimer or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Disclaimer on the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website and Services after any such changes shall constitute your consent to such changes.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Acceptance of this disclaimer</Head>
                <Text tagName="p">You acknowledge that you have read this Disclaimer and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Disclaimer. If you do not agree to abide by the terms of this Disclaimer, you are not authorized to access or use the Website and Services.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Contacting us</Head>
                <Text tagName="p">If you would like to contact us to understand more about this Disclaimer or wish to contact us concerning any matter relating to it, you may do so via the contact form.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Acceptable use policy</Head>
                <Text tagName="p">This acceptable use policy ("Policy") sets forth the general guidelines and acceptable and prohibited uses of the kasparovchess.com website ("Website" or "Service") and apps and any of its related products and services (collectively, "Services"). This Policy is a legally binding agreement between you ("User", "you" or "your") and KEYSQUARE ("KEYSQUARE", "we", "us" or "our"). By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms "User", "you" or "your" shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Website and Services. You acknowledge that this Agreement is a contract between you and KEYSQUARE, even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Prohibited activities and uses</Head>
                <Text tagName="p">You may not use the Website and Services to publish content or engage in activity that is illegal under applicable law, that is harmful to others, or that would subject us to liability, including, without limitation, in connection with any of the following, each of which is prohibited under this Policy:</Text>
                <ul>
                    <li>Distributing malware or other malicious code.</li>
                    <li>Disclosing sensitive personal information about others.</li>
                    <li>Collecting, or attempting to collect, personal information about third parties without their knowledge or consent.</li>
                    <li>Distributing pornography or adult related content.</li>
                    <li>Promoting or facilitating prostitution or any escort services.</li>
                    <li>Hosting, distributing, or linking to child pornography or content that is harmful to minors.</li>
                    <li>Promoting or facilitating gambling, violence, terrorist activities or selling weapons or ammunition.</li>
                    <li>Engaging in the unlawful distribution of controlled substances, drug contraband or prescription medications.</li>
                    <li>Managing payment aggregators or facilitators such as processing payments on behalf of other businesses or charities.</li>
                    <li>Facilitating pyramid schemes or other models intended to seek payments from public actors.</li>
                    <li>Threatening harm to persons or property or otherwise harassing behavior.</li>
                    <li>Manual or automatic credit card or other available payment methods testing using bots or scripts.</li>
                    <li>Purchasing any of the offered Services on someone else’s behalf.</li>
                    <li>Misrepresenting or fraudulently representing products or services.</li>
                    <li>Infringing the intellectual property or other proprietary rights of others.</li>
                    <li>Facilitating, aiding, or encouraging any of the above activities through the Website and Services.</li>
                </ul>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">System abuse</Head>
                <Text tagName="p">Any User in violation of the Website and Services security is subject to criminal and civil liability, as well as immediate account termination. Examples include, but are not limited to the following:</Text>
                <ul>
                    <li>Use or distribution of tools designed for compromising security of the Website and Services.</li>
                    <li>Intentionally or negligently transmitting files containing a computer virus or corrupted data.</li>
                    <li>Accessing another network without permission, including to probe or scan for vulnerabilities or breach security or authentication measures.</li>
                    <li>Unauthorized scanning or monitoring of data on any network or system without proper authorization of the owner of the system or network.</li>
                </ul>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Service resources</Head>
                <Text tagName="p">You may not consume excessive amounts of the resources of the Website and Services or use the Website and Services in any way which results in performance issues or which interrupts the Services for other Users. Prohibited activities that contribute to excessive use, include without limitation:</Text>
                <ul>
                    <li>Deliberate attempts to overload the Website and Services and broadcast attacks (i.e. denial of service attacks).</li>
                    <li>Engaging in any other activities that degrade the usability and performance of the Website and Services.</li>
                    <li>Hosting or running malicious code or other scripts or processes that adversely impact the Website and Services.</li>
                    <li>Operating a file sharing site or scripts for BitTorrent or similar, which includes sending or receiving files containing these mechanisms.</li>
                    <li>Web proxy scripts, such as those that allow anyone to browse to a third-party website anonymously, are prohibited.</li>
                </ul>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">No spam policy</Head>
                <Text tagName="p">You may not use the Website and Services to send spam or bulk unsolicited messages. We maintain a zero tolerance policy for use of the Website and Services in any manner associated with the transmission, distribution or delivery of any bulk e-mail, including unsolicited bulk or unsolicited commercial e-mail, or the sending, assisting, or commissioning the transmission of commercial e-mail that does not comply with the U.S. CAN-SPAM Act of 2003 ("SPAM").</Text>
                <Text tagName="p">Your products or services advertised via SPAM (i.e. Spamvertised) may not be used in conjunction with the Website and Services. This provision includes, but is not limited to, SPAM sent via fax, phone, postal mail, email, instant messaging, or newsgroups.</Text>
                <Text tagName="p">Sending emails through the Website and Services to purchased email lists ("safe lists") will be treated as SPAM.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Defamation and objectionable content</Head>
                <Text tagName="p">We value the freedom of expression and encourage Users to be respectful with the content they post. We are not a publisher of User content and are not able to investigate the veracity of individual defamation claims or to determine whether certain material, which we may find objectionable, should be censored. However, we reserve the right to moderate, disable or remove any content to prevent harm to others or to us or the Website and Services, as determined in our sole discretion.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Copyrighted content</Head>
                <Text tagName="p">Copyrighted material must not be published via the Website and Services without the explicit permission of the copyright owner or a person explicitly authorized to give such permission by the copyright owner. Upon receipt of a claim for copyright infringement, or a notice of such violation, we may, at our discretion, run an investigation and, upon confirmation, may remove the infringing material from the Website and Services. We may terminate the Service of Users with repeated copyright infringements. Further procedures may be carried out if necessary. We will assume no liability to any User of the Website and Services for the removal of any such material. If you believe your copyright is being infringed by a person or persons using the Website and Services, please get in touch with us to report copyright infringement.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Security</Head>
                <Text tagName="p">You take full responsibility for maintaining reasonable security precautions for your account. You are responsible for protecting and updating any login account provided to you for the Website and Services. You must protect the confidentiality of your login details, and you should change your password periodically.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Enforcement</Head>
                <Text tagName="p">We reserve our right to be the sole arbiter in determining the seriousness of each infringement and to immediately take corrective actions, including but not limited to:</Text>
                <ul>
                    <li>Suspending or terminating your Service with or without notice upon any violation of this Policy. Any violations may also result in the immediate suspension or termination of your account.</li>
                    <li>Disabling or removing any content which is prohibited by this Policy, including to prevent harm to others or to us or the Website and Services, as determined by us in our sole discretion.</li>
                    <li>Reporting violations to law enforcement as determined by us in our sole discretion.</li>
                    <li>A failure to respond to an email from our abuse team within 2 days, or as otherwise specified in the communication to you, may result in the suspension or termination of your account.</li>
                </ul>
                <Text tagName="p">Suspended and terminated User accounts due to violations will not be re-activated.</Text>
                <Text tagName="p">Nothing contained in this Policy shall be construed to limit our actions or remedies in any way with respect to any of the prohibited activities. We reserve the right to take any and all additional actions we may deem appropriate with respect to such activities, including without limitation taking action to recover the costs and expenses of identifying offenders and removing them from the Website and Services, and levying cancellation charges to cover our costs. In addition, we reserve at all times all rights and remedies available to us with respect to such activities at law or in equity.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Reporting violations</Head>
                <Text tagName="p">If you have discovered and would like to report a violation of this Policy, please contact us immediately. We will investigate the situation and provide you with full assistance.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Changes and amendments</Head>
                <Text tagName="p">We reserve the right to modify this Policy or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Policy on the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website and Services after any such changes shall constitute your consent to such changes.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Acceptance of this policy</Head>
                <Text tagName="p">You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Website and Services.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Contacting us</Head>
                <Text tagName="p">If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to it, you may do so via the contact form.</Text>
            </div>

            <div>
                <Head level={2} size="md" weight="medium">DMCA policy</Head>
                <Text tagName="p">This Digital Millennium Copyright Act policy ("Policy") applies to the kasparovchess.com website ("Website" or "Service") and apps available on the apple store and the google play store and any of its related products and services (collectively, "Services") and outlines how KEYSQUARE ("KEYSQUARE", "we", "us" or "our") addresses copyright infringement notifications and how you ("you" or "your") may submit a copyright infringement complaint.</Text>
                <Text tagName="p">Protection of intellectual property is of utmost importance to us and we ask our users and their authorized agents to do the same. It is our policy to expeditiously respond to clear notifications of alleged copyright infringement that comply with the United States Digital Millennium Copyright Act ("DMCA") of 1998, the text of which can be found at the U.S. Copyright Office website.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">What to consider before submitting a copyright complaint</Head>
                <Text tagName="p">Before submitting a copyright complaint to us, consider whether the use could be considered fair use.
                Fair use states that brief excerpts of copyrighted material may, under certain circumstances, be quoted verbatim for purposes such as criticism, news reporting, teaching, and research, without the need for permission from or payment to the copyright holder.</Text>
                <Text tagName="p">If you have considered fair use, and you still wish to continue with a copyright complaint, you may want to first reach out to the user in question to see if you can resolve the matter directly with the user.</Text>
                <Text tagName="p">Please note that if you are unsure whether the material you are reporting is in fact infringing, you may wish to contact an attorney before filing a notification with us.</Text>
                <Text tagName="p">The DMCA requires you to provide your personal information in the copyright infringement notification. If you are concerned about the privacy of your personal information, you may wish to hire an agent to report infringing material for you.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Notifications of infringement</Head>
                <Text tagName="p">If you are a copyright owner or an agent thereof, and you believe that any material available on our Services infringes your copyrights, then you may submit a written copyright infringement notification ("Notification") using the contact details below pursuant to the DMCA by providing us with the following information :</Text>
                <ul>
                    <li>Identification of the copyrighted work that you claim has been infringed, or, if multiple copyrighted works are covered by this Notification, you may provide a representative list of the copyrighted works that you claim have been infringed.</li>
                    <li>Identification of the infringing material and information you claim is infringing (or the subject of infringing activity), including at a minimum, if applicable, the URL or URLs of the web pages where the allegedly infringing material may be found.</li>
                    <li>Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and, if available, an e-mail address.</li>
                    <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, the copyright owner's agent, or the law.</li>
                    <li>A statement that the information in the notification is accurate, and under penalty of perjury that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                    <li>A physical or electronic signature (typing your full name will suffice) of the copyright owner or a person authorized to act on their behalf.</li>
                </ul>
                <Text tagName="p">All such Notifications must comply with the DMCA requirements. You may refer to a DMCA takedown notice generator or other similar services to avoid making mistake and ensure compliance of your Notification.</Text>
                <Text tagName="p">Filing a DMCA complaint is the start of a pre-defined legal process. Your complaint will be reviewed for accuracy, validity, and completeness. If your complaint has satisfied these requirements, our response may include the removal or restriction of access to allegedly infringing material as well as a permanent termination of repeat infringers’ accounts. A backup of the terminated account’s data may be requested; however, it may be subject to certain penalty fees imposed. The final penalty fee will be determined by the severity and frequency of the violations.</Text>
                <Text tagName="p">If we remove or restrict access to materials or terminate an account in response to a Notification of alleged infringement, we will make a good faith effort to contact the affected user with information concerning the removal or restriction of access.</Text>
                <Text tagName="p">Notwithstanding anything to the contrary contained in any portion of this Policy, KEYSQUARE reserves the right to take no action upon receipt of a DMCA copyright infringement notification if it fails to comply with all the requirements of the DMCA for such notifications.</Text>
                <Text tagName="p">The process described in this Policy does not limit our ability to pursue any other remedies we may have to address suspected infringement.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Changes and amendments</Head>
                <Text tagName="p">We reserve the right to modify this Policy or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Policy on the Website. When we do, we will revise the updated date at the bottom of this page.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Reporting copyright infringement</Head>
                <Text tagName="p">If you would like to notify us of the infringing material or activity, you may do so via the contact form
                DMCA policy</Text>
                <Text tagName="p">This Digital Millennium Copyright Act policy ("Policy") applies to the kasparovchess.com website ("Website" or "Service") and apps available on the apple store and the google play store and any of its related products and services (collectively, "Services") and outlines how KEYSQUARE ("KEYSQUARE", "we", "us" or "our") addresses copyright infringement notifications and how you ("you" or "your") may submit a copyright infringement complaint.</Text>
                <Text tagName="p">Protection of intellectual property is of utmost importance to us and we ask our users and their authorized agents to do the same. It is our policy to expeditiously respond to clear notifications of alleged copyright infringement that comply with the United States Digital Millennium Copyright Act ("DMCA") of 1998, the text of which can be found at the U.S. Copyright Office website.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">What to consider before submitting a copyright complaint</Head>
                <Text tagName="p">Before submitting a copyright complaint to us, consider whether the use could be considered fair use.
                Fair use states that brief excerpts of copyrighted material may, under certain circumstances, be quoted verbatim for purposes such as criticism, news reporting, teaching, and research, without the need for permission from or payment to the copyright holder.</Text>
                <Text tagName="p">If you have considered fair use, and you still wish to continue with a copyright complaint, you may want to first reach out to the user in question to see if you can resolve the matter directly with the user.</Text>
                <Text tagName="p">Please note that if you are unsure whether the material you are reporting is in fact infringing, you may wish to contact an attorney before filing a notification with us.</Text>
                <Text tagName="p">The DMCA requires you to provide your personal information in the copyright infringement notification. If you are concerned about the privacy of your personal information, you may wish to hire an agent to report infringing material for you.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Notifications of infringement</Head>
                <Text tagName="p">If you are a copyright owner or an agent thereof, and you believe that any material available on our Services infringes your copyrights, then you may submit a written copyright infringement notification ("Notification") using the contact details below pursuant to the DMCA by providing us with the following information :</Text>
                <ul>
                    <li>Identification of the copyrighted work that you claim has been infringed, or, if multiple copyrighted works are covered by this Notification, you may provide a representative list of the copyrighted works that you claim have been infringed.</li>
                    <li>Identification of the infringing material and information you claim is infringing (or the subject of infringing activity), including at a minimum, if applicable, the URL or URLs of the web pages where the allegedly infringing material may be found.</li>
                    <li>Information reasonably sufficient to permit us to contact you, such as an address, telephone number, and, if available, an e-mail address.</li>
                    <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, the copyright owner's agent, or the law.</li>
                    <li>A statement that the information in the notification is accurate, and under penalty of perjury that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                    <li>A physical or electronic signature (typing your full name will suffice) of the copyright owner or a person authorized to act on their behalf.</li>
                </ul>
                <Text tagName="p">All such Notifications must comply with the DMCA requirements. You may refer to a DMCA takedown notice generator or other similar services to avoid making mistake and ensure compliance of your Notification.</Text>
                <Text tagName="p">Filing a DMCA complaint is the start of a pre-defined legal process. Your complaint will be reviewed for accuracy, validity, and completeness. If your complaint has satisfied these requirements, our response may include the removal or restriction of access to allegedly infringing material as well as a permanent termination of repeat infringers’ accounts. A backup of the terminated account’s data may be requested; however, it may be subject to certain penalty fees imposed. The final penalty fee will be determined by the severity and frequency of the violations.</Text>
                <Text tagName="p">If we remove or restrict access to materials or terminate an account in response to a Notification of alleged infringement, we will make a good faith effort to contact the affected user with information concerning the removal or restriction of access.</Text>
                <Text tagName="p">Notwithstanding anything to the contrary contained in any portion of this Policy, KEYSQUARE reserves the right to take no action upon receipt of a DMCA copyright infringement notification if it fails to comply with all the requirements of the DMCA for such notifications.</Text>
                <Text tagName="p">The process described in this Policy does not limit our ability to pursue any other remedies we may have to address suspected infringement.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Changes and amendments</Head>
                <Text tagName="p">We reserve the right to modify this Policy or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Policy on the Website. When we do, we will revise the updated date at the bottom of this page.</Text>
            </div>

            <div>
                <Head level={3} size="xs" weight="medium">Reporting copyright infringement</Head>
                <Text tagName="p">If you would like to notify us of the infringing material or activity, you may do so via the contact form</Text>
            </div>
        </section>
    );
};

export default Terms;
