import * as constants from './constants';

const initialState = {
    inProgress: null,
    success: null,
    error: null,
    all: [],
    single: {},
    chapter: {},
    filters: {
        themes: null,
        levels: null,
        teachers: null
    },
    noMoreData: false,
    sortBy: null,
};

const itemsPerPage = 8;

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_PROGRAMS:
        case constants.GET_PROGRAM:
        case constants.GET_CHAPTER:
            return {
                ...state,
                inProgress: true,
                success: null,
                error: null,
            };
        case constants.GET_PROGRAMS_SUCCESS:
            return getAllPrograms(state, action);
        case constants.GET_PROGRAM_SUCCESS:
            return getSingleProgram(state, action);
        case constants.GET_CHAPTER_SUCCESS:
            return getChapter(state, action);
        case constants.GET_PROGRAMS_FAILURE:
        case constants.GET_PROGRAM_FAILURE:
        case constants.GET_CHAPTER_FAILURE:
           return {
                ...state,
                inProgress: null,
                error: action.error,
            };
        case constants.CLEAR_PROGRAMS:
            return {
                ...state,
                inProgress: null,
                success: null,
                error: null,
                all: [],
                single: {},
                chapter: {}
            };
        case constants.SET_PROGRAMS_FILTERS:
            return setProgramsFilters(state, action);
        case constants.SET_PROGRAMS_SORT_BY:
            return setProgramsSortBy(state, action);
        case constants.GET_MORE_PROGRAMS:
            return {
                ...state,
                inProgress: true,
            };
        case constants.GET_MORE_PROGRAMS_SUCCESS:
            return getMoreProgramsSuccess(state, action);
        default:
            return state;
    }
};

const getAllPrograms = (state, { data }) => {
    return {
        ...state,
        inProgress: null,
        error: null,
        success: true,
        all: data,
        noMoreData: data.length === itemsPerPage ? false : true,
    };
};

const getSingleProgram = (state, { data: actionData }) => {
    return {
        ...state,
        inProgress: null,
        success: true,
        single: actionData,
    };
};

const getChapter = (state, { data: actionData }) => {
    return {
        ...state,
        inProgress: null,
        success: true,
        chapter: actionData,
    };
};

const setProgramsFilters = (state, { data }) => {
    return { 
        ...state,
        filters: data,
    };
};

const setProgramsSortBy = (state, { data }) => {
    return { 
        ...state,
        sortBy: data,
    };
};

const getMoreProgramsSuccess = (state, { data }) => {
    return { 
        ...state,
        inProgress: false,
        all: [...state.all, ...data],
        noMoreData: data.length === itemsPerPage ? false : true
    };
};

export default reducer;
