import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { checkValidity } from 'shared/utility';

import { Text } from 'UI';

const Select = ({ 
    options, 
    label,
    displayKeyName, 
    valueKeyName, 
    className, 
    defaultOption,
    name,
    register,
    setvalue,
    errors,
    rules,
    ...props 
}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [textColor, setTextColor] = useState(" text-grey-light ");
    const [bgClasses, setBgClasses] = useState(" bg-white bg-opacity-5 ");

    const onChange = (e) => {
        if (!e.target.value && textColor !== ' text-grey-light ') {
            setTextColor(" text-grey-light ");
        } else {
            setTextColor("");
        }
    };

    useEffect(() => {
        if (errors) {
            setBgClasses(" bg-red-800 bg-opacity-30 ");
        }
    }, [errors]);

    return (
        <>
            {register &&
                <div className="flex flex-col w-full">
                    {label && 
                        <label htmlFor={name} className="text-lg py-2">{label}</label>
                    }
                    <select name={name} {...props}
                        {...register(name, {
                            validate: value => {
                                const validation = checkValidity(value, rules);
                                setErrorMessage(validation.message);
                                return validation.isValid;
                            }
                        })} 
                        onChange={onChange}
                        className={`${className?(" "+className):""}${bgClasses}${textColor}w-full p-5 shadow-input rounded-t`} >

                        {defaultOption ?
                            <option value="">{defaultOption[displayKeyName]}</option>
                            : <option value=""></option>
                        }

                        {options.map((option) => {
                            return (
                                <option key={option[valueKeyName]} value={option[valueKeyName]}>{option[displayKeyName]}</option>
                            );
                        })}
                    </select>
                    {errors && errorMessage && <Text textColor="red-600">{errorMessage}</Text>}
                </div>
            }
        </>
    );
};

Select.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    displayKeyName: PropTypes.string.isRequired,
    valueKeyName: PropTypes.string.isRequired,
    className: PropTypes.any,
    label: PropTypes.string,
    defaultOption: PropTypes.object.isRequired,
    register: PropTypes.func,
    setvalue: PropTypes.func,
    rules: PropTypes.object,
    errors: PropTypes.object
};

export default Select;
