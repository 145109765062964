import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ChoosePlan, Page, DownloadApp, UpgradeBlock } from 'components';

import { Head, Text, Loader } from 'UI';
import { getHomePageData } from 'store/strapi/selector';
import { getHomeRequest } from 'store/strapi/actions';

import { ReactComponent as CameraChip } from 'assets/icons/upgrade/camera_chip.svg';
import { ReactComponent as ChessWinChip } from 'assets/icons/upgrade/chess_win_chip.svg';
import { ReactComponent as PuzzleChip } from 'assets/icons/upgrade/puzzle_chip.svg';
import { ReactComponent as TrophyChip } from 'assets/icons/upgrade/trophy_chip.svg';
import { ReactComponent as ReportChip } from 'assets/icons/upgrade/report_chip.svg';
import KasparovChip from 'assets/images/kasparov-chip.png';
import UpgradeLearn from 'assets/images/upgrade/teacher-learn.png';
import UpgradeWatch from 'assets/images/upgrade/video-watch.png';
import UpgradePlay from 'assets/images/upgrade/play.png';

import TestimonyOne from 'assets/images/upgrade/testimony1.png';
import TestimonyTwo from 'assets/images/upgrade/testimony2.png';
import TestimonyThree from 'assets/images/upgrade/testimony3.png';

const UpgradePage = () => {
    const { data: homeData, error, inProgress } = useSelector(getHomePageData, shallowEqual);
    const [pageData, setPageData] = useState(null);
    const dispatch = useDispatch();
    const testimonies = [
        {
            image: TestimonyOne,
            name: "Carla C.",
            quote: "This is so interesting to see these great champions explaining chess, we are so lucky to be able to see this, awesome job.",
            align: "left",
        },
        {
            image: TestimonyTwo,
            name: "Kiron K.",
            quote: "Excellent tutorial",
            align: "right",
        },
        {
            image: TestimonyThree,
            name: "@bitthevitzzz",
            quote: "I've personally learned a lot from the video lessons, they're very instructive and helpful",
            align: "left",
        }
    ];

    useEffect(() => {
        if (!homeData) {
            dispatch(getHomeRequest());
        } else {
            if (homeData.sections && homeData.sections.length) {
                homeData.sections.forEach((section, i) => {
                    if (section.__component === "hub.banner-mobile") {
                        setPageData(section);
                    }
                });
            }
        }
    }, [homeData]);

    useEffect(() => {
        if (error) {
            toast.error({ 
                type: 'subscription', 
                action: 'fetch',
                key: 'upgrade',
            });
        }
    }, [error]);

    return (
        <Page wrapperClassName="bg-black">
            <Loader loading={inProgress ? inProgress : false} isFixed={true} />
            {pageData &&
                <>
                    <section style={{backgroundImage: `url(${pageData.background?.url})`}}
                        className="flex flex-col py-12 px-7 bg-no-repeat bg-cover bg-center gap-7">
                        <div className="text-center pt-4">
                            <Head size="xl" weight="medium">
                                {pageData.title}
                            </Head>
                            <Text tagName="p">{pageData.paragraph}</Text>
                        </div>
                        <ChoosePlan />
                    </section>

                    <section className="my-2 pt-28 bg-level bg-top bg-no-repeat">
                        <div className="px-8 pb-10">
                           <Head size="3xl" weight="medium" level={2}>Learn.</Head>
                           <Text size="lg" weight="medium" className="py-2">From the best</Text>
                           <Text size="sm" tagName="p" opacity="lighter" className="py-7">
                               Whether you are a total beginner or a grandmaster, you will enjoy our suite of available lessons, masterclasses, and puzzles.
                           </Text>
                           <ul className="grid grid-cols-1 gap-6">
                                <li className="flex gap-2 items-center w-full">
                                   <div className="w-1/5"><ChessWinChip /></div>
                                   <div className="w-4/5 text-md">Focus on a specific aspect of the game with our curated exercises</div>
                                </li>
                                <li className="flex gap-2 items-center w-full">
                                   <div className="w-1/5"><img src={KasparovChip} alt="" /></div>
                                   <div className="w-4/5 text-md">Masterclass with Garry Kasparov</div>
                                </li>
                                <li className="flex gap-2 items-center w-full">
                                   <div className="w-1/5"><CameraChip /></div>
                                   <div className="w-4/5 text-md">Hundreds of GM Lessons & Programs</div>
                                </li>
                           </ul>
                        </div>
                       <img src={UpgradeLearn} alt="Learn with GM lessons and programs" />
                    </section>

                    <section className="px-6 pb-10 my-2 pt-10">
                        <Head size="3xl" weight="medium" level={2} className="text-center">Don't take our word for it.</Head>
                        <ul className="flex flex-col space-y-6 mt-16">
                            {testimonies.map((testimony) => {
                                return (
                                    <li className={`flex items-center justify-between space-x-4 ${testimony.align === 'right' ? 'flex-row-reverse':'flex-row'}`}>
                                        <div>
                                            <img src={testimony.image} style={{maxWidth: '107px'}}/>
                                        </div>
                                        <div className="leading-loose space-y-3">
                                            <div className="text-primary font-bold text-2xl">{testimony.name}</div>
                                            <div className="text-lg">"{testimony.quote}"</div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </section>

                    <section className="my-2 pt-14 text-center">
                        <div className="px-8 pb-4 ">
                            <Head size="3xl" weight="medium" level={2}>Watch.</Head>
                            <Text size="lg" weight="medium" className="py-4">
                               GCT events live and fun chess content, from amateurs playing in the street to live broadcasts of top events
                            </Text>
                            <Text size="sm" tagName="p" opacity="lighter" className="pt-4">
                               Through the Grand Chess tour, our documentaries, interviews, podcasts, and live content, explore different facets of the chess game
                            </Text>
                        </div>
                       <img src={UpgradeWatch} alt="Watch chess related content" />
                    </section>

                    <section className="my-2 pt-14 px-8">
                        <div className="text-center">
                            <Head size="3xl" weight="medium" level={2}>Play.</Head>
                            <img src={UpgradePlay} alt="Play" className="py-5" />
                            <Text size="3xl" weight="medium">
                               Play on the app & our desktop website.
                            </Text>
                            <Text size="sm" tagName="p" opacity="lighter" className="pt-4">
                               Enjoy more than 50000 puzzles, play against the computer or your friends by downloading the app or going to kasparovchess.com on a PC or tablet
                            </Text>
                        </div>
                        <ul className="grid grid-cols-1 gap-8 pt-8">
                            <li className="flex gap-2 items-center w-full">
                               <div className="w-1/5"><PuzzleChip /></div>
                               <div className="w-4/5 text-md">Unlimited puzzles</div>
                            </li>
                            <li className="flex gap-2 items-center w-full">
                               <div className="w-1/5"><TrophyChip /></div>
                               <div className="w-4/5 text-md">Challenge yourself and your friends</div>
                            </li>
                            <li className="flex gap-2 items-center w-full">
                               <div className="w-1/5"><ReportChip /></div>
                               <div className="w-4/5 text-md">Game report & Analysis</div>
                            </li>
                        </ul>
                    </section>
                </>
            }
            <section className="mt-12 p-4 text-center bg-white">
                <DownloadApp headingLevel={2} />
            </section>
        </Page>
    );
};

export default UpgradePage;
