import React, { useState, useEffect, createRef } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    MasterclassPreview,
    Page
} from 'components';

import { 
    Head, 
    Text,
    Carousel,
    VideoPlayer,
    Loader
} from 'UI';
import API from 'API';
import { handleAPIErrors } from 'shared/errors';

const EpisodePage = ({ match, location }) => {
    const history = useHistory();
    const [episode, setEpisode] = useState(null);
    const [loading, setLoading] = useState(null);
    const { id } = useParams();
    const ref = createRef();

    useEffect(() => {
        fetchEpisode();
    }, [id]);

    const scrollTop = () => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    };

    const fetchEpisode = async () => {
        setLoading(true);
        try {
            const { data } = await API.get(`/cms/masterclass_episodes/${id}`);
            setEpisode(data);
            scrollTop();
        } catch (error) {
            if (error.response && error.response.status) {
                handleAPIErrors(error.response.status, history, location.pathname);
            } else {
                toast.error({ 
                    type: 'masterclass', 
                    action: 'fetch',
                    key: 'one',
                });
            }
        }
        setLoading(false);
    };

    const findEpisode = () => {
        const index = episode.chapter.findIndex(
            (episode) =>
                episode.masterclass_episode_id === parseInt(id)
        );

        return `Episode ${index + 1}`;
    };

    return (
        <Page wrapperClassName="flex flex-col flex-auto bg-fake-black" className="flex-grow pb-8" key={episode}>
            <Loader loading={loading ? loading : false} isFixed={true} className="bg-opacity-40" /> 
            {episode ? 
                <>
                    <section className="mb-5" ref={ref}> 
                        <VideoPlayer
                            video={
                                episode.dailymotion_id
                            }
                            autoplay={true}
                        />
                        <div className="px-8 pt-4 pb-2">
                            <Head size="sm">{episode.title}</Head>
                            <Text size="xs" className="pb-4">{findEpisode()}</Text>
                            <Text size="sm">{episode.description}</Text>
                        </div>
                    </section>

                    <section className="mt-12">
                        <div className="px-8">
                            <Head level="2" size="md">Other episodes</Head>
                            <Text size="xs" className="pb-4" opacity="lighter">{episode.chapter.length} episode{episode.chapter.length > 1 && 's'}</Text>
                        </div>
                        <div className="pl-8">
                            <Carousel>
                                {episode.chapter.map((otherEpisode, i) => {
                                    return (
                                        <MasterclassPreview masterclass={otherEpisode} key={i} headingLevel={3} />
                                    );
                                })}
                            </Carousel>
                        </div>
                    </section>
                </>
             : <></>}
        </Page>
    );    
};

export default withRouter(EpisodePage);
