import { Text } from 'UI';
import PropTypes from 'prop-types';

import { ReactComponent as Check } from 'assets/icons/common/checkmark_ok.svg';

const Selector = ({ onSelection, items, displayKeyName, selected, itemListClass, checkIcon, ...props }) => {
    return (
        <ul {...props}>
            {items.map((item, i) => {
                const isSelected = selected ? selected[displayKeyName] === item[displayKeyName] : false;
                return (
                    <li key={i} onClick={() => onSelection(item)} className={`flex items-center py-2 relative${itemListClass?(' '+itemListClass):''}`}>
                        {checkIcon && <Check className={`absolute left-3 ${selected ? 'hidden':undefined}`} />}
                        <Text weight={isSelected ? 'bold':undefined} textColor={isSelected ? 'primary':undefined}>{item[displayKeyName]}</Text>
                    </li>
                );
            })}
        </ul>
    );
};

Selector.propTypes = {
    items: PropTypes.array.isRequired,
    onSelection: PropTypes.func.isRequired,
    displayKeyName: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    itemListClass: PropTypes.string,
    checkIcon: PropTypes.bool
};

Selector.defaultProps = {
    displayKeyName: 'value'
};

export default Selector;
