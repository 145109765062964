import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setTableHeight } from 'store/util/actions';

const ScreenSizeWrapper = (props) => {
    const dispatch = useDispatch();

    const calculateTableHeight = () => {
        if (window.innerHeight > 768) {
            switch (true) {
                case window.innerWidth > 1660:
                    return window.innerHeight - 200;
                case window.innerWidth <= 1660 && window.innerWidth > 1440:
                    return window.innerWidth - 792;
                case window.innerWidth <= 1440:
                    return window.innerWidth - 628;

                default:
                    return null;
            }
        } else {
            // return 530;
            return window.innerHeight - 150;
        }
    };

    const setViewportHeight = () => {
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // let smallScreen = false;
    // let mobile = false;

    // if (window.innerWidth < 415) {
    //     smallScreen = false;
    //     mobile = true;
    // } else if (window.innerWidth < 1024) {
    //     smallScreen = true;
    //     mobile = false;
    // }

    useEffect(() => {
        dispatch(setTableHeight(calculateTableHeight()));
        setViewportHeight();

        window.addEventListener('resize', () => {
          setViewportHeight();
        });

        return () => {
            window.removeEventListener('resize', () => {});
        };
    }, []);
    return (
        <>
            {props.children}
        </>
    );
};

export default ScreenSizeWrapper;
