import React, { Suspense } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { 
    SUBSCRIPTION_URLS,
    MAIN_URLS,
    MASTERCLASS_URLS,
    ARTICLES_URLS,
    LESSONS_URLS,
    VIDEOS_URLS,
    PROGRAMS_URLS,
    PRODUCT_PAYMENT_URLS,
} from 'shared/urlList';

import { 
    HomePage, 
    LessonsPage,
    LessonPage,
    MasterclassPage,
    EpisodePage,
    ArticlesPage,
    ArticlePage,
    RegisterPage,
    ResetPasswordPage,
    FourOFour,
    SubscriptionSuccess,
    VideoPage,
    UpgradePage,
    LoginPage,
    ProgramsPage,
    ProgramPage,
    ChapterPage,
    OnBoardingPage,
    GrandChessTourPage,
    WelcomePage,
    ProductPaymentSuccess,
    ProductPaymentCancel,
} from 'components';

// TODO Handle guest redirect better
// Introduce isGuest param that will help
const RouterWrapper = (props) => {
    const isLoggedIn = false;
    return (
        <Suspense fallback={null}>
            {!isLoggedIn ? <Guest props={props} /> : <LoggedIn props={props} />}
        </Suspense>
    );
};

const LoggedIn = ({ props }) => {
    return (
        <Switch>
            <Route exact path={MAIN_URLS.HOME} component={HomePage} />
        </Switch>
    );
};

const Guest = ({ props }) => {
    return (
        <TransitionGroup>
            <CSSTransition
                key={props.location?.key}
                classNames="fade"
                timeout={{ enter: 2000, exit: 2000 }}>
                <Switch>
                    <Route exact path={MAIN_URLS.HOME} component={HomePage} />
                    <Route exact path={MAIN_URLS.REGISTER} component={RegisterPage} />
                    <Route exact path={MAIN_URLS.ONBOARDING} component={OnBoardingPage} />
                    <Route exact path={MAIN_URLS.LOGIN} component={LoginPage} />
                    <Route exact path={MAIN_URLS.RESET_PASSWORD} component={ResetPasswordPage} />
                    <Route exact path={LESSONS_URLS.ALL} component={LessonsPage} />
                    <Route exact path={LESSONS_URLS.ONE} component={LessonPage} />
                    <Route exact path={MASTERCLASS_URLS.ALL} component={MasterclassPage} />
                    <Route exact path={MASTERCLASS_URLS.ONE} component={EpisodePage} />
                    <Route exact path={ARTICLES_URLS.ALL} component={ArticlesPage} />
                    <Route exact path={ARTICLES_URLS.ONE} component={ArticlePage} />
                    <Route exact path={SUBSCRIPTION_URLS.SUBSCRIPTION_SUCCESS} component={SubscriptionSuccess} />
                    <Route exact path={SUBSCRIPTION_URLS.UPGRADE_ACCOUNT} component={UpgradePage} />
                    <Route exact path={VIDEOS_URLS.ONE} component={VideoPage} />
                    <Route exact path={PROGRAMS_URLS.ALL} component={ProgramsPage} />
                    <Route exact path={PROGRAMS_URLS.ONE} component={ProgramPage} />
                    <Route exact path={PROGRAMS_URLS.CHAPTER} component={ChapterPage} />
                    <Route exact path={VIDEOS_URLS.GRAND_CHESS_TOUR} component={GrandChessTourPage} />
                    <Route exact path={[
                        SUBSCRIPTION_URLS.WELCOME_YEARLY,
                        SUBSCRIPTION_URLS.WELCOME_MONTHLY,
                    ]} component={WelcomePage} />
                    <Route exact path={PRODUCT_PAYMENT_URLS.SUCCESS} component={ProductPaymentSuccess} />
                    <Route exact path={PRODUCT_PAYMENT_URLS.CANCEL} component={ProductPaymentCancel} />
                    {props.location?.pathname === '/' && <Redirect to={MAIN_URLS.HOME} />}
                    <Route path="*" exact component={FourOFour} />
                    <Redirect to={MAIN_URLS.NOT_FOUND} />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
};

export default withRouter(RouterWrapper);
