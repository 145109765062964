import * as constants from './constants';

export const getFooterRequest = () => {
    return {
        type: constants.GET_FOOTER_REQUEST,
    };
};

export const getFooterSuccess = (data) => {
    return {
        type: constants.GET_FOOTER_SUCCESS,
        data,
    };
};

export const getFooterFailure = (error) => {
    return {
        type: constants.GET_FOOTER_FAILURE,
        error,
    };
};

export const subscribeNewsletterRequest = ({ email }) => {
    return {
        type: constants.SUBSCRIBE_NL_REQUEST,
        data: {
            email
        }
    };
};

export const subscribeNewsletterSuccess = (data) => {
    return {
        type: constants.SUBSCRIBE_NL_SUCCESS,
        data,
    };
};

export const subscribeNewsletterFailure = (error) => {
    return {
        type: constants.SUBSCRIBE_NL_FAILURE,
        error,
    };
};
