import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { toast } from 'shared/alerts';
import qs from 'query-string';

import { ReactComponent as Logo } from 'assets/icons/common/logo_2@2x.svg';

import * as actions from 'store/user/actions';
import * as userSelectors from 'store/user/selector';

import { 
    Button, 
    ButtonLink,
    Text,
    Head,
    Input,
    Form,
    Loader
} from 'UI';

import { MAIN_URLS } from 'shared/urlList';

const ResetPasswordPage = () => {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const dispatch = useDispatch();
    const recoverPasswordSelector = useSelector(
        userSelectors.recoverPasswordRequest,
        shallowEqual
    );

    const onSubmit = (data) => {
        if (data.password === data.password_confirm) {
            const payload = {
                password: data.password.trim(),
                token
            };

            dispatch(actions.recoverPasswordRequest(payload));
        } else {
            toast.error({ 
                type: 'resetPassword', 
                action: 'create',
                key: 'mismatch'
            });
        }
    };

    useEffect(() => {
        const { error, isSuccessful, inProgress } = recoverPasswordSelector;

        if (inProgress) {
            setLoading(true);
        } else {
            setLoading(false);
        }

        if (error) {
            toast.error({ 
                type: 'resetPassword', 
                action: 'create',
            });
        }

        if (isSuccessful) {
            toast.success('Your password has been updated.');
        }

        dispatch(actions.resetPasswordRecovery());
    }, [recoverPasswordSelector]);

    useEffect(() => {
        const { search } = location;
        if (search) {
            const parsed = qs.parse(search);
            setToken(parsed.token);
        } else {
            history.replace(MAIN_URLS.HOME);
        }
    }, []);

    return (
        <div className="pt-20 flex flex-col items-center min-h-screen justify-center bg-gradient-to-r from-purple-dark to-purple-light relative">
            <Loader loading={loading} bgColor="white" className="bg-opacity-40" />
            
            <section className="flex flex-col items-start justify-center h-full w-full px-8">
                <Logo className="self-center mb-12" />
                <Head size="lg" weight="normal">Reset your password</Head>
                {!recoverPasswordSelector.isSuccessful &&
                    <Text>Your email has been verified. Please enter a new password in the form below.</Text>
                }
            </section>

            {!recoverPasswordSelector.isSuccessful ?
                <>
                    <section className="w-full px-8 pt-4">
                        <Form onSubmit={onSubmit} noValidate autoComplete="off">
                            <Input label="New password" type="password" name="password" placeholder="Your new password"
                                rules={{required: true, isPassword: true}} autoComplete="off"/>
                            <Input label="Repeat your new password" type="password" name="password_confirm" placeholder="Re-enter new password"
                                rules={{required: true, isPassword: true}} autoComplete="off"/>
                            <Button bgColor="white" textColor="purple-dark" size="lg" type="submit" rounded="md" className="my-6">Update password</Button>
                        </Form>
                    </section>

                    <section className="flex flex-col items-center pb-5">
                        <ButtonLink 
                            to={MAIN_URLS.HOME} 
                            className="underline" 
                            bgColor="none" 
                            size="sm">Cancel</ButtonLink>
                    </section>
                </> :
                <section className="w-full px-8 pt-4">
                    <Text className="pb-8">Your password has been updated. You can now log in using your new password.</Text>
                    <ButtonLink 
                        to={`${MAIN_URLS.HOME}?open=login`} 
                        bgColor="white" 
                        textColor="purple-dark" 
                        size="lg" 
                        type="button" 
                        rounded="md" 
                        className="my-6">
                        Log in
                    </ButtonLink>
                </section>
            }
        </div>
    );
};

export default ResetPasswordPage;
