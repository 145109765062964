import API from 'API';

export const PROGRAM_URLS = {
    PROGRAMS: '/cms/programs',
    PROGRAM: '/cms/programs/',
    CHAPTER: '/cms/program-chapters/',
};

export const getAllPrograms = ({ filters, sortBy, limit, start }) => {
    const apiCall = `${PROGRAM_URLS.PROGRAMS}?` +
        `${limit ? `_limit=${limit}` : ''}` +
        `${start ? `&_start=${start}` : ''}` +
        `${sortBy ? `&_sort=${sortBy.key}:asc` : ''}` +
        `${filters?.themes ? `&topic=${filters.themes.id}` : ''}` +
        `${filters?.levels ? `&level=${filters.levels.id}` : ''}` +
        `${filters?.teachers ? `&trainers=${filters.teachers.id}` : ''}`;
    return API.get(apiCall);
};

export const getSingleProgram = (params) => {
    const apiCall = PROGRAM_URLS.PROGRAM + params.id;

    return API.get(apiCall);
};

export const getChapter = (params) => {
    const apiCall = PROGRAM_URLS.CHAPTER + params.id;

    return API.get(apiCall);
};
