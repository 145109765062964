import React from 'react';

import { ReactComponent as Facebook } from 'assets/icons/social/facebook.svg';
import { ReactComponent as Twitter } from 'assets/icons/social/twitter.svg';
import { ReactComponent as Youtube } from 'assets/icons/social/youtube.svg';
import { ReactComponent as Instagram } from 'assets/icons/social/instagram.svg';

const SocialNetworks = ({ className, urls }) => {
    const iconElement = (key) => {
        if (key === 'facebook') {
            return <Facebook />;
        } else if (key === 'instagram') {
            return <Instagram />;
        } else if (key === 'youtube') {
            return <Youtube />;
        } else if (key === 'twitter') {
            return <Twitter />;
        }
    };

    return (
        <div className={`flex flex-row items-center gap-10 ${className}`}>
            {Object.keys(urls).map((key) => {
                return (
                    <a key={key} href={urls[key]} target="_blank" rel="noreferrer" className="flex flex-col items-center justify-center w-6">
                        {iconElement(key)}
                    </a>
                );
            })}
        </div>
    );
};

export default SocialNetworks;
