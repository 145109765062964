import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { 
    NewsletterForm, 
    LoginPrompt, 
    Register, 
    UpgradePrompt, 
    GuestPrompt,
    Terms,
    Privacy
} from 'components';
import SocialNetworks from './SocialNetworks/SocialNetworks';
import { getFooter } from 'store/footer/selector';
import { getToggleModalValue } from 'store/util/selector';
import { toggleModal } from 'store/util/actions';
import { getFooterRequest } from 'store/footer/actions';
import { Text, Modal, Loader } from 'UI';

import { ReactComponent as Logo } from 'assets/icons/common/logo_2@2x.svg';

const Footer = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [showGuest, setShowGuest] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const toggleModalSelector = useSelector(getToggleModalValue, shallowEqual);
    const footerData = useSelector(getFooter, shallowEqual);

    const dispatch = useDispatch();
    const location = useLocation();

    const doAction = (actionKey = null, value = null) => {
        dispatch(toggleModal(null));
        setShowRegister(false);
        setShowLogin(false);
        setShowUpgrade(false);
        setShowGuest(false);

        if (actionKey === 'register') {
            setShowRegister(true);
        } else if (actionKey === 'upgrade') {
            setShowUpgrade(true);
        } else if (actionKey === 'login') {
            if (location.pathname !== '/login') {
                setShowLogin(true);
            } else {
                dispatch(toggleModal(null));
            }
        } else if (actionKey === 'guest') {
            setShowGuest(true);
        } else if (actionKey === 'terms') {
            setShowTerms(true);
        } else if (actionKey === 'privacy') {
            setShowPrivacyPolicy(true);
        }
    };

    useEffect(() => {
        checkQueryString();
        dispatch(getFooterRequest());
    }, []);

    useEffect(() => {
        if (toggleModalSelector) {
            doAction(toggleModalSelector);
        }
    }, [toggleModalSelector]);

    const checkQueryString = () => {
        const { search } = location;
        if (search) {
            const parsed = qs.parse(search);
            if (parsed && parsed.open) {
                switch(parsed.open) {
                    case 'login':
                        setShowLogin(true);
                        break;
                    case 'register':
                        setShowRegister(true);
                        break;
                    case 'upgrade':
                        setShowUpgrade(true);
                        break;
                    default:
                }
            }
        }
    };

    return (
        <footer className="bg-black p-8
        					flex flex-col justify-items-center items-center
                            w-full relative">
            <Loader loading={footerData.inProgress ? true : false} />
            <div className="divide-y divide-white divide-opacity-20">
                <div className="w-full py-4">
    			    <Logo className="w-4 h-auto m-auto" />
                </div>
                <div className="w-full text-center p-4">
                    <Text textColor="white" size="lg">Want to get updates on Kasparovchess content, event and features?</Text>
                </div>
            </div>
            <NewsletterForm className="my-4" subscribed={footerData?.data?.subscribed || false} />
            {footerData?.data?.urls &&
                <SocialNetworks className="my-4" urls={footerData.data.urls} />
            }

            <Modal isVisible={showLogin} close={() => setShowLogin(false)} label="Login"
                description="Log in with your email address or with social networks">
                <LoginPrompt onClose={(actionKey, value) => doAction(actionKey, value)} />
            </Modal>
            <Modal isVisible={showRegister} close={() => setShowRegister(false)} label="Register"
                description="Register for free using your email address or social networks web services">
                <Register onClose={(actionKey, value) => doAction(actionKey, value)} />
            </Modal>
            <Modal isVisible={showUpgrade} close={() => setShowUpgrade(false)} label="choosePlan"
                description="Choose a subscription plan and upgrade your account">
                <UpgradePrompt onClose={(actionKey, value) => doAction(actionKey, value)} />
            </Modal>
            <Modal isVisible={showGuest} close={() => setShowGuest(false)} label="RegisterPrompt"
                description="Register for free and access more content">
                <GuestPrompt onClose={(actionKey, value) => doAction(actionKey, value)} />
            </Modal>
            <Modal label="terms" isVisible={showTerms} close={() => setShowTerms(false)}
                description="Read the terms and conditions of Kasparovchess websites">
                <Terms onClose={() => setShowTerms(false)} />
            </Modal>
            <Modal label="privacyPolicy" isVisible={showPrivacyPolicy} close={() => setShowPrivacyPolicy(false)}
                description="Read the privacy policy of Kasparovchess websites">
                <Privacy onClose={() => setShowPrivacyPolicy(false)} />
            </Modal>
        </footer>
    );
};

export default Footer;
