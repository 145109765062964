import PropTypes from 'prop-types';
import React from 'react';

const Text = ({
    children,
    className,
    opacity,
    size,
    textColor,
    weight,
    tagName
}) => {
    const TagName = tagName;
    
    if (!className) {
        className = "";
    }

    if (opacity === 'lighter') {
        className += ' text-opacity-40';
    } else if (opacity === 'light') {
        className += ' text-opacity-60';
    }
    
    if (size) {
        className += ` text-${size}`;
    }

    className += ` text-${textColor}`;
    className += ` font-${weight}`;


    return (
        <TagName
            className={className}
        >
            {children}
        </TagName>
    );
};

Text.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array])
        .isRequired,
    opacity: PropTypes.oneOf(['lighter', 'light', 'medium', 'heavy', 'full']),
    size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl']),
    textColor: PropTypes.string,
    weight: PropTypes.oneOf(['light', 'normal', 'medium', 'bold', 'black']),
    tagName: PropTypes.string
};

Text.defaultProps = {
    opacity: 'full',
    textColor: 'white',
    weight: 'normal',
    tagName: 'div'
};

export default Text;
