const LOCAL_STORAGE_KEYS = {
    ACCESS_TOKEN: '_k_sqr_at',
    REFRESH_TOKEN: '_k_sqr_rt',
    LOGGED_USER_INFO: 'user',
    GUEST_USER_INFO: 'guest',
    USER_COLORS: 'colors',
    IS_GUEST: 'is_guest',
    COOKIES_CONSENT: '_k_sqr_c_acc'
};

export const setIsGuest = (token) => {
    const now = new Date();
    const item = {
        value: token,
        ttl: now.getTime() + (3600 * 1000),
    };
    return localStorage.setItem(LOCAL_STORAGE_KEYS.IS_GUEST, JSON.stringify(item));
};

export const getIsGuest = () => {
    const string =  localStorage.getItem(LOCAL_STORAGE_KEYS.IS_GUEST);
    if (string) {
        const item = JSON.parse(string);
        const now = new Date();
        if (item && item.ttl > now.getTime()) {
            return item.value;
        }
    }
};

export const removeIsGuest = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.IS_GUEST);
};

// Guest user info
export const setGuestUser = (guestUser) => {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.GUEST_USER_INFO, guestUser);
};

export const getGuestUser = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.GUEST_USER_INFO);
};

export const removeGuestUser = () => {
    return localStorage.removeItem(LOCAL_STORAGE_KEYS.GUEST_USER_INFO);
};

// User info
export const setUser = (user) => {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.LOGGED_USER_INFO, user);
};

export const getUser = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_USER_INFO);
};

export const removeUser = () => {
    return localStorage.removeItem(LOCAL_STORAGE_KEYS.LOGGED_USER_INFO);
};

export const getUserColors = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.USER_COLORS);
};

export const setUserColors = (colors) => {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.USER_COLORS, colors);
};

export const getCookiesConsent = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.COOKIES_CONSENT);
};

export const setCookiesConsent = (value) => {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.COOKIES_CONSENT, value);
};

export const setCategoryConsent = (category, value) => {
    const consent = JSON.parse(getCookiesConsent());
    consent[category] = value;
    return localStorage.setItem(LOCAL_STORAGE_KEYS.COOKIES_CONSENT, JSON.stringify(value));
};
