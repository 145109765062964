import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';

import { Spinner, Head } from 'UI';

import API from 'API';

import { toast } from 'react-toastify';
import { setSubscriptionPlan } from 'store/subscriptions/actions';
import { userSubscriptionPlan } from 'store/subscriptions/selector';
import { getUser } from 'store/user/actions';
import { MAIN_URLS, SUBSCRIPTION_URLS } from 'shared/urlList';

import { ReactComponent as Logo } from 'assets/icons/common/logo_2@2x.svg';


const SubscriptionSuccess = ({ location, history }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const userPlan = useSelector(userSubscriptionPlan);

    const validateStripeSession = async (sessionId) => {
        try {
            const response = await API.get(`/subscriptions/web/checkout/session/${sessionId}`);
            setLoading(false);
            if (response && response.data && response.data.data) {
                dispatch(setSubscriptionPlan(response.data.data));
            } else {
                toast.error({
                    type: 'subscription',
                    action: 'update',
                    key: 'session',
                });
            }
        } catch (error) {
            setLoading(false);
            toast.error();
        }
    };

    useEffect(() => {
        if (userPlan && userPlan.plan && userPlan.plan.period) {
            dispatch(getUser());
            toast.success('Congratulations, you\'re officially a Pro member!');
            if (window.dataLayer && window.dataLayer.push) {
                window.dataLayer.push({'event': 'kc-upgrade-account'});
            }
            if (userPlan.plan.period === 'yearly') {
                history.replace(SUBSCRIPTION_URLS.WELCOME_YEARLY);
            } else {
                history.replace(SUBSCRIPTION_URLS.WELCOME_MONTHLY);
            }
        }
    }, [userPlan]);

    useEffect(() => {
        const { session_id } = qs.parse(location.search);

        if (!session_id) {
            history.replace(MAIN_URLS.HOME);
        }

        validateStripeSession(session_id);
    }, []);

    useEffect(() => {
        document.title = "Processing your request...";
    });

    return (
        <div className="flex items-center justify-center flex-col h-screen">
            <Logo />
            <Head size="lg">Processing your request</Head>
            {loading ? <Spinner /> : <div style={{ visibility: 'hidden', height: '42px' }}/>}
        </div>
    );
};

export default withRouter(SubscriptionSuccess);
