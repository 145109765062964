import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, Text } from '../';

import { ReactComponent as CloseIcon } from 'assets/icons/common/close_icon.svg';
import { ReactComponent as BackArrow } from 'assets/icons/arrows/arrow_back.svg';

const Modal = ({ label, description, children, isVisible, close, title, back, ...props }) => {
    const [style, setStyle] = useState({
        top: '-150vh'
    });

    const showModal = () => {
        document.body.style.overflow = 'hidden';
        setStyle({
            top: '0'
        });
    };

    const hideModal = () => {
        setStyle({
            top: '-150vh'
        });
    };

    useEffect(() => {
        if (!isVisible) {
            hideModal();
        } else {
            showModal();
        }
        return () => (document.body.style.overflow = 'unset');
    }, [isVisible]);

    return (
        <div style={style} role="dialog" aria-labelledby={label} aria-describedby={description} {...props}
            className="fixed left-0 right-0 bg-black w-screen h-screen z-30 transition-all duration-500">
            <div className="absolute flex flex-1 w-full justify-between items-center py-2 bg-transparent">
                <div className="flex-shrink">
                    <BackArrow className={!back ? 'invisible' : undefined} onClick={() => back()}/>
                </div>
                <div className="text-center flex-grow">
                    {title &&
                        <Text className="m-auto">{title}</Text>
                    }
                </div>
                <Button clicked={() => close()} bgColor="transparent" className="block flex-shrink z-20">
                    <CloseIcon />
                </Button>
            </div>
            <div className="h-screen h-screen-mobile overflow-scroll">
                {children}
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array])
        .isRequired, 
    isVisible: PropTypes.bool,
    close: PropTypes.func.isRequired,
    title: PropTypes.string, 
    back: PropTypes.func,
    label: PropTypes.string,
    description: PropTypes.string
};

Modal.defaultProps = {
    isVisible: false
};


export default Modal;
