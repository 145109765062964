import * as CONSTANTS from './constants';

// HOME PAGE ACTIONS
export const getHomeRequest = () => {
    return {
        type: CONSTANTS.GET_HOME_REQUEST,
    };
};

export const getHomeSuccess = (data) => {
    return {
        type: CONSTANTS.GET_HOME_SUCCESS,
        data,
    };
};

export const getHomeFailure = (error) => {
    return {
        type: CONSTANTS.GET_HOME_FAILURE,
        error,
    };
};

// ARTICLES ACTIONS
export const getAllArticlesRequest = (data) => {
    return {
        type: CONSTANTS.GET_ALL_ARTICLES_REQUEST,
        data,
    };
};

export const getAllArticlesSuccess = (data) => {
    return {
        type: CONSTANTS.GET_ALL_ARTICLES_SUCCESS,
        data,
    };
};

export const getAllArticlesFailure = (error) => {
    return {
        type: CONSTANTS.GET_ALL_ARTICLES_FAILURE,
        error,
    };
};

export const clearAllArticles = () => {
    return {
        type: CONSTANTS.CLEAR_ALL_ARTICLES,
    };
};

// SINGLE ARTICLE ACTIONS
export const getSingleArticleRequest = (id) => {
    return {
        type: CONSTANTS.GET_SINGLE_ARTICLE_REQUEST,
        id,
    };
};

export const getSingleArticleSuccess = (data) => {
    return {
        type: CONSTANTS.GET_SINGLE_ARTICLE_SUCCESS,
        data,
    };
};

export const getSingleArticleFailure = (error) => {
    return {
        type: CONSTANTS.GET_SINGLE_ARTICLE_FAILURE,
        error,
    };
};

// GET MOST VIEWED ARTICLES
export const getMostViewedArticlesRequest = () => {
    return {
        type: CONSTANTS.GET_MOST_VIEWED_ARTICLES_REQUEST,
    };
};

export const getMostViewedArticlesSuccess = (data) => {
    return {
        type: CONSTANTS.GET_MOST_VIEWED_ARTICLES_SUCCESS,
        data,
    };
};

export const getMostViewedArticlesFailure = (error) => {
    return {
        type: CONSTANTS.GET_MOST_VIEWED_ARTICLES_FAILURE,
        error,
    };
};

// GET SINGLE VIDEO ACTIONS
export const getSingleVideoRequest = (id) => {
    return {
        type: CONSTANTS.GET_SINGLE_VIDEO_REQUEST,
        id,
    };
};

export const getSingleVideoSuccess = (data) => {
    return {
        type: CONSTANTS.GET_SINGLE_VIDEO_SUCCESS,
        data,
    };
};

export const getSingleVideoFailure = (error) => {
    return {
        type: CONSTANTS.GET_SINGLE_VIDEO_FAILURE,
        error,
    };
};

// GET LESSONS
export const getLessons = (data) => {
    return {
        type: CONSTANTS.GET_LESSONS,
        data,
    };
};

export const getLessonsSuccess = (data) => {
    return {
        type: CONSTANTS.GET_LESSONS_SUCCESS,
        data,
    };
};

export const getLessonsFailure = (error) => {
    return {
        type: CONSTANTS.GET_LESSONS_FAILURE,
        error,
    };
};

// GET LESSONS TOTAL COUNT
export const getTotalLessonsCount = () => {
    return {
        type: CONSTANTS.GET_TOTAL_LESSONS_COUNT,
    };
};

export const getTotalLessonsCountSuccess = (data) => {
    return {
        type: CONSTANTS.GET_TOTAL_LESSONS_COUNT_SUCCESS,
        data,
    };
};

export const getTotalLessonsCountFailure = (error) => {
    return {
        type: CONSTANTS.GET_TOTAL_LESSONS_COUNT_FAILURE,
        error,
    };
};

// GET TEACHERS
export const getTeachers = () => {
    return {
        type: CONSTANTS.GET_TEACHERS,
    };
};

export const getTeachersSuccess = (data) => {
    return {
        type: CONSTANTS.GET_TEACHERS_SUCCESS,
        data,
    };
};

export const getTeachersFailure = (error) => {
    return {
        type: CONSTANTS.GET_TEACHERS_FAILURE,
        error,
    };
};

// GET LESSONS CATEGORIES
export const getLessonsCategories = () => {
    return {
        type: CONSTANTS.GET_LESSONS_CATEGORIES,
    };
};

export const getLessonsCategoriesSuccess = (data) => {
    return {
        type: CONSTANTS.GET_LESSONS_CATEGORIES_SUCCESS,
        data,
    };
};

export const getLessonsCategoriesFailure = (error) => {
    return {
        type: CONSTANTS.GET_LESSONS_CATEGORIES_FAILURE,
        error,
    };
};

// GET TEACHERS
export const getDifficultyLevels = () => {
    return {
        type: CONSTANTS.GET_DIFFICULTY_LEVELS,
    };
};

export const getDifficultyLevelsSuccess = (data) => {
    return {
        type: CONSTANTS.GET_DIFFICULTY_LEVELS_SUCCESS,
        data,
    };
};

export const getDifficultyLevelsFailure = (error) => {
    return {
        type: CONSTANTS.GET_DIFFICULTY_LEVELS_FAILURE,
        error,
    };
};

export const setLessonsFilters = (data) => {
    return {
        type: CONSTANTS.SET_LESSONS_FILTERS,
        data,
    };
};

export const setLessonsSortBy = (data) => {
    return {
        type: CONSTANTS.SET_LESSONS_SORT_BY,
        data,
    };
};

export const getMoreLessons = () => {
    return {
        type: CONSTANTS.GET_MORE_LESSONS,
    };
};

export const getMoreLessonsSuccess = (data) => {
    return {
        type: CONSTANTS.GET_MORE_LESSONS_SUCCESS,
        data,
    };
};

export const getMoreLessonsFailure = (error) => {
    return {
        type: CONSTANTS.GET_MORE_LESSONS_FAILURE,
        error,
    };
};