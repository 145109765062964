import { withRouter, useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Login, Page } from 'components';
import { MAIN_URLS } from 'shared/urlList';
import qs from 'query-string';

const LoginPage = ({ location }) => {
    const history = useHistory();

    const onLogin = () => {
        redirectAfterLogin();
    };

    const redirectAfterLogin = () => {
        const { search } = location;
        if (search) {
            const parsed = qs.parse(search);
            if (parsed && parsed.redirect) {
                history.replace(parsed.redirect);
            }
        } else {
            history.replace(MAIN_URLS.HOME);
        }
    };

    return (
        <Page>
            <Login onLogin={() => onLogin()} />
        </Page>
    );
};

export default withRouter(injectIntl(LoginPage));
