import { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Page, ChapterPreview } from 'components';
import { getChapterSelector, getProgramsStateSelector } from 'store/program/selector';
import { getChapter } from 'store/program/actions';
import { MAIN_URLS } from 'shared/urlList';
import { Loader, Head, Text, VideoPlayer, Button, Carousel } from 'UI';
import { ReactComponent as PlayAlt } from 'assets/icons/player/play@2x.svg';
import { ReactComponent as ArrowBack } from 'assets/icons/arrows/arrow_back.svg';
import { ReactComponent as ListIcon } from 'assets/icons/history/list_view_active.svg';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { toast } from 'shared/alerts';
import API from 'API';

const ChapterPage = ({ history, location }) => {
    const dispatch = useDispatch();
    const chapter = useSelector(getChapterSelector);
    const { inProgress, error } = useSelector(getProgramsStateSelector);
    const { id } = useParams();
    const [ selectedSubchapterIndex, setSelectedSubchapterIndex ] = useState(null);
    const [ nextAvailable, setNextAvailable ] = useState(false);
    const [ previousAvailable, setPreviousAvailable ] = useState(false);
    const [ playlist, setPlaylist ] = useState([]);
    const [ showPlaylist, setShowPlaylist ] = useState(false);
    const [ showPlayer, setShowPlayer ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        dispatch(getChapter(id));
    }, []);

    useEffect(() => {
        if (chapter && chapter.subchapters && chapter.subchapters.length) {
            createPlaylist();
        }
    }, [chapter]);

    useEffect(() => {
        if (playlist && playlist.length && ((selectedSubchapterIndex+1) < playlist.length)) {
            setNextAvailable(true);
        } else {
            setNextAvailable(false);
        }
        if (playlist && playlist.length && selectedSubchapterIndex === 0) {
            setPreviousAvailable(false);
        } else {
            setPreviousAvailable(true);
        }
    }, [selectedSubchapterIndex]);

    useEffect(() => {
        if (playlist && playlist.length) {
            setSelectedSubchapterIndex(0);
        }
    }, [playlist]);

    useEffect(() => {
        if (error) {
            toast.error({
                type: 'program',
                action: 'fetch',
                key: 'chapter'
            });
        }
    }, [error]);

    const nextChapter = () => {
        if (nextAvailable) {
            setSelectedSubchapterIndex(selectedSubchapterIndex + 1);
        }
    };

    const previousChapter = () => {
        if (previousAvailable) {
            setSelectedSubchapterIndex(selectedSubchapterIndex - 1);
        }
    };

    const createPlaylist = () => {
        const nplaylist = [];
        chapter.subchapters.forEach((subchapter, i) => {
            if (subchapter.__component !== 'program-chapter.subchapter_exercise') {
                nplaylist.push(subchapter);
            }
        });
        setPlaylist(nplaylist);
    };

    const playVideo = () => {
        setShowPlayer(true);
        const payload = {
            id: playlist[selectedSubchapterIndex].id,
            type: 'program_subchapter_video',
            progress: 100,
        };

        try {
            setLoading(true);
            API.post('/content/progress', payload);
        } catch (error) {
            toast.error({
                type: 'program',
                action: 'update',
                key: 'progress'
            });
        }
        setLoading(false);
    };

    const goBack = () => {
        setShowPlaylist(false);
        setShowPlayer(false);
        history.goBack();
    };

    const togglePlaylist = (value) => {
        setShowPlaylist(value);
    };

    return <Page className="text-md bg-fake-black">
        <Loader loading={inProgress || loading ? true : false} isFixed={true} />
        {selectedSubchapterIndex !== null && 
            <div key={selectedSubchapterIndex}>
                <section className="flex flex-row justify-between p-6 bg-no-repeat bg-top" style={{ 
                        backgroundImage: `linear-gradient(to top right, #171717 40%, transparent), url(${chapter.program?.hero_image?.formats?.medium?.url})` 
                    }}>
                    <Button clicked={goBack} noStyle={true} weight="medium" className="w-2/3">
                        <div className="flex flex-row items-center gap-2">
                            <ArrowBack className="h-5 w-5" />
                            <span className="text-left">{chapter.program?.title}</span>
                        </div>
                    </Button>
                    <Button noStyle={true} weight="medium" className="w-1/3" clicked={() => togglePlaylist(true)}>
                        <div className="flex flex-row items-center justify-end gap-2">
                            <div>Playlist</div>
                            <div className="bg-primary p-3 rounded-full flex items-center justify-center">
                                <ListIcon height="18" width="20" />
                            </div>
                        </div>
                    </Button>
                </section>

                <section>
                    {playlist[selectedSubchapterIndex].dailymotion_id &&
                        <div>
                            {showPlayer ?
                                <VideoPlayer video={playlist[selectedSubchapterIndex].dailymotion_id} /> :
                                <div className="relative items-center justify-center" onClick={() => playVideo()}>
                                    <img src={playlist[selectedSubchapterIndex].dm_data?.thumbnail_720_url} 
                                        alt="Click to start video"
                                        className="w-full"/>
                                    <div className="absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 h-full z-5">
                                        <PlayAlt />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    <div className="px-8 pt-8 pb-2 flex flex-row gap-2">
                        <Button size="md" bgColor="transparent" rounded="md" disabled={!previousAvailable}
                            className="w-1/2 border" weight="medium" clicked={previousChapter}>Previous</Button>
                        <Button size="md" rounded="md" className="w-1/2" weight="medium" disabled={!nextAvailable}
                            clicked={nextChapter}>Next</Button>
                    </div>
                </section>

                <section className="px-8 pb-8">
                    <Head weight="medium" size="lg" className="py-3">
                        {playlist[selectedSubchapterIndex].title}
                    </Head>
                    <Text>
                        {playlist[selectedSubchapterIndex].description}
                    </Text>
                </section>

                {chapter.program.chapters && chapter.program.chapters?.length ?
                    <section className="pb-8">
                        <Head weight="medium" size="md" level={2} className="px-8 pb-8">
                            {chapter.program.title} chapters
                        </Head>
                        <Carousel centerMode={true} centerPadding="35px" variableWidth={false}>
                            {chapter.program.chapters?.map((chapter, i) => {
                                return <ChapterPreview index={i} chapter={chapter} key={i}/>;
                            })}
                        </Carousel>
                    </section> : <></>
                }

            </div>
        }

        {chapter && playlist && (!!selectedSubchapterIndex || selectedSubchapterIndex === 0) &&
            <BottomSheet open={showPlaylist} onDismiss={() => togglePlaylist(false)} className="text-white p-4">
                <div className="px-8 mb-8">
                    <Head level={2} size="lg" weight="medium">{chapter.title}</Head>
                    <Text>{chapter.description}</Text>
                </div>
                <ul>
                    {playlist.map((subchapter, i) => {
                        return <li className="px-8 py-4 even:bg-grey-darker odd:bg-grey-dark"
                            key={i}
                            onClick={() => {
                                setSelectedSubchapterIndex(i); 
                                setShowPlaylist(false);
                            }}
                            style={{ fontWeight: (subchapter.id === playlist[selectedSubchapterIndex].id ? 'bold' : 'normal') }}>
                            {subchapter.title}
                        </li>;
                    })}
                </ul>
            </BottomSheet>
        }
    </Page>;
};

export default withRouter(ChapterPage);
