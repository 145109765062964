import * as constants from './constants';

export const redirect = (url = '/', hard = false) => {
    return {
        type: constants.REDIRECT,
        url,
        hard,
    };
};

export const setTableHeight = (height) => {
    return {
        type: constants.SET_TABLE_HEIGHT,
        height,
    };
};

// Action to toggle all headers modal from anywhere
// modalKey can be register, login, upgrade, guest
export const toggleModal = (modalKey) => {
    return {
        type: constants.TOGGLE_MODAL,
        modalKey,
    };
};

export const updateMeta = (newMeta) => {
    return {
        type: constants.UPDATE_META,
        newMeta,
    };
};

export const initCookiesConsent = (localConsent) => {
    return {
        type: constants.INIT_COOKIES_CONSENT,
        localConsent
    };
};

export const updateCookiesConsent = ({ category, choice }) => {
    return {
        type: constants.UPDATE_COOKIES_CONSENT,
        data: { 
            category,
            choice
        }
    };
};

export const activateWebview = (token) => {
    return {
        type: constants.ACTIVATE_WEBVIEW,
        token
    };
};
