import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import 'react-spring-bottom-sheet/dist/style.css';
import 'react-circular-progressbar/dist/styles.css';
import "react-toggle/style.css";
import App from './App';
import store from './store/root/store';
import { BrowserRouter as Router } from 'react-router-dom';

const app = (
    <HelmetProvider>
        <Router>
                <App store={store} />
        </Router>
    </HelmetProvider>
);

ReactDOM.render(app, document.getElementById('root'));
