import { takeLatest, put as dispatch } from 'redux-saga/effects';

import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';

export function* footerSaga() {
    yield takeLatest(constants.GET_FOOTER_REQUEST, getFooterDataRequest);
    yield takeLatest(constants.SUBSCRIBE_NL_REQUEST, subscribeNewsletterRequest);
}

function* getFooterDataRequest() {
    try {
        const response = yield api.getFooterData();
        yield dispatch(actions.getFooterSuccess(response.data.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('getFooterFailure failed', e);
        yield dispatch(actions.getFooterFailure(e));
    }
}

function* subscribeNewsletterRequest(params) {
    try {
        const response = yield api.subscribeNewsletter(params.data);
        yield dispatch(actions.subscribeNewsletterSuccess(response.data));
    } catch (e) {
        //eslint-disable-next-line
        console.warn('subscribeNewsletterFailure failed', e);
        yield dispatch(actions.subscribeNewsletterFailure(e));
    }
}
