import React from "react";
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";

export default function Form({ defaultValues, children, onSubmit, ...props }) {
  const { 
    handleSubmit, 
    register, 
    setValue, 
    formState: { errors } 
  } = useForm({ 
    defaultValues
  });

  const typeAllowed = (type) => {
    // Exclude every html tags
    // Allow only react component
    return typeof(type) === "function";
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...props}>
      {React.Children.map(children, child => {
        return (
          <>
            {child.props.name && typeAllowed(child.type)
              ? <>
                  {React.createElement(child.type, {
                    ...{
                      ...child.props,
                      register: register,
                      key: child.props.name,
                      setvalue: setValue, // use  function to force value change
                      errors: errors[child.props.name]
                    }
                  })}
                </>
              : child}
          </>
        );
      })}
    </form>
  );
}

Form.propTypes = {
    defaultValues: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array])
        .isRequired,
    onSubmit: PropTypes.func
};
