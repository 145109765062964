import React from 'react';

import data from 'assets/animations/main_loader.gif';

const Spinner = ({ size, style, className }) => {
    return (
		<div
			className={className}
			style={{
				...style,
				width: size ? size : '120px',
				height: size ? size : '120px',
				backgroundImage: `url(${data})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
			}}
		/>
	);
};

export default Spinner;
