import { ChoosePlan } from 'components';
import { Head, Text, Button } from 'UI';
import { ReactComponent as Check } from 'assets/icons/common/checkmark_ok.svg';
import HeroUpgrade from 'assets/images/hero_upgrade.jpeg';

const UpgradePrompt = ({ onClose }) => {
    return (
        <div style={{backgroundImage: `url(${HeroUpgrade})`}}
            className="flex flex-col bg-blend-multiply py-12 px-7 bg-top bg-no-repeat bg-cover bg-center">
            <Head size="md" weight="medium" level="2">
                Need to keep your training going? Unlock all our training suite.
            </Head>
            <section className="pt-14 pb-4">
                <ChoosePlan />
            </section>

            <section className="my-2">
                <ul className="flex flex-col gap-5">
                    <li className="flex items-baseline gap-3">
                        <div><Check /></div>
                        <Text>Full access to all lessons by leading masters including Levon Aronian, MVL, Anish Giri, Sopiko Guramishcili, Tania Sachdev and many more</Text>
                    </li>
                    <li className="flex items-baseline gap-3">
                        <div><Check /></div>
                        <Text>Full access to all masterclass episodes</Text>
                    </li>
                    <li className="flex items-baseline gap-3">
                        <div><Check /></div>
                        <Text>Train with more than 50 000 puzzles covering every aspect of the game</Text>
                    </li>
                    <li className="flex items-baseline gap-3">
                        <div><Check /></div>
                        <Text>Full access to documentaries, interviews, podcasts and live tournaments broadcasts</Text>
                    </li>
                </ul>
                <div className="text-center mt-5">
                    <Button bgColor="transparent" textColor="white" size="lg" clicked={() => onClose()}>Not now</Button>
                </div>
            </section>
        </div>
    );
};

export default UpgradePrompt;
