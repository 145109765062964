export const CHECK_AUTH = 'USER:CHECK_AUTH';
export const CHECK_AUTH_LOGOUT = 'USER:CHECK_AUTH_LOGOUT';
export const USER_LOGOUT = 'USER:USER_LOGOUT';
export const CLEAR_USER = 'USER:CLEAR_USER';

export const GET_USER_REQUEST = 'USER:GET_USER_REQUEST';
export const GET_USER_FAILURE = 'USER:GET_USER_FAILURE';
export const GET_USER_SUCCESS = 'USER:GET_USER_SUCCESS';

export const SET_USER_REQUEST = 'USER:SET_USER_REQUEST';
export const SET_USER_SUCCESS = 'USER:SET_USER_SUCCESS';
export const SET_USER_FAILURE = 'USER:SET_USER_FAILURE';

export const SET_USER_AVATAR_REQUEST = 'USER:SET_USER_AVATAR_REQUEST';
export const SET_USER_AVATAR_SUCCESS = 'USER:SET_USER_AVATAR_SUCCESS';
export const SET_USER_AVATAR_FAILURE = 'USER:SET_USER_AVATAR_FAILURE';

export const GET_REFRESH_TOKEN_REQUEST = 'USER:GET_REFRESH_TOKEN_REQUEST';
export const GET_REFRESH_TOKEN_SUCCESS = 'USER:GET_REFRESH_TOKEN_SUCCESS';
export const GET_REFRESH_TOKEN_FAILURE = 'USER:GET_REFRESH_TOKEN_FAILURE';

export const SET_REFRESH_TOKEN = 'USER:SET_REFRESH_TOKEN';

export const SET_ACCESS_TOKEN = 'USER:SET_ACCESS_TOKEN';
export const REMOVE_ACCESS_TOKEN = 'USER:REMOVE_ACCESS_TOKEN';

export const SET_GUEST_USER = 'USER:SET_GUEST_USER';
export const RESET_USER = 'USER:RESET_USER';
export const SET_USER_TYPE = 'USER:SET_USER_TYPE';
export const SET_USER_COLORS = 'USE:SET_USER_COLORS';

export const LOGIN_REQUEST = 'USER:LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'USER:LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USER:LOGIN_FAILURE';
export const LOGIN_RESET = 'USER:LOGIN_RESET';

export const LOGOUT_REQUEST = 'USER:LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'USER:LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'USER:LOGOUT_FAILURE';

export const REGISTER_REQUEST = 'USER:REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'USER:REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'USER:REGISTER_FAILURE';

export const REGISTER_NO_CONFIRM_REQUEST = 'USER:REGISTER_NO_CONFIRM_REQUEST';
export const REGISTER_NO_CONFIRM_SUCCESS = 'USER:REGISTER_NO_CONFIRM_SUCCESS';
export const REGISTER_NO_CONFIRM_FAILURE = 'USER:REGISTER_NO_CONFIRM_FAILURE';

export const EMAIL_REGISTER_REQUEST = 'USER:EMAIL_REGISTER_REQUEST';
export const EMAIL_REGISTER_SUCCESS = 'USER:EMAIL_REGISTER_SUCCESS';
export const EMAIL_REGISTER_FAILURE = 'USER:EMAIL_REGISTER_FAILURE';

export const FACEBOOK_LOGIN_REQUEST = 'USER:FACEBOOK_LOGIN_REQUEST';
export const FACEBOOK_LOGIN_SUCCESS = 'USER:FACEBOOK_LOGIN_SUCCESS';
export const FACEBOOK_LOGIN_FAILURE = 'USER:FACEBOOK_LOGIN_FAILURE';

export const GOOGLE_LOGIN_REQUEST = 'USER:GOOGLE_LOGIN_REQUEST';
export const GOOGLE_LOGIN_SUCCESS = 'USER:GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAILURE = 'USER:GOOGLE_LOGIN_FAILURE';

export const APPLE_LOGIN_REQUEST = 'USER:APPLE_LOGIN_REQUEST';
export const APPLE_LOGIN_SUCCESS = 'USER:APPLE_LOGIN_SUCCESS';
export const APPLE_LOGIN_FAILURE = 'USER:APPLE_LOGIN_FAILURE';

export const CHECK_USERNAME_REQUEST = 'USER:CHECK_USERNAME_REQUEST';
export const CHECK_USERNAME_SUCCESS = 'USER:CHECK_USERNAME_SUCCESS';
export const CHECK_USERNAME_FAILURE = 'USER:CHECK_USERNAME_FAILURE';

export const CHECK_EMAIL_REQUEST = 'USER:CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_SUCCESS = 'USER:CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_FAILURE = 'USER:CHECK_EMAIL_FAILURE';

export const START_PASSWORD_RECOVERY_REQUEST =
    'USER:START_PASSWORD_RECOVERY_REQUEST';
export const START_PASSWORD_RECOVERY_SUCCESS =
    'USER:START_PASSWORD_RECOVERY_SUCCESS';
export const START_PASSWORD_RECOVERY_FAILURE =
    'USER:START_PASSWORD_RECOVERY_FAILURE';
export const RESET_PASSWORD_RECOVERY = 'USER:RESET_PASSWORD_RECOVERY';

export const RECOVER_PASSWORD_REQUEST = 'USER:RECOVER_PASSWORD_REQUEST';
export const RECOVER_PASSWORD_SUCCESS = 'USER:RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILURE = 'USER:RECOVER_PASSWORD_FAILURE';
export const RESET_RECOVER_PASSWORD = 'USER:RESET_RECOVER_PASSWORD';

export const SHOW_REACTIONS = 'USER:SET_SHOW_REACTION';
export const HIDE_REACTIONS = 'USER:SET_HIDE_REACTION';

export const REDIRECT_AFTER_LOGIN = 'USER:REDIRECT_AFTER_LOGIN';
