import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { toast } from 'shared/alerts';

import { ReactComponent as Logo } from 'assets/icons/common/logo_2@2x.svg';

import * as actions from 'store/user/actions';
import * as userSelectors from 'store/user/selector';

import { 
    Button, 
    Text,
    Head,
    Input,
    Form,
    Loader
} from 'UI';

const ForgotPassword = ({ onCancel, ...props }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const startPasswordRecoverySelector = useSelector(
        userSelectors.startPasswordRecoveryRequest,
        shallowEqual
    );

    const onSubmit = (data) => {
        const payload = {
            email: data.email.trim()
        };

        dispatch(actions.startPasswordRecoveryRequest(payload));
    };

    useEffect(() => {
        const { error, isSuccessful, inProgress } = startPasswordRecoverySelector;

        if (inProgress) {
            setLoading(true);
        } else {
            setLoading(false);
        }

        if (error) {
            toast.error({ 
                type: 'passwordRecovery', 
                action: 'create',
                APIError: error.data.error
            });
        }

        if (isSuccessful) {
            toast.success('We sent you an email with instructions to reset your password.');
        }

        dispatch(actions.resetPasswordRecovery());
    }, [startPasswordRecoverySelector]);

    return (
        <div className="pt-20 flex flex-col items-center min-h-screen justify-center bg-gradient-to-r from-purple-dark to-purple-light relative" {...props}>
            <Loader loading={loading} bgColor="white" className="bg-opacity-40" />
            
            <section className="flex flex-col items-start justify-center h-full w-full px-8">
                <Logo className="self-center mb-12" />
                <Head size="lg" weight="medium">Forgot your password?</Head>
                <Text>Enter the email address you used to sign up and we'll send you a verification link to reset your password.</Text>
            </section>

            <section className="w-full px-8 pt-4">
                <Form onSubmit={onSubmit} noValidate>
                    <Input label="E-mail" type="email" name="email" placeholder="Your email"
                        rules={{required: true, isEmail: true}} />
                    <Button bgColor="white" textColor="purple-dark" size="lg" type="submit" rounded="md" className="my-6">Recover my password</Button>
                </Form>
            </section>

            <section className="flex flex-col items-center">
                <Button className="underline" bgColor="none" size="sm" clicked={onCancel}>Cancel</Button>
            </section>
        </div>
    );
};

export default ForgotPassword;
