import React, { useState, useEffect, createRef } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    LessonPreview,
    Page
} from 'components';

import { 
    Head, 
    Text,
    Carousel,
    VideoPlayer,
    Accordion,
    Loader,
    Selector
} from 'UI';

import { updateMeta } from 'store/util/actions';
import { selectDifficultyLevels, selectLessonsError } from 'store/strapi/selector';
import { getDifficultyLevels } from 'store/strapi/actions';
import STRAPI from 'API_STRAPI';
import API from 'API';
import { toast } from 'react-toastify';
import { handleAPIErrors } from 'shared/errors';


// import { ReactComponent as Check } from 'assets/icons/common/checkmark_ok.svg';

const LessonPage = ({ location, history }) => {
    const ref = createRef();
    const [lesson, setLesson] = useState({});
    const [selectedChapter, setSelectedChapter] = useState(null);
    const { id } = useParams();
    const error = useSelector(selectLessonsError);
    const difficultyLevels = useSelector(selectDifficultyLevels);
    const dispatch = useDispatch();
    const [sameTeacher, setSameTeacher] = useState([]);
    const [sameTopic, setSameTopic] = useState([]);
    const [loading, setLoading] = useState(false);

    const loadLesson = async () => {
        setLoading(true);
        try {
            const { data } = await API.get(`/cms/lessons/${id}`);
            const { chapters } = data;
            setLesson(data);
            // setProgress(progress);
            setSelectedChapter(chapters[0]);
        } catch (error) {
            if (error.response && error.response.status) {
                handleAPIErrors(error.response.status, history, location.pathname);
            } else {
                toast.error({ 
                    type: 'lesson', 
                    action: 'fetch',
                    key: 'all',
                });

            }
        }
    };

    const loadRelatedLessons = async () => {
        try {
            // fetch from the same teacher
            if (lesson?.teacher?.id) {
                const { data } = await STRAPI.get(
                    `/lessons/?_limit=12&teacher=${lesson.teacher.id}&_sortBy=title:asc`
                );
                setSameTeacher(data);
            }

            // fetch from the same topic
            if (lesson?.lesson_category?.id) {
                const { data } = await API.get(
                    `/cms/lessons/?_limit=12&lesson_category=${lesson.lesson_category.id}&_sortBy=difficulty_level:asc`
                );
                setSameTopic(data);
            }
        } catch (error) {
            // eslint-disable-next-line
            console.log(error);
        }

        setLoading(false);
    };

    useEffect(() => {
        loadRelatedLessons();
        ref?.current.scrollIntoView({ behavior: 'smooth' });
        if (lesson.id) {
            dispatch(updateMeta({
                route: location.pathname, 
                title: `learn chess online - ${lesson.title}`,
                metaDesc: `${lesson.description_short} - ${lesson.description}`
            }));
        }
    }, [lesson]);

    useEffect(() => {
        if (!difficultyLevels) {
            dispatch(getDifficultyLevels());
        }
    }, [difficultyLevels]);

    // TODO handle user subscription plan to see if he can go to current lesson
    useEffect(() => {
        if (error) {
            //eslint-disable-next-line
            console.log(error);
        }
    }, [error]);

    useEffect(() => {
        if ((lesson && id !== lesson.id) || !lesson) {
            loadLesson();
        }
    }, [id]);

    return (
        <Page className="flex-grow pb-8" withPartials ref={ref} key={lesson}>
            {lesson && lesson.chapters ?
                <>
                    <Loader loading={loading} isFixed={true} className="bg-opacity-40" />
                    <section className="pb-5 bg-fake-black"> 
                        {selectedChapter &&
                            <VideoPlayer
                                video={
                                    selectedChapter.dailymotion_id
                                }
                                autoplay={true}
                            />
                        }
                        <div className="px-8 pt-4 pb-2">
                            {lesson.title && <Head size="lg" weight="normal" className="mb-4">{lesson.title}</Head>}
                            {lesson.description && <Text size="sm">{lesson.description}</Text>}
                        </div>
                    </section>

                    <section className="px-8 pt-2 pb-8 bg-fake-black">
                        <Accordion panels={[
                            {
                                label: 'Lessons',
                                content: (<Selector items={lesson.chapters} 
                                        displayKeyName="title" selected={selectedChapter} 
                                        onSelection={setSelectedChapter}/>
                                )
                            }
                        ]} defaultActiveTab={0} />
                    </section>

                    {sameTopic.length > 0 &&
                        <section className="py-4 bg-black">
                            <Head level="2" size="md" weight="medium" className="px-8">On the same topic</Head>
                            <div className="py-6 pl-8">
                                <Carousel>
                                    {sameTopic.map((lesson, i) => {
                                        return (<LessonPreview lesson={lesson} key={i} headingLevel={3} className="mr-5" />);
                                    })}
                                </Carousel>
                            </div>
                        </section>
                    }

                    {sameTeacher.length > 0 &&
                        <section className="py-4 bg-black">
                            <Head level="2" size="md" weight="medium" className="px-8">From the same teacher</Head>
                            <div className="py-6 pl-8">
                                <Carousel>
                                    {sameTeacher.map((lesson, i) => {
                                        return (<LessonPreview lesson={lesson} key={i} headingLevel={3} className="mr-5" />);
                                    })}
                                </Carousel>
                            </div>
                        </section>
                    }
                </>
            :<></>}
        </Page>
    );      
};

export default withRouter(LessonPage);
