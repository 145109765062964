import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ReactComponent as Clock } from 'assets/icons/common/clock.svg';

import { formatDateFromISO } from 'shared/date';

import { Text, Head } from 'UI';

const ArticlePreview = ({ article, headingLevel }) => {
    const [style] = useState({
        backgroundImage: `url(${article.hero_image.formats.medium ? article.hero_image.formats.medium.url : article.hero_image.formats.thumbnail.url})`
    });

    return (
        <Link to={`/articles/${article.id}`} className="block">
            <div>
                <div className="relative h-40 w-full bg-no-repeat bg-cover bg-center" style={style}></div>
                <div className="pt-3 pb-8">
                    <Text size="xs" weight="normal" className="flex items-center justify-between">
                        <div className="flex items-center gap-1">
                            <div>{formatDateFromISO(article.date_posted)} • </div>
                            <div className="opacity-60 flex gap-1">
                                <Clock className="inline" /> 
                                <div>{article.time_to_read} min</div>
                            </div>
                        </div>
                    </Text>
                    <Head level={headingLevel} weight="normal" paddingY={2}>{article.title}</Head>
                </div>
            </div>
        </Link>
    );
};

ArticlePreview.propTypes = {
    article: PropTypes.object.isRequired,
    headingLevel: PropTypes.number.isRequired
};

ArticlePreview.defaultProps = {
    headingLevel: 2
};

export default ArticlePreview;
