import React, { useState, useEffect } from 'react';
import { getAllArticlesSelector } from 'store/strapi/selector';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getAllArticlesRequest } from'store/strapi/actions';
import { toast } from 'react-toastify';

import { 
    ArticlePreview,
    Page
} from 'components';

import {
    Head,
    Button,
    Loader
} from 'UI';

const ArticlesPage = () => {
    const dispatch = useDispatch();
    const [limit] = useState(8);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const { inProgress, error, allArticles: { data: articles } } = useSelector(getAllArticlesSelector, shallowEqual);
    
    useEffect(() => {
        fetchArticles();

        // return () => dispatch(clearAllArticles());
    }, [offset]);

    useEffect(() => {
        if (error) {
            toast.error({ 
                type: 'article', 
                action: 'fetch',
                key: 'all',
            });
        }
    }, [error]);

    useEffect(() => {
        if (inProgress) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [inProgress]);

    const loadMoreData = () => {
        setOffset(offset + limit);
    };

    const fetchArticles = () => {
        dispatch(getAllArticlesRequest({
            limit,
            offset
        }));
    };

    return (
        <Page className="flex-grow bg-fake-black" title="Last articles" withPartials>
            <Loader loading={loading} />
            <section className="p-6">
                <Head size="md" weight="medium">Last articles</Head>
                    <>
                        {articles?.map((article, i) => {
                            return (<ArticlePreview article={article} key={i} />);
                        })}
                        <Button clicked={loadMoreData} weight="medium" size="xl" disabled={
                            articles &&
                            articles.length < offset + limit
                        }>Load more</Button>
                    </>
            </section>
        </Page>
    );
};

export default ArticlesPage;
