import { toast as toastify } from 'react-toastify';
import { errorMessages } from 'shared/errors';

export const toast = {
    ...toastify,
    error: (params) => {
        toastify.error(errorMessages(params));
    },
    success: (content) => {
        toastify.success(content);
    },
};
