import React, { useEffect } from 'react';

import { Header, Footer } from 'components';
import { isWebviewActive } from 'store/util/selector';
import { useSelector } from 'react-redux';

import ScrollToTopOnMount from 'shared/ScrollToTopOnMount';

const WithHeaderAndFooter = ({ ...props }) => {
    const webviewActive = useSelector(isWebviewActive);

    return (
        <>
            <ScrollToTopOnMount />
            <div id="mask" />
            {!webviewActive && <Header />}
            {props.children}
            {!webviewActive && <Footer />}
        </>
    );
};

export default WithHeaderAndFooter;
