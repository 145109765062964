import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Button, Text } from 'UI';
import { 
    MASTERCLASS_URLS,
    ARTICLES_URLS,
    LESSONS_URLS,
    PROGRAMS_URLS
} from 'shared/urlList';

import { selectLessonsTotalCounter } from 'store/strapi/selector';
import { getTotalLessonsCount } from 'store/strapi/actions';
import { getUser } from 'store/user/selector';
import { getIsGuest } from 'shared/localStorage';
import { logoutRequest } from 'store/user/actions';

const Menu = ({ onClose }) => {
    const isGuest = getIsGuest();
    const totalLessonsCounter = useSelector(selectLessonsTotalCounter);
    const dispatch = useDispatch();
    const userInfo = useSelector(getUser, shallowEqual);
    const [userIsFree, setUserIsFree] = useState(true);

    useEffect(() => {
        dispatch(getTotalLessonsCount());
    }, []);

    useEffect(() => {
        if (userInfo) {
            setUserIsFree(isFree());
        }
    }, [userInfo]);

    const isFree = () => {
        if (userInfo.user_tier === 'free' && userInfo.user_tier === 'free') {
            return true;
        } else if (userInfo.subscription && userInfo.subscription.status && (userInfo.subscription.status === 'expired' || userInfo.subscription.status === 'canceled')) {
            return true;
        }
        return false;
    };

    return (
        <div className="flex flex-col flex-grow items-center h-full min-h-screen min-h-screen-mobile justify-between">
            <section className="flex flex-1 flex-col items-center justify-evenly text-2xl p-8 font-bold tracking-wide" 
                role="navigation" aria-label="Main">
                <Link to={{pathname: LESSONS_URLS.ALL}}>
                    Lessons
                    {/*<sup className="text-primary text-xs">{totalLessonsCounter}</sup>*/}
                </Link>
                <Link to={{pathname: MASTERCLASS_URLS.ALL}}>
                    Masterclass
                    {/*<sup className="text-primary text-xs">80</sup>*/}
                </Link>
                <Link to={{pathname: ARTICLES_URLS.ALL}}>
                    Articles
                    {/*<sup className="text-primary text-xs">450</sup>*/}
                </Link>
                <Link to={{pathname: PROGRAMS_URLS.ALL}}>
                    Chess programs
                </Link>
                {isGuest ?
                    <Button className="font-bold" noStyle={true} clicked={() => onClose('login')}>
                        Log in
                    </Button> :
                    <Button className="font-bold" noStyle={true} clicked={() => dispatch(logoutRequest())}>
                        Log out
                    </Button>
                }
            </section>

            {(isGuest || userIsFree) &&
                <section className="flex flex-col flex-shrink w-full px-4 py-6 text-center bg-knight bg-cover">
                {isGuest ?
                    <>
                        <Text size="3xl" weight="bold" className="mb-4">Become a member</Text>
                        <div>
                            <Button className="font-medium" size="md" clicked={() => onClose('register')}>Sign up</Button>
                        </div>
                    </> : 
                    userIsFree &&
                        <>
                            <Text size="3xl" weight="bold" className="mb-4">Elevate your game</Text>
                            <div>
                                <Button className="font-medium" size="md" clicked={() => onClose('upgrade')}>Upgrade</Button>
                            </div>
                        </>
                }
            </section>
            }
        </div>
    );
};

export default withRouter(injectIntl(Menu));
