import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'UI';

const ButtonLink = ({
    disabled,
    clicked,
    children,
    active,
    className,
    buttonClassName,
    to,
    ...props
}) => {
    return (
        <Link
            to={to}
            disabled={disabled}
            className={className}
            onClick={clicked}
        >
            <Button {...props} className={buttonClassName}>{children}</Button>
        </Link>
    );
};

ButtonLink.propTypes = {
    disabled: PropTypes.bool,
    clicked: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.array,
    ]).isRequired,
    active: PropTypes.bool,
    buttonClassName: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object]),
};

ButtonLink.defaultProps = {
    disabled: false,
};

export default ButtonLink;
