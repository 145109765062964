import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const Button = forwardRef(({
    disabled,
    clicked,
    children,
    active,
    className,
    size,
    bgColor,
    textColor,
    rounded,
    withIcon,
    noStyle,
    type,
    weight,
    ...props
}, ref) => {
    if (!className) {
        className = "";
    }

    if (!noStyle) {
        if (size === 'xs') {
            className += " px-2 py-1 text-xs";
        } else if (size === 'sm') {
            className += " px-3 py-1 text-sm";
        } else if (size === 'md') {
            className += " px-12 py-4";
        } else if (size === 'lg') {
            className += " block py-4 mx-auto text-lg w-72 whitespace-nowrap text-center max-w-lg";
        } else if (size === 'xl') {
            className += " block py-4 mx-auto text-lg w-full whitespace-nowrap text-center max-w-lg";
        }

        if (bgColor) {
            className += ` bg-${bgColor}`;
        }

        if (textColor) {
            className += ` text-${textColor}`;
        }

        if (rounded) {
            className += ` rounded-${rounded}`;
        }
    }

    if (withIcon) {
        className += " flex justify-center items-center gap-4";
    }

    if (weight) {
        className += ` font-${weight}`;
    }

    if (disabled) {
        className += `${(disabled ? " bg-opacity-20 cursor-not-allowed" : "")}`;
    }

    className += " tracking-wide whitespace-pre-line max-w-full";
    className = className.trim();

    return (
        <button
            disabled={disabled ? 'disabled':undefined}
            onClick={clicked}
            className={className}
            type={type}
            ref={ref}
            {...props}
        >
            {children}
        </button>
    );
});

Button.propTypes = {
    disabled: PropTypes.bool,
    clicked: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array])
        .isRequired,
    active: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    bgColor: PropTypes.string,
    textColor: PropTypes.string,
    rounded: PropTypes.oneOf(['none', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', 'full']),
    weight: PropTypes.oneOf(['light', 'normal', 'medium', 'bold', 'black']),
    widthIcon: PropTypes.bool,
    noStyle: PropTypes.bool,
    type: PropTypes.string.isRequired,
};

Button.defaultProps = {
    disabled: false,
    size: 'sm',
    bgColor: 'primary',
    rounded: 'sm',
    withIcon: false,
    noStyle: false,
    type: 'button',
    weight: 'normal',
};

export default Button;
