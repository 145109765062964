import { combineReducers } from 'redux';
import utilReducer from 'store/util/reducer';
import localeReducer from 'store/locale/reducer';
import strapiContent from 'store/strapi/reducer';
import subscriptionsReducer from 'store/subscriptions/reducer';
import userReducer from 'store/user/reducer';
import footerReducer from 'store/footer/reducer';
import programReducer from 'store/program/reducer';

// Combine reducers for redux store
const rootReducer = combineReducers({
    user: userReducer,
    locale: localeReducer,
    util: utilReducer,
    subscriptions: subscriptionsReducer,
    strapiContent,
    footer: footerReducer,
    programs: programReducer
});

export default rootReducer;
