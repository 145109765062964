import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'store/util/actions';

import { ReactComponent as Logo } from 'assets/icons/common/logo_2@2x.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/common/menu_icon.svg';

import { 
    Menu, 
    PromotionBanner,
} from 'components';

import {
    Button,
    Modal
} from 'UI';

import { MAIN_URLS } from 'shared/urlList';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const dispatch = useDispatch();

    const doAction = (actionKey = null, value = null) => {
        setShowMenu(false);
        dispatch(toggleModal(null));
        dispatch(toggleModal(actionKey));
    };

    return (
        <Fragment>
            <header className="bg-fake-black" id="navbar">
                {/* <PromotionBanner /> */}
                <section className="flex justify-between items-center py-5 px-6 h-15">
                    <Link
                        to={MAIN_URLS.HOME}
                        className="w-4"
                    >
                        <Logo className="w-3 h-auto" />
                    </Link>
                    <Button
                        bgColor='transparent'
                        className="w-10"
                        clicked={() => setShowMenu(true)}
                    >
                        <MenuIcon className="w-5 h-auto" />
                    </Button>
                </section>
                <Modal label="mainMenu" isVisible={showMenu} close={() => setShowMenu(false)} 
                    description="Navigate on the website by opening this dialog">
                    <Menu onClose={(actionKey, value) => doAction(actionKey, value)} />
                </Modal>
            </header>
        </Fragment>
    );
};

export default Header;
