import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatDuration } from 'shared/time';
import { Text, Head, Lock } from 'UI';

const LessonPreview = ({ lesson, headingLevel, className }) => {

    const wrapperStyle = {
        backgroundImage: `url('${lesson.thumbnail?.formats.medium?.url || lesson.thumbnail?.formats.small?.url}')`
    };

    const lessonColorStyle = {
        backgroundColor: lesson?.difficulty_level?.color
    };

    return (
        <Link to={`/lessons/${lesson.id}`} 
            replace={true}
            className={`${className?(className+' '):''}bg-cover bg-no-repeat bg-center flex flex-col justify-between 
                        block h-80 w-60 relative`}
            style={wrapperStyle}
            onClick={(e) => lesson.locked && e.preventDefault()}>
            {lesson.locked &&
                <Lock />
            }
            <div className="flex justify-between items-center p-2">
                <div className="flex items-center rounded-full tracking-wide h-6 px-3" style={lessonColorStyle}>
                    {lesson.difficulty_level && <Text size="md" weight="bold">{lesson.difficulty_level.value}</Text>}
                </div>
                {/*{ (progression || lesson.isRead) &&
                    <div className="relative">
                        <Tick color={lesson.difficulty_level.color} progression={progression ? progression : null} />
                    </div>
                }*/}
            </div>
            <div className="bg-gradient-to-b from-transparent to-grey-dark p-2">
                <Head size="sm" level={headingLevel}>{lesson?.title}</Head>
                <Text weight="bold" className="tracking-wide" size="md">{lesson?.description_short} - { formatDuration(lesson?.duration) }</Text>
            </div>
        </Link>
    );    
};

LessonPreview.propTypes = {
    lesson: PropTypes.object,
    headingLevel: PropTypes.number.isRequired
};

LessonPreview.defaultProps = {
    headingLevel: 2
};

export default LessonPreview;
