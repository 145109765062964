import React, { useState } from 'react';

import Panel from './Panel/Panel';

const Accordion = ({ panels, defaultActiveTab }) => {
	const [activeTab, setActiveTab] = useState(defaultActiveTab);

	const activateTab = index => {
		setActiveTab(activeTab === index ? -1 : index);
	};

	return (
		<div role='tablist' className="w-full mx-auto">
			{panels.map((panel, index) => (
				<Panel
					key={index}
					activeTab={activeTab}
					index={index}
					{...panel}
					activateTab={() => activateTab(index)}
				/>
			))}
		</div>
	);
};

export default Accordion;
