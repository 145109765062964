import { Login } from 'components';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';

const LoginPrompt = ({ onClose }) => {
    const location = useLocation();
    const history = useHistory();

    const onLogin = () => {
        const { search } = location;
        if (search) {
            const parsed = qs.parse(search);
            if (parsed.redirect) {
                history.push(parsed.redirect);
            }
        } else {
           window.location.reload();
        }
        
    };

    return (
        <Login onLogin={() => onLogin()} />
    );
};

export default LoginPrompt;
