import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { checkValidity } from 'shared/utility';

import { Button, Text } from 'UI';

import { ReactComponent as Visibility } from 'assets/icons/common/visibility.svg';
import { ReactComponent as VisibilityOff } from 'assets/icons/common/visibility_off.svg';

const Input = ({
    label,
    className,
    visibilityButton,
    type,
    name, 
    register,
    setvalue,
    rules,
    errors,
    checkError,
    onInputChange,
    ...props
}) => {
    const [showContent, setShowContent] = useState(false);
    const [dynamicType, setDynamicType] = useState(type);
    const [errorMessage, setErrorMessage] = useState(null);

    const toggleVisibility = () => {
        if (showContent) {
            setShowContent(false);
        } else {
            setShowContent(true);
        }
    };

    useEffect(() => {
        if (showContent) {
            setDynamicType('text');
        } else {
            setDynamicType(type);
        }
    }, [showContent]);

    return (
        <>
            {register &&
                <div className="flex flex-col w-full">
                    {label && 
                        <label htmlFor={name} className="text-lg py-2">{label}</label>
                    }

                    <div className="w-full relative">
                        <input 
                            {...register(name, {
                                validate: value => {
                                    const validation = checkValidity(value, rules);
                                    setErrorMessage(validation.message);
                                    return validation.isValid;
                                }
                            })}
                            name={name}
                            onChange={onInputChange}
                            className={(className ? className : "") + (errors ? " bg-red-800 bg-opacity-30" : "") + " w-full p-5 placeholder-grey-light bg-white bg-opacity-5 shadow-input rounded-t"} 
                            type={dynamicType}
                            {...props} />
                        {visibilityButton &&
                            <Button className="absolute right-2 top-5 z-index-20" bgColor="none" type="button" clicked={toggleVisibility}>
                                {!showContent && <Visibility />}
                                {showContent && <VisibilityOff />}
                            </Button>
                        }
                    </div>
                    {errors && errorMessage && <Text textColor="red-600">{errorMessage}</Text>}
                    {checkError && <Text textColor="red-600">{checkError}</Text>}
                </div>
            }
        </>
    );
};

Input.propTypes = {
    className: PropTypes.any,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    visibilityButton: PropTypes.bool,
    name: PropTypes.string.isRequired,
    register: PropTypes.func,
    setvalue: PropTypes.func,
    rules: PropTypes.object,
    errors: PropTypes.object,
    onInputChange: PropTypes.func,
    checkError: PropTypes.string,
};

Input.defaultProps = {
    type: "text",
    visibilityButton: false
};

export default Input;
