export const GET_HOME_REQUEST = 'OMNIHUB:HOME:GET_HOME_REQUEST';
export const GET_HOME_SUCCESS = 'OMNIHUB:HOME:GET_HOME_SUCCESS';
export const GET_HOME_FAILURE = 'OMNIHUB:HOME:GET_HOME_FAILURE';

export const GET_ALL_ARTICLES_REQUEST =
    'STRAPI:ARTICLES:GET_ALL_ARTICLES_REQUEST';
export const GET_ALL_ARTICLES_SUCCESS =
    'STRAPI:ARTICLES:GET_ALL_ARTICLES_SUCCESS';
export const GET_ALL_ARTICLES_FAILURE =
    'STRAPI:ARTICLES:GET_ALL_ARTICLES_FAILURE';
export const CLEAR_ALL_ARTICLES =
    'STRAPI:ARTICLES:CLEAR_ALL_ARTICLES';

export const GET_SINGLE_ARTICLE_REQUEST =
    'STRAPI:ARTICLES:GET_SINGLE_ARTICLE_REQUEST';
export const GET_SINGLE_ARTICLE_SUCCESS =
    'STRAPI:ARTICLES:GET_SINGLE_ARTICLE_SUCCESS';
export const GET_SINGLE_ARTICLE_FAILURE =
    'STRAPI:ARTICLES:GET_SINGLE_ARTICLE_FAILURE';

export const GET_MOST_VIEWED_ARTICLES_REQUEST =
    'STRAPI:ARTICLES:GET_MOST_VIEWED_ARTICLES_REQUEST';
export const GET_MOST_VIEWED_ARTICLES_SUCCESS =
    'STRAPI:ARTICLES:GET_MOST_VIEWED_ARTICLES_SUCCESS';
export const GET_MOST_VIEWED_ARTICLES_FAILURE =
    'STRAPI:ARTICLES:GET_MOST_VIEWED_ARTICLES_FAILURE';

export const GET_SINGLE_VIDEO_REQUEST =
    'STRAPI:VIDEOS:GET_SINGLE_VIDEO_REQUEST';
export const GET_SINGLE_VIDEO_SUCCESS =
    'STRAPI:VIDEOS:GET_SINGLE_VIDEO_SUCCESS';
export const GET_SINGLE_VIDEO_FAILURE =
    'STRAPI:VIDEOS:GET_SINGLE_VIDEO_FAILURE';

export const GET_LESSONS = 'STRAPI:LESSONS:GET_LESSONS';
export const GET_LESSONS_SUCCESS = 'STRAPI:LESSONS:GET_LESSONS_SUCCESS';
export const GET_LESSONS_FAILURE = 'STRAPI:LESSONS:GET_LESSONS_FAILURE';

export const GET_MORE_LESSONS = 'STRAPI:LESSONS:GET_MORE_LESSONS';
export const GET_MORE_LESSONS_SUCCESS = 'STRAPI:LESSONS:GET_MORE_LESSONS_SUCCESS';
export const GET_MORE_LESSONS_FAILURE = 'STRAPI:LESSONS:GET_MORE_LESSONS_FAILURE';

export const GET_TOTAL_LESSONS_COUNT = 'STRAPI:LESSONS:GET_TOTAL_LESSONS_COUNT';
export const GET_TOTAL_LESSONS_COUNT_SUCCESS = 'STRAPI:LESSONS:GET_TOTAL_LESSONS_COUNT_SUCCESS';
export const GET_TOTAL_LESSONS_COUNT_FAILURE = 'STRAPI:LESSONS:GET_TOTAL_LESSONS_COUNT_FAILURE';

export const GET_TEACHERS = 'STRAPI:LESSONS:GET_TEACHERS';
export const GET_TEACHERS_SUCCESS = 'STRAPI:LESSONS:GET_TEACHERS_SUCCESS';
export const GET_TEACHERS_FAILURE = 'STRAPI:LESSONS:GET_TEACHERS_FAILURE';

export const GET_LESSONS_CATEGORIES = 'STRAPI:LESSONS:GET_LESSONS_CATEGORIES';
export const GET_LESSONS_CATEGORIES_SUCCESS = 'STRAPI:LESSONS:GET_LESSONS_CATEGORIES_SUCCESS';
export const GET_LESSONS_CATEGORIES_FAILURE = 'STRAPI:LESSONS:GET_LESSONS_CATEGORIES_FAILURE';

export const GET_DIFFICULTY_LEVELS = 'STRAPI:LESSONS:GET_DIFFICULTY_LEVELS';
export const GET_DIFFICULTY_LEVELS_SUCCESS = 'STRAPI:LESSONS:GET_DIFFICULTY_LEVELS_SUCCESS';
export const GET_DIFFICULTY_LEVELS_FAILURE = 'STRAPI:LESSONS:GET_DIFFICULTY_LEVELS_FAILURE';

export const SET_LESSONS_FILTERS = 'STRAPI:LESSONS:SET_LESSONS_FILTERS';
export const SET_LESSONS_SORT_BY = 'STRAPI:LESSONS:SET_LESSONS_SORT_BY';
