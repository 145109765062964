import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { WithHeaderAndFooter } from 'components';

const Page = forwardRef(({ withPartials, children, wrapperClassName, ...props }, ref) => {
    const pageContent = (
        <main {...props}>
            {children}
        </main>
    );

    return (
        <div className={wrapperClassName} ref={ref}>
            { withPartials ? 
                <WithHeaderAndFooter>
                    {pageContent}
                </WithHeaderAndFooter> :
                {pageContent}

            }
        </div>
    );
});

Page.propTypes = {
    withPartials: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array])
        .isRequired,
    wrapperClassName: PropTypes.string
};

Page.defaultProps = {
    withPartials: true,
    wrapperClassName: "flex flex-col flex-auto"
};

export default Page;
