import React, { useState, useEffect } from 'react';
import { getSubscriptionPlans } from 'store/subscriptions/selector';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getUser } from 'store/user/selector';
import { getIsGuest } from 'shared/localStorage';
import { MAIN_URLS } from 'shared/urlList';
import { toast } from 'react-toastify';
import API from 'API';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';
import { Head } from 'UI';
import { toggleModal } from 'store/util/actions';

import { 
    Text,
    ButtonLink,
    Toggle,
    Loader,
    Button,
} from 'UI';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SingleUpgradeBlock = ({
  singleProduct,
  redirectUrl,
  purchaseText,
}) => {
  const [planDetails, setPlanDetails] = useState({});
  const { data: subscriptionPlans, inProgress, error } = useSelector(getSubscriptionPlans);
  const userInfo = useSelector(getUser, shallowEqual);
  const [userIsFree, setUserIsFree] = useState(true);
  const isGuest = getIsGuest();
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(false);
  const location = useLocation();

  const isFree = () => {
      if (userInfo.user_tier === 'free' && userInfo.user_tier === 'free') {
        return true;
      } else if (userInfo.subscription && userInfo.subscription.status && (userInfo.subscription.status === 'expired' || userInfo.subscription.status === 'canceled')) {
        return true;
      }
      return false;
  };

  const handlePurchase = async () => {
      if (window.dataLayer && window.dataLayer.push) {
          window.dataLayer.push({'event': 'kc-payment-init'});
      }
      try {
        setLoading(true);
        const response = await API.post('/products/purchase', {
            id: singleProduct.entityId,
            entity: singleProduct.entity,
            final_url: redirectUrl,
        });
        if (response?.data?.data?.id) {
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({
                sessionId: response.data.data.id,
            });
        } 
      } catch (error) {
        console.warn(error);
        toast.error();
        setLoading(false);
      }
      setLoading(false);
  };

  const formatter = (currency) => {
      switch (currency?.toLowerCase()) {
          case 'usd': {
              return '$';
          }
          case 'eur': {
              return '€';
          }
          case 'inr': {
              return '₹';
          }
          default: {
              return 'Missing currency!';
          }
      }
  };

  useEffect(() => {
      if (error) {
          toast.error({ 
              type: 'subscription', 
              action: 'fetch',
              key: 'plans',
          });
      }
  }, [error]);

  useEffect(() => {
    if (userInfo) {
      setUserIsFree(isFree());
    }
  }, [userInfo]);

  useEffect(() => {
    console.log(singleProduct)
  }, [singleProduct]);

  return (
    <>
      {(isGuest || userIsFree) &&
        <>
          {isGuest ?
            <>
              <div>
                <ButtonLink 
                    to={MAIN_URLS.ONBOARDING+`?redirect=${location.pathname}`}
                    size="sm" 
                    weight="medium"
                    buttonClassName="py-3 text-md"
                    style={{width: '327px', fontSize: '16px'}}>Sign up & Start Learning</ButtonLink>
              </div>
            </> : 
            userIsFree &&
              <>
                {planDetails && 
                  <div className="relative">
                    <Loader loading={loading || (inProgress ? inProgress : false)} className="bg-white bg-opacity-20" />
                    <ul className="grid gap-3 grid-cols-2 justify-between relative">
                      <li className="flex flex-col justify-between bg-primary-dark p-4 rounded-md divide-y divide-grey-light">
                          <div className="flex flex-col justify-between pb-2">
                              <Head level="3" size="xs" weight="medium">{purchaseText}</Head>
                          </div>
                          <div className="pt-4 flex flex-col justify-between">
                              {singleProduct && <Text size="sm">{formatter(singleProduct.currentUserPrice.currency)} {singleProduct.currentUserPrice.price}</Text>}
                              <Button size="sm" 
                                  className="py-3 mt-4 text-md"
                                  clicked={() => handlePurchase()}
                                  >
                                      <div className="font-bold text-lg">Buy now</div>
                              </Button>
                          </div>
                      </li>
                      <li className="flex flex-col justify-between bg-primary-dark p-4 rounded-md divide-y divide-grey-light items-center">
                          <div className="flex flex-col justify-between pb-2">
                            <Head level="3" size="xs" weight="medium">Subscribe to unlock all of Kasparovchess</Head>
                          </div>
                          <div className="pt-4 flex flex-col justify-between">
                              <Text size="sm">Inc. a 7-day trial</Text>
                              <Button size="sm" 
                                  className="py-3 mt-4 text-md"
                                  clicked={() => dispatch(toggleModal('upgrade'))}>
                                  <div className="font-bold text-lg">Learn more</div>
                              </Button>
                          </div>
                      </li>
                    </ul>
                  </div>
                }
              </>
          }                      
        </>
      }
    </>
  );
};

export default SingleUpgradeBlock;
