import { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Page, ChapterPreview, ProgramPreview } from 'components';
import { getSingleProgramSelector, getProgramsStateSelector } from 'store/program/selector';
import { getSingleProgram } from 'store/program/actions';
import { PROGRAMS_URLS, MAIN_URLS } from 'shared/urlList';
import { toast } from 'shared/alerts';
import { Loader, Head, Text, VideoPlayer, Button, Carousel, ButtonLink } from 'UI';
import { ReactComponent as Check } from 'assets/icons/common/checkmark_ok.svg';
import { ReactComponent as PlayAlt } from 'assets/icons/player/play@2x.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrows/arrow_right_purple.svg';

const ProgramPage = ({ history, location }) => {
    const dispatch = useDispatch();
    const program = useSelector(getSingleProgramSelector);
    const { inProgress, error } = useSelector(getProgramsStateSelector);
    const { id } = useParams();
    const [showVideoThumb, setShowVideoThumb] = useState(true);

    useEffect(() => {
        dispatch(getSingleProgram(id));
    }, []);

    useEffect(() => {
        if (error) {
            toast.error({
                type: 'program',
                action: 'fetch',
                key: 'one'
            });
        }
    }, [error]);

    return <Page className="text-sm bg-fake-black">
        <Loader loading={inProgress ? inProgress : false} />
        {program &&
            <>
                <div className="px-8 py-6 bg-no-repeat bg-cover bg-center" style={{ 
                    backgroundImage: `linear-gradient(to top right, #171717 40%, transparent), url(${program.hero_image?.formats?.medium?.url})` 
                }}>
                    <Head weight="medium" size="xl" className="max-w-full">{program.title ? program.title : ''}</Head>
                    <div className="pb-6">
                        <div className="flex gap-2 pt-6 pb-4">
                            {program.trainers?.map((trainer, i) => {
                                return (
                                <div key={i} className="relative h-14 w-14 flex items-center justify-center">
                                    <div className="relative z-10 h-12 w-12 rounded-full overflow-hidden shadow-trainer-thumb bg-grey-darker p-3 bg-cover"
                                    style={{ backgroundImage: `url(${trainer.thumbnail?.formats?.thumbnail?.url})` }}></div>
                                    <div className="absolute top-0 h-14 w-14 z-5 rounded-full overflow-hidden shadow-trainer-thumb bg-grey-darker p-3 bg-cover"></div>
                                </div>);
                            })}
                        </div>
                        <Text tagName="p" className="pb-3">
                            Hosted by&nbsp;
                            {program.trainers?.map((trainer, i) => {
                                return (<span key={i}>
                                    <span className="font-medium">{trainer.name}</span>
                                    {program.trainers.length > (i + 1) && 
                                        <><br/>&&nbsp;</>
                                    }
                                </span>);
                            })}
                        </Text>
                        <Text tagName="p" weight="medium" className="border-t pt-3 border-opacity-40">
                            {program.level?.value}{program.chapter_count ? (' • '+program.chapter_count+' chapters') : ''}
                        </Text>
                    </div>
                    {program.bullet_points && program.bullet_points?.length ?
                        <div>
                            <Head weight="medium" size="md" level={2}>What's inside ?</Head>
                            <Text tagName="p" className="py-6">{program.description}</Text>
                            <ul className="grid grid-cols-1 gap-3 pb-6 pt-4">
                                {program.bullet_points?.map((point, i) => {
                                    return <li className="w-full flex items-baseline gap-2" key={i}>
                                        <div className="flex items-center shrink-0"><Check height="10" width="15" /></div>
                                        <Text className="grow" weight="medium">{point}</Text>
                                    </li>;
                                })}
                            </ul>
                        </div> : <></>
                    }
                </div>

                {program.dm_data && program.introduction_video &&
                    <div className="px-4 pb-8">
                        {showVideoThumb ?
                            <Button noStyle={true} clicked={() => setShowVideoThumb(false)} className="relative w-full">
                                <img src={program.dm_data?.thumbnail_480_url} className="w-full" alt="Click to play the video" />
                                <div className="absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 h-full z-10">
                                    <PlayAlt />
                                </div>
                            </Button> :
                            <VideoPlayer video={program.introduction_video} />
                        }
                    </div>
                }

                {program.chapters && program.chapters?.length ?
                    <div className="pb-8">
                        <Head weight="medium" size="md" level={2} className="px-8 pb-8">
                            {`Program chapter${program.chapter_count > 1 ? 's':''}`} ({program.chapter_count})
                        </Head>
                        <Carousel centerMode={true} centerPadding="35px" variableWidth={false}>
                            {program.chapters?.map((chapter, i) => {
                                return <ChapterPreview index={i} chapter={chapter} key={i}/>;
                            })}
                        </Carousel>
                    </div> : <></>
                }

                {program.related_program &&
                    <div className="p-4 bg-grey-dark">
                        <Head weight="medium" size="md" level={2}>
                            Recommanded
                        </Head>
                        <ProgramPreview program={program.related_program} />
                        <ButtonLink 
                            size="lg" weight="medium" className="text-md"
                            bgColor="transparent" to={PROGRAMS_URLS.ALL} textColor="primary">
                            <div className="flex flex-row items-center justify-center gap-2">
                                <span>View all</span><span><ArrowRight /></span>
                            </div>
                        </ButtonLink>
                    </div>
                }
            </>
        }
    </Page>;
};

export default withRouter(ProgramPage);
