// NAV PAGES
const getProgramContentBase = (state) => state.programs;

// ARTICLES
export const getAllProgramsSelector = (state) =>
    getProgramContentBase(state).all;
export const getSingleProgramSelector = (state) =>
    getProgramContentBase(state).single;
export const getChapterSelector = (state) =>
    getProgramContentBase(state).chapter;
export const getProgramsStateSelector = (state) =>
    getProgramContentBase(state);
export const getProgramsFilters = state => getProgramContentBase(state).filters;
export const getProgramsSortBy = state => getProgramContentBase(state).sortBy;
