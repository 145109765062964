import { getUser as getUserLS } from 'shared/localStorage';

const getUserBaseReducer = (state) => state.user;
export const getLoginRequest = (state) => state.user.loginRequest;
export const getUser = (state) => {
    if (state.user && state.user.user) {
        return state.user.user;
    }
    const user = getUserLS();
    if (user) {
        return JSON.parse(user);
    }

    return null;
};
export const getUserColors = (state) => state.user.userColors;
export const getRegisterRequest = (state) => state.user.registerRequest;
export const getUsernameCheckRequest = (state) =>
    state.user.checkUsernameRequest;
export const getEmailCheckRequest = (state) => state.user.checkEmailRequest;
export const startPasswordRecoveryRequest = (state) =>
    state.user.startPasswordRecoveryRequest;
export const recoverPasswordRequest = (state) =>
    state.user.recoverPasswordRequest;

export const getUserAccessToken = (state) => {
    return getUserBaseReducer(state) && getUserBaseReducer(state).accessToken;
};
export const getIsGuestUser = (state) => getUserBaseReducer(state).guestUser;

export const getReactionStatus = (state) =>
    getUserBaseReducer(state).showReaction;
export const setUserDataStatus = (state) =>
    getUserBaseReducer(state).setUserState;
export const getUserRedirectAfterLogin = (state) =>
    getUserBaseReducer(state).redirectAfterLogin;
export const userIsFree = (state) => {
    const user = getUserBaseReducer(state);

    if (!user) {
        return true;
    }

    if (user.user_tier === 'free' && user.user_tier === 'free') {
        return true;
    } else if (!user.subscription || (user.subscription.status && (user.subscription.status === 'expired' || user.subscription.status === 'canceled'))) {
        return true;
    }

    return false;
};
