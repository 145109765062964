// eslint-disable-next-line
import { takeLatest, put as dispatch } from 'redux-saga/effects';

// import * as actions from './actions';
// import * as api from './api';
import * as constants from './constants';
import { setAccessToken } from 'store/user/actions';

export function* utilSaga() {
    yield takeLatest(constants.REDIRECT, redirect);
    yield takeLatest(constants.ACTIVATE_WEBVIEW, activateWebview);
}

export function* redirect({ url, hard = false }) {
    if (!hard) {
        yield window.keyPush(url);
    } else {
        yield window.location = url;
    }
}

export function* activateWebview({ token }) {
    yield dispatch(setAccessToken(token));
}
