import React from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'UI';

const Loader = ({ 
    loading, 
    className, 
    bgColor,
    isFixed
}) => {
    return (
        <>
            {loading &&
                <div className={`${className} ${isFixed?'fixed':'absolute'} left-0 top-0 bottom-0 right-0 flex items-center justify-center z-30 bg-${bgColor}`}>
                    <Spinner />
                </div>
            }
        </>
    );
};

Loader.propTypes = {
    loading: PropTypes.bool.isRequired,
    className: PropTypes.string,
    bgColor: PropTypes.string,
    isFixed: PropTypes.bool
};

Loader.defaultProps = {
    loading: false,
    bgColor: 'black',
    isFixed: false
};

export default Loader;
