import * as CONSTANTS from './constants';

// PROGRAMS ACTIONS
export const getAllPrograms = (data) => {
    return {
        type: CONSTANTS.GET_PROGRAMS,
        data,
    };
};

export const getAllProgramsSuccess = (data) => {
    return {
        type: CONSTANTS.GET_PROGRAMS_SUCCESS,
        data,
    };
};

export const getAllProgramsFailure = (error) => {
    return {
        type: CONSTANTS.GET_PROGRAMS_FAILURE,
        error,
    };
};

export const clearAllPrograms = () => {
    return {
        type: CONSTANTS.CLEAR_PROGRAMS,
    };
};

// SINGLE PROGRAM ACTIONS
export const getSingleProgram = (id) => {
    return {
        type: CONSTANTS.GET_PROGRAM,
        id,
    };
};

export const getSingleProgramSuccess = (data) => {
    return {
        type: CONSTANTS.GET_PROGRAM_SUCCESS,
        data,
    };
};

export const getSingleProgramFailure = (error) => {
    return {
        type: CONSTANTS.GET_PROGRAM_FAILURE,
        error,
    };
};

// CHAPTER ACTIONS
export const getChapter = (id) => {
    return {
        type: CONSTANTS.GET_CHAPTER,
        id,
    };
};

export const getChapterSuccess = (data) => {
    return {
        type: CONSTANTS.GET_CHAPTER_SUCCESS,
        data,
    };
};

export const getChapterFailure = (error) => {
    return {
        type: CONSTANTS.GET_CHAPTER_FAILURE,
        error,
    };
};

export const setProgramsFilters = (data) => {
    return {
        type: CONSTANTS.SET_PROGRAMS_FILTERS,
        data,
    };
};

export const setProgramsSortBy = (data) => {
    return {
        type: CONSTANTS.SET_PROGRAMS_SORT_BY,
        data,
    };
};

export const getMorePrograms = () => {
    return {
        type: CONSTANTS.GET_MORE_PROGRAMS,
    };
};

export const getMoreProgramsSuccess = (data) => {
    return {
        type: CONSTANTS.GET_MORE_PROGRAMS_SUCCESS,
        data,
    };
};

export const getMoreProgramsFailure = (error) => {
    return {
        type: CONSTANTS.GET_MORE_PROGRAMS_FAILURE,
        error,
    };
};
