import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { toast } from 'react-toastify';
import { MASTERCLASS_URLS } from 'shared/urlList';
import {
    MasterclassPreview,
    Page,
    SingleUpgradeBlock,
} from 'components';

import { 
    Head, 
    Text,
    Carousel,
    Loader,
    ButtonLink,
    Toggle,
} from 'UI';
import STRAPI from 'API_STRAPI';


const MasterclassPage = () => {
    const [masterclass, setMasterclass] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchMasterclass = async () => {
        setLoading(true);
		try {
			const response = await STRAPI.get(
				'/masterclasses/learn-with-garry'
			);
			const { data } = response;
			setMasterclass(data);
		} catch (error) {
			// eslint-disable-next-line
			console.warn(error);
            toast.error({ 
                type: 'masterclass', 
                action: 'fetch',
                key: 'all',
            });
		}
        setLoading(false);
	};
    useEffect(() => {
        fetchMasterclass();
    }, []);

    return (
        <Page className="flex-grow" wrapperClassName="flex flex-col flex-auto bg-fake-black" title={`Masterclass: Learn with Garry Kasparov`} withPartials>
            <Loader loading={loading} isFixed/>
            <section className="pt-28 pb-8 w-full bg-top-half bg-gradient-kasparov bg-contain bg-no-repeat">
                <div className="px-6  mb-10 text-center">
                    <Head size="xl" weight="medium" style={{fontSize: '40px'}}>
                        Learn chess<br/>
                        with a world champion
                    </Head>
                    <Text size="sm" className="mt-4 mb-10 leading-relaxed">
                    Garry Kasparov shares with you his chess mastery. Hear the stories he never told, secrets he never shared. Develop the skills to become a totally new player. 
                    </Text>
                    {masterclass.iap && !masterclass.iap.purchased && 
                        <SingleUpgradeBlock
                            purchaseText={<>Unlock<br/> Full<br/> Masterclass</>}
                            singleProduct={masterclass.iap}
                            redirectUrl={MASTERCLASS_URLS.ALL} />
                    }
                </div>
                {masterclass && masterclass.chapters && masterclass.chapters.map((chapter, i) => {
                    return (
                        <div key={i}>
                            <div className="px-6">
                                <Head level="2" weight="medium" size="md" className="mt-6">
                                    {chapter.title}
                                </Head>
                                <Text size="sm" opacity="lighter" className="pb-2">{chapter.episodes && chapter.episodes.length} épisodes</Text>
                                <Text size="sm" className="my-4">{chapter.description}</Text>
                            </div>
                            <div className="pl-6">
                                <Carousel>
                                    {chapter.episodes && chapter.episodes.map((episode, i) => {
                                        return (
                                            <MasterclassPreview masterclass={episode} key={i} headingLevel={3} />
                                        );
                                    })}
                                </Carousel>
                            </div>
                        </div>
                    );
                })}
            </section>
        </Page>
    );    
};

export default withRouter(MasterclassPage);
