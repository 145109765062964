import React, { useState, useEffect, createRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getSingleVideoRequest } from 'store/strapi/actions';
import { getVideoSelector } from 'store/strapi/selector';
import { updateMeta } from 'store/util/actions';
import { toast } from 'react-toastify';
import {
    Page,
    JourneyPreview,
    DocumentaryPreview
} from 'components';
import { 
    Loader,
    VideoPlayer,
    Head,
    Text,
    Carousel
} from 'UI'; 
import { handleAPIErrors } from 'shared/errors';

const VideoPage = ({ location, history }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const ref = createRef();
    const [videoCategory, setVideoCategory] = useState(null);
    const { singleVideo, inProgress, error } = useSelector(getVideoSelector, shallowEqual);

    const fetchVideo = () => {
        dispatch(getSingleVideoRequest(id));
    };

    const isDocumentary = (video) => {
        if (video.video_category && video.video_category.name === 'Documentaries') {
            return true;
        }
        return false;
    };

    const isJourney = (video) => {
        if (video.video_category && video.video_category.name === 'Chess Journeys') {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (id) {
            fetchVideo();
        }
    }, [id]);

    useEffect(() => {
        if (singleVideo.data) {
            dispatch(updateMeta({
                route: location.pathname, 
                title: `${singleVideo.data.title}`,
                metaDesc: `Kasparovchess - ${singleVideo.data.description}`
            }));
        }
    }, [singleVideo.data]);

    useEffect(() => {
        if (error) {
            if (error.response && error.response.status) {
                handleAPIErrors(error.response.status, history, location.pathname);
            } else {
                toast.error({ 
                    type: 'video', 
                    action: 'fetch',
                    key: 'one',
                });
            }
        }
        if (singleVideo.data) {
            if (isJourney(singleVideo.data)) {
                setVideoCategory('journey');
            } else if (isDocumentary(singleVideo.data)) {
                setVideoCategory('documentary');
            }
        }
    }, [error, singleVideo]);

    return (<Page className="relative" wrapperClassName="bg-fake-black">
        <Loader loading={inProgress ? inProgress : false} isFixed={true} />
        {singleVideo?.data ?
            <>
                <section className="mb-5" ref={ref}> 
                    {singleVideo.data.dailymotion_id &&
                        <VideoPlayer
                            video={
                                singleVideo.data.dailymotion_id
                            }
                            autoplay={true}
                        />
                    }
                    <div className="px-8 pt-4 pb-2">
                        <Head size="sm">{singleVideo.data.title}</Head>
                        <Text size="xs" className="pb-4">{singleVideo.data.subhead}</Text>
                        <Text size="sm">{singleVideo.data.description}</Text>
                    </div>
                </section>

                <section className="my-12">
                    <div className="px-8">
                        <Head level="2" size="md">Related videos</Head>
                    </div>
                    <div className="pl-8">
                         <Carousel>
                             {singleVideo.data.related.map((otherEpisode, i) => {
                                if (videoCategory === 'journey') {
                                    return <JourneyPreview journey={otherEpisode} key={i} headingLevel={3} />;
                                } else {
                                    return <DocumentaryPreview documentary={otherEpisode} key={i} headingLevel={3} />;
                                }
                             })}
                         </Carousel>
                    </div>
                </section>
            </> : 
            <>This video is unavailable at the moment.</>
        }
    </Page>);
};

export default withRouter(VideoPage);
