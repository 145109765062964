import { MAIN_URLS, SUBSCRIPTION_URLS } from 'shared/urlList';
import { getIsGuest } from 'shared/localStorage';

export const errors = {
    article: {
        fetch: {
            one: 'That\'s a blunder, we could not load this article. Please try again.',
            all: 'That\'s a blunder, we could not load the articles. Please try again.',
        }
    },
    masterclass: {
        fetch: {
            one: 'Something went wrong fetching the episode.',
            all: 'Something went wrong fetching masterclass chapters.',
        }
    },
    video: {
        fetch: {
            one: 'Something went wrong fetching this video.',
        }
    },
    home: {
        fetch: {
            any: 'Something went wrong fetching this page data.'
        }
    },
    login: {
        create: {
            any: 'Invalid email or password.',
            api: `{API_ERROR}`,
        }
    },
    passwordRecovery: {
        create: {
            any: `{API_ERROR}`,
        }
    },
    resetPassword: {
        create: {
            mismatch: 'The passwords don\'t match. Please try again.',
        }
    },
    register: {
        create: {
            any: `Something went wrong with your registration: {API_ERROR}`,
            google: 'Google authentication failed.',
        }
    },
    subscription: {
        create: {
            any: 'Something went wrong. Please try again later.',
            payment: 'Something went wrong. Please try again later.'
        },
        fetch: {
            upgrade: 'Something went wrong fetching this page data.',
            plans: 'Something went wrong fetching subscription plans.'
        },
        update: {
            session: 'An error occurred while trying to update your user session. You may need to reload the page to see changes.',
        }
    },
    newsletter: {
        create: {
            any: 'Something went wrong with your newsletter subscription.',
        }
    },
    lesson: {
        fetch: {
            all: 'Something went wrong fetching the lesson.',
        }
    },
    program: {
        fetch: {
            all: 'Something went wrong fetching programs.',
            one: 'Something went wrong fetching this program.',
            chapter: 'Something went wrong fetching this chapter.',
        },
        update: {
            progress: 'Something went wrong updating your progress.'
        }
    },
    default: 'Something went wrong. Please try again later.',
};

export const errorMessages = ({ type, action, key = 'any', APIError = null }) => {
    if (!type || !action) {
        return errors.default;
    }

    if (errors[type] && errors[type][action] && errors[type][action][key]) {
        return errors[type][action][key].replace('{API_ERROR}', APIError);
    } else {
        return errors.default;
    }
};

export const handleAPIErrors = (code, history, redirectTo = MAIN_URLS.HOME) => {
    switch(code) {
        case 404:
            history.push(MAIN_URLS.NOT_FOUND);
            break;
        case 403:
            if (getIsGuest()) {
                history.push(MAIN_URLS.LOGIN + `?redirect=${redirectTo}`);
            } else {
                history.push(SUBSCRIPTION_URLS.UPGRADE_ACCOUNT);
            }
            break;
        default:
    }
};
