import { takeLatest, put as dispatch, select} from 'redux-saga/effects';
import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import { getProgramsFilters, getProgramsSortBy, getAllProgramsSelector } from './selector';

const itemsPerPage = 8;

export function* programSaga() {
    yield takeLatest(constants.GET_PROGRAMS, getPrograms);
    yield takeLatest(constants.GET_PROGRAM, getSingleProgram);
    yield takeLatest(constants.GET_CHAPTER, getChapter);
    yield takeLatest(constants.SET_PROGRAMS_FILTERS, setProgramsFilters);
    yield takeLatest(constants.SET_PROGRAMS_SORT_BY, setProgramsSortBy);
    yield takeLatest(constants.GET_MORE_PROGRAMS, getMorePrograms);
}

function* getPrograms(params) {
    try {
        const filters = yield select(getProgramsFilters);
        const sortBy = yield select(getProgramsSortBy);
        const resp = yield api.getAllPrograms({ filters, sortBy, limit: itemsPerPage, start: 0 });
        yield dispatch(actions.getAllProgramsSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getAllProgramsFailure failed', error);
        yield dispatch(actions.getAllProgramsFailure(error));
    }
}

function* getMorePrograms(params) {
    try {
        const filters = yield select(getProgramsFilters);
        const sortBy = yield select(getProgramsSortBy);
        const programs = yield select(getAllProgramsSelector);
        const resp = yield api.getAllPrograms({ filters, sortBy, limit: itemsPerPage, start: programs.length });
        yield dispatch(actions.getMoreProgramsSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getAllProgramsFailure failed', error);
        yield dispatch(actions.getMoreProgramsFailure(error));
    }
}

function* getSingleProgram(params) {
    try {
        const resp = yield api.getSingleProgram(params);
        yield dispatch(actions.getSingleProgramSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getSingleProgram failed', error);
        yield dispatch(actions.getSingleProgramFailure(error));
    }
}

function* getChapter(params) {
    try {
        const resp = yield api.getChapter(params);
        yield dispatch(actions.getChapterSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getChapter failed', error);
        yield dispatch(actions.getChapterFailure(error));
    }
}

function* setProgramsFilters() {
    yield dispatch(actions.getAllPrograms());
}

function* setProgramsSortBy() {
    yield dispatch(actions.getAllPrograms());
}
