import React from 'react';

import { Link } from 'react-router-dom';
import { formatDuration } from 'shared/time';
import PropTypes from 'prop-types';

import { ReactComponent as PlayAlt } from 'assets/icons/player/play@2x.svg';
import { Text, Head } from 'UI';

const JourneyPreview = ({ 
    journey,
    headingLevel
}) => {
    return (
        <Link to={`/videos/${journey.id}`} className="block mr-5">
            <div>
                <div className="relative flex justify-center items-center w-64 h-80" style={{
                    backgroundImage: `url(${journey.hero_image.formats.medium ? journey.hero_image.formats.medium.url : journey.hero_image.formats.thumbnail.url})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}>
                    <PlayAlt className="absolute" />
                </div>
                <div className="mt-3">
                    <div className="flex gap-2">
                        <Text size="sm">{journey.subhead}</Text>
                        <Text size="sm" opacity="light">{ formatDuration(journey.duration) }</Text>
                    </div>
                     <Head level={headingLevel} weight="normal" paddingY={0}>{journey.title}</Head>
                </div>
            </div>
        </Link>
    );
};

JourneyPreview.propTypes = {
    journey: PropTypes.object.isRequired,
    headingLevel: PropTypes.number.isRequired,
};

JourneyPreview.defaultProps = {
    headingLevel: 2
};

export default JourneyPreview;
