import { updateObject } from 'shared/utility';
import * as constants from './constants';

const initialState = {
    footerData: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    newsletter: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
};

const getFooterDataRequest = (state, inProgress, isSuccessful, data, error) => {
    return updateObject(state, {
        footerData: {
            inProgress,
            isSuccessful,
            data,
            error,
        }
    });
};

const subscribeNewsletterRequest = (state, inProgress, isSuccessful, data, error) => {
    return updateObject(state, {
        newsletter: {
            inProgress,
            isSuccessful,
            data,
            error,
        }
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_FOOTER_REQUEST:
            return getFooterDataRequest(state, true, null, null, '');
        case constants.GET_FOOTER_SUCCESS:
            return getFooterDataRequest(state, false, true, action.data, null);
        case constants.GET_FOOTER_FAILURE:
            return getFooterDataRequest(
                state,
                false,
                false,
                null,
                action.error,
            );
        case constants.SUBSCRIBE_NL_REQUEST:
            return subscribeNewsletterRequest(state, true, null, null, '');
        case constants.SUBSCRIBE_NL_SUCCESS:
            return subscribeNewsletterRequest(state, false, true, action.data, null);
        case constants.SUBSCRIBE_NL_FAILURE:
            return subscribeNewsletterRequest(
                state,
                false,
                false,
                null,
                action.error,
            );
        default:
            return state;
    }
};

export default reducer;
