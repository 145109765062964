import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import merge from 'lodash/merge';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.scss';

const Carousel = ({ minHeight, centerMode, centerPadding, variableWidth, ...props }) => {
    const children = props.children;
    const slider = useRef(null);

    const slideSettings = {
        infinite: props.infinite,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchThreshold: 10,
        arrows: false,
        centerMode,
        centerPadding: centerPadding ? centerPadding : undefined,
        variableWidth,
        mobileFirst: true
    };

    // merge default settings with component settings
    if (typeof props.settings === 'undefined') {
        props.settings = {};
    }
    merge(slideSettings, props.settings);

    const calculateStyles = () => {
        const width = "100%";

        return {
            width,
            minHeight: `${minHeight}px`
        };
    };


    return (
        <section
            style={calculateStyles()}
            className={[
                (props.horizontalOverflow ? 'carousel-overflow':''),
                (props.alignToLeft ? 'carousel-left':''),
                'videos',
            ].join(' ')}
        >
            <Slider {...slideSettings} ref={slider}>
                {children}
            </Slider>
        </section>
    );
};

Carousel.propTypes = {
    disabled: PropTypes.bool,
    clicked: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.array,
    ]).isRequired,
    active: PropTypes.bool,
    infinite: PropTypes.bool,
    minHeight: PropTypes.number,
    centerMode: PropTypes.bool,
    centerPadding: PropTypes.string,
    variableWidth: PropTypes.bool
};

Carousel.defaultProps = {
    disabled: false,
    infinite: false,
    minHeight: 200,
    centerMode: false,
    variableWidth: true
};

export default Carousel;
