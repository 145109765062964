import React, { useState, useEffect } from 'react';
import { getSubscriptionPlans } from 'store/subscriptions/selector';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getUser } from 'store/user/selector';
import { getIsGuest } from 'shared/localStorage';
import { MAIN_URLS } from 'shared/urlList';
import { toast } from 'react-toastify';
import API from 'API';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';

import { 
    Text,
    ButtonLink,
    Toggle,
    Loader,
    Button,
} from 'UI';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const UpgradeBlock = ({
  upgradeButtonText = 'Upgrade to premium'
}) => {
  const [planDetails, setPlanDetails] = useState({});
  const { data: subscriptionPlans, inProgress, error } = useSelector(getSubscriptionPlans);
  const userInfo = useSelector(getUser, shallowEqual);
  const [userIsFree, setUserIsFree] = useState(true);
  const isGuest = getIsGuest();
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(false);
  const location = useLocation();

  const changePeriod = (e) => {
    if (e.target.checked) {
      setPeriod('yearly');
    } else {
      setPeriod('monthly');
    }
  };

  const setPeriod = (period) => {
    if (subscriptionPlans && subscriptionPlans.length > 0) {
      subscriptionPlans.forEach(plan => {
        if (subscriptionPlans.length > 2 && plan.currency === 'USD' && plan.period === period) {
          setPlanDetails(plan);
        } else if (subscriptionPlans.length === 2 && plan.period === period) {
          setPlanDetails(plan);
        }
      });
    }
  };

  const isFree = () => {
      if (userInfo.user_tier === 'free' && userInfo.user_tier === 'free') {
        return true;
      } else if (userInfo.subscription && userInfo.subscription.status && (userInfo.subscription.status === 'expired' || userInfo.subscription.status === 'canceled')) {
        return true;
      }
      return false;
  };

  const createSubscription = async (planId) => {
      try {
          const response = await API.post(
              '/subscriptions/web/checkout/new_subscription',
              {
                  plan_id: planId
              }
          );
          return response.data.data;
      } catch (error) {
          toast.error();
          //eslint-disable-next-line
          console.warn(error);
          setLoading(false);
      }
  };

  const handleSubscription = async (planId) => {
      if (window.dataLayer && window.dataLayer.push) {
          window.dataLayer.push({'event': 'kc-payment-init'});
      }
      setLoading(true);
      const data = await createSubscription(planId);
      const stripe = await stripePromise;
      try {
          await stripe.redirectToCheckout({
              sessionId: data && data.id,
          });
      } catch (error) {
          //eslint-disable-next-line
          console.warn(error);
          toast.error();
      }
      setLoading(false);
  };

  useEffect(() => {
      if (error) {
          toast.error({ 
              type: 'subscription', 
              action: 'fetch',
              key: 'plans',
          });
      }
  }, [error]);

  useEffect(() => {
    if (userInfo) {
      setUserIsFree(isFree());
    }
  }, [userInfo]);

  useEffect(() => {
    if (subscriptionPlans) {
      setPeriod('monthly');
    }
  }, [subscriptionPlans]);

  return (
    <>
      {(isGuest || userIsFree) &&
        <>
          {isGuest ?
            <>
              <div>
                <ButtonLink 
                    to={MAIN_URLS.ONBOARDING+`?redirect=${location.pathname}`}
                    size="sm" 
                    weight="medium"
                    buttonClassName="py-3 text-md"
                    style={{width: '327px', fontSize: '16px'}}>Sign up & Start Learning</ButtonLink>
              </div>
            </> : 
            userIsFree &&
              <>
                {planDetails && 
                  <div className="relative">
                    <Loader loading={loading || (inProgress ? inProgress : false)} className="bg-white bg-opacity-20" />
                    <Button size="sm" 
                        className="py-3 text-md"
                        style={{width: '327px'}}
                        clicked={() => handleSubscription(planDetails.id)}>
                            <div className="font-bold text-lg">{upgradeButtonText}</div>
                            <div className="font-medium text-sm">{planDetails.price_text}</div>
                    </Button>
                    <div className="flex flex-row mt-10 justify-center">
                        <span className={`mr-4${planDetails.period === 'monthly' ? ' text-turquoise font-bold':''}`}>Monthly</span>
                        <Toggle id="period" onChange={changePeriod} />
                        <span className={`ml-4${planDetails.period === 'yearly' ? ' text-turquoise font-bold':''}`}>Yearly</span>
                    </div>
                  </div>
                }
              </>
          }
        </>
      }
    </>
  );
};

export default UpgradeBlock;
