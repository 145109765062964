export const facebookSDK = (lang) => {
    window.fbAsyncInit = function() {
        window.FB.init({
            appId: process.env.REACT_APP_FB_APP_ID,
            cookie: true,
            xfbml: true,
            autoLogAppEvents: true,
            version: 'v9.0',
        });

        window.FB.AppEvents.logPageView();
    };

    (function(d, s, id) {
        let js = d.getElementsByTagName(s)[0];
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = `https://connect.facebook.net/${lang}/sdk.js`;
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
};

export function dailymotionSDK() {
	window.DM.init({
		apiKey: process.env.REACT_APP_DAILYMOTION_KEY,
		status: true, // check login status
		cookie: true, // enable cookies to allow the server to access the session
	});
}

export function googleSDK() {
    window.googleSDKLoaded = () => {
        window.gapi.load('auth2', () => {
            const auth2 = window.gapi.auth2.init({
                client_id: process.env.REACT_APP_GOOGLE_APP_ID,
                cookiepolicy: 'single_host_origin',
                scope: 'profile email',
            });
            window.googleAuth = auth2;
        });
    };

    (function(d, s, id) {
        let js = d.getElementsByTagName(s)[0];
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src =
            'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
        fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
}

export function appleSDK() {
    if (window.AppleID) {
        window.AppleID.auth.init({
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
            scope: 'name email',
            redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URL,
            // redirectURI: 'https://keysquare.pagekite.me/onboarding',
            state: 'Auth request',
            usePopup: true,
        });
    }
}
