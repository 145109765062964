import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'UI';
import PropTypes from 'prop-types';
import { PROGRAMS_URLS } from 'shared/urlList';

const ChapterPreview = ({ index, chapter, headingLevel }) => {
    const [cardBackground, setCardBackground] = useState('bg-purple-dark');

    useEffect(() => {
        if (chapter.progress === 100) {
            setCardBackground('bg-purple-lighter');
        } else if (chapter.progress === 0) {
            setCardBackground('bg-grey-dark');
        }
    }, []);

    return <Link className={`rounded-lg text-sm flex flex-col p-6 h-full mr-2 ${cardBackground}`}
        to={PROGRAMS_URLS.CHAPTER.replace(':id', chapter.id)}>
        { chapter &&
            <>
                <Text size="xl" weight="medium">{index + 1}.</Text>
                <Text size="xl" weight="medium">{chapter.title}</Text>
                <Text tagName="p" className="py-2">{chapter.description}</Text>
                <div className="pb-4 pt-2">
                    <hr className="border-top border-white border-opacity-20"></hr>
                    <hr className="border-bottom border-purple-darker"></hr>
                </div>
                <Text size="lg" className="border-top"><span className="font-medium">{chapter.completed_lessons}</span>/{chapter.lesson_count} lessons</Text>
            </>
        }
    </Link>;
};

ChapterPreview.propTypes = {
    chapter: PropTypes.object.isRequired,
    headingLevel: PropTypes.number.isRequired
};

ChapterPreview.defaultProps = {
    headingLevel: 2
};

export default ChapterPreview;
