import { Head, Text, Lock } from 'UI';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PROGRAMS_URLS } from 'shared/urlList';

const ProgramCarouselPreview = ({ program, headingLevel }) => {
    return <div className="overflow-hidden text-sm pt-6 pb-12">
        { program &&
            <>
                <Link
                    to={PROGRAMS_URLS.ONE.replace(':id', program.id)} 
                    onClick={(e) => program.locked && e.preventDefault()}
                    className="block bg-no-repeat bg-cover mr-8 rounded-md overflow-hidden relative">
                    {program.locked &&
                        <Lock />
                    }
                    <div className="h-72 w-56 flex flex-col justify-end px-6 py-4"
                        style={{ backgroundImage: `url(${program.carousel_image?.url})` }}>
                        <Head level={headingLevel} weight="medium" size="md" 
                            className="max-w-full w-1/2">{program.title}</Head>
                    </div>
                    <div className="bg-white px-6 py-4">
                        <Text tagName="p" weight="medium" textColor="black" opacity="lighter">
                            {program.level.value}{program.chapter_count ? (' - '+program.chapter_count+' chapters') : ''}
                        </Text>
                    </div>
                </Link>
            </>
        }
    </div>;
};

ProgramCarouselPreview.propTypes = {
    program: PropTypes.object.isRequired,
    headingLevel: PropTypes.number.isRequired
};

ProgramCarouselPreview.defaultProps = {
    headingLevel: 2
};

export default ProgramCarouselPreview;
