import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { checkValidity } from 'shared/utility';

import { Text } from 'UI';

import { ReactComponent as CheckboxOn } from 'assets/icons/form/checkbox_checked.svg';
import { ReactComponent as CheckboxOff } from 'assets/icons/form/checkbox_unchecked.svg';

const Checkbox = ({ 
    className,
    name,
    children,
    register,
    setvalue,
    rules,
    errors,
    ...props 
}) => {
    const [checked, setChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        setvalue(name, checked);
    }, [checked]);

    return (
        <>
        {register &&
            <div className="flex w-full items-start gap-2" onClick={() => setChecked(!checked)}>
                <div className={`${className?(" "+className):""}pt-2`}>
                    <input 
                        className="hidden"
                        type="checkbox"
                        name={name}
                        value={checked}
                        defaultChecked={checked}
                        {...register(name, {
                            validate: value => {
                                const validation = checkValidity(value, rules);
                                setErrorMessage(validation.message);
                                return validation.isValid;
                            }
                        })} />
                    <CheckboxOn className={checked ? 'visible':'hidden'} />
                    <CheckboxOff className={!checked ? 'visible':'hidden'}/>
                </div>
                <label htmlFor={name} className="text-md">
                    {children}
                    {errors && <Text textColor="red-600">{errorMessage}</Text>}
                </label>
            </div>
        }
        </>
    );
};

Checkbox.propTypes = {
    children: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.any,
    register: PropTypes.func,
    setvalue: PropTypes.func,
    rules: PropTypes.object,
    errors: PropTypes.object
};

export default Checkbox;
