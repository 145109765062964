import * as constants from './constants';

const initialState = {
    home: {
        homeState: { inProgress: null, success: null, error: null },
        data: null,
    },
    articles: {
        inProgress: null,
        success: null,
        error: null,
        allArticles: {
            data: null,
            mostViewed: null,
        },
        singleArticle: {
            data: null,
        },
    },
    lessons: {
        inProgress: null,
        success: null,
        error: null,
        totalCounter: null,
        data: null,
        allLessons: {
            data: null
        },
        categories: null,
        filters: {
            themes: null,
            levels: null,
            teachers: null
        },
        sortBy: { id: 1, name: 'Alphabetically', key: 'title' },
        noMoreData: false,
    },
    videos: {
        inProgress: null,
        success: null,
        error: null,
        singleVideo: {
            data: null,
        },
    },
    teachers: null,
    difficultyLevels: null,
};

const fetchAllArticles = (state, action) => {
    const data = state.articles.allArticles.data
        ? [...state.articles.allArticles.data, ...action.data]
        : action.data;
    return {
        ...state,
        articles: {
            ...state.articles,
            inProgress: null,
            success: true,
            allArticles: {
                ...state.articles.allArticles,
                data
            },
        },
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_HOME_REQUEST:
            return {
                ...state,
                home: {
                    ...state.home,
                    homeState: {
                        inProgress: true,
                    },
                },
            };
        case constants.GET_HOME_SUCCESS:
            return {
                ...state,
                home: {
                    ...state.home,
                    homeState: {
                        inProgress: false,
                        success: true,
                    },
                    data: action.data,
                },
            };
        case constants.GET_HOME_FAILURE:
            return {
                ...state,
                home: {
                    ...state.home,
                    homeState: {
                        inProgress: false,
                        error: action.error,
                    },
                },
            };
        case constants.GET_ALL_ARTICLES_REQUEST:
        case constants.GET_SINGLE_ARTICLE_REQUEST:
        case constants.GET_MOST_VIEWED_ARTICLES_REQUEST:
            return {
                ...state,
                articles: {
                    ...state.articles,
                    inProgress: true,
                    singleArticle: { data: null },
                },
			};
		case constants.CLEAR_ALL_ARTICLES:
            return {
                ...state,
                articles: {
					inProgress: null,
					success: null,
					error: null,
					allArticles: {
						data: null,
						mostViewed: null,
					},
					singleArticle: {
						data: null,
					},
				},
			};
        case constants.GET_ALL_ARTICLES_SUCCESS:
            return fetchAllArticles(state, action);
        case constants.GET_SINGLE_ARTICLE_SUCCESS:
            return {
                ...state,
                articles: {
                    ...state.articles,
                    inProgress: null,
                    success: true,
                    singleArticle: {
                        data: action.data,
                    },
                },
            };
        case constants.GET_MOST_VIEWED_ARTICLES_SUCCESS:
            return {
                ...state,
                articles: {
                    ...state.articles,
                    inProgress: null,
                    success: true,
                    allArticles: {
                        ...state.articles.allArticles,
                        mostViewed: action.data,
                    },
                },
            };
        case constants.GET_ALL_ARTICLES_FAILURE:
        case constants.GET_SINGLE_ARTICLE_FAILURE:
        case constants.GET_MOST_VIEWED_ARTICLES_FAILURE:
            return {
                ...state,
                articles: {
                    ...state.articles,
                    inProgress: null,
                    error: action.error,
                },
            };
        case constants.GET_TOTAL_LESSONS_COUNT:
            return {
                ...state,
                lessons: {
                    ...state.lessons,
                    inProgress: true,
                },
            };
        case constants.GET_TOTAL_LESSONS_COUNT_SUCCESS:
            return {
                ...state,
                lessons: {
                    ...state.lessons,
                    totalCounter: action.data,
                    inProgress: false,
                },
            };
        case constants.GET_LESSONS:
            return {
                ...state,
                lessons: {
                    ...state.lessons,
                    inProgress: true,
                },
            };
        case constants.GET_LESSONS_SUCCESS:
            return getLessonsSuccess(state, action);
        case constants.GET_MORE_LESSONS:
            return {
                ...state,
                lessons: {
                    ...state.lessons,
                    inProgress: true,
                },
            };
        case constants.GET_MORE_LESSONS_SUCCESS:
            return getMoreLessonsSuccess(state, action);
        case constants.GET_TEACHERS_SUCCESS:
            return getTeachersSuccess(state, action);
        case constants.GET_LESSONS_CATEGORIES_SUCCESS:
            return getLessonsCategoriesSuccess(state, action);
        case constants.GET_DIFFICULTY_LEVELS_SUCCESS:
            return getDifficultyLevelsSuccess(state, action);
        case constants.SET_LESSONS_FILTERS:
            return setLessonsFilters(state, action);
        case constants.SET_LESSONS_SORT_BY:
            return setLessonsSortBy(state, action);
        case constants.GET_SINGLE_VIDEO_REQUEST:
            return {
                ...state,
                videos: {
                    ...state.videos,
                    inProgress: true,
                    success: null,
                    error: null,
                    singleVideo: { data: null },
                },
            };
        case constants.GET_SINGLE_VIDEO_SUCCESS:
            return {
                ...state,
                videos: {
                    ...state.videos,
                    inProgress: null,
                    success: true,
                    singleVideo: {
                        data: action.data,
                    },
                },
            };
        case constants.GET_SINGLE_VIDEO_FAILURE:
            return {
                ...state,
                videos: {
                    ...state.videos,
                    inProgress: null,
                    error: action.error,
                },
            };
        default:
            return state;
    }
};

const getLessonsSuccess = (state, { data }) => {
    return { 
        ...state,
        lessons: { 
            ...state.lessons,
            data,
            inProgress: false,
            noMoreData: data.length === 24 ? false : true,
        }
    };
};

const getMoreLessonsSuccess = (state, { data }) => {
    return { 
        ...state,
        lessons: { 
            ...state.lessons,
            inProgress: false,
            data: [...state.lessons.data, ...data],
            noMoreData: data.length === 24 ? false : true
        }
    };
};

const getTeachersSuccess = (state, { data }) => {
    return { 
        ...state,
        teachers: data
    };
};

const getLessonsCategoriesSuccess = (state, { data }) => {
    return { 
        ...state,
        lessons: { 
            ...state.lessons,
            categories: data
        }
    };
};

const getDifficultyLevelsSuccess = (state, { data }) => {
    return { 
        ...state,
        difficultyLevels: data
    };
};

const setLessonsFilters = (state, { data }) => {
    return { 
        ...state,
        lessons: {
            ...state.lessons,
            filters: data,
        }
    };
};

const setLessonsSortBy = (state, { data }) => {
    return { 
        ...state,
        lessons: {
            ...state.lessons,
            sortBy: data,
        }
    };
};

export default reducer;
