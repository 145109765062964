export const GET_PROGRAMS = 'GET_PROGRAMS';
export const GET_PROGRAMS_SUCCESS = 'GET_PROGRAMS:SUCCESS';
export const GET_PROGRAMS_FAILURE = 'GET_PROGRAMS:FAILURE';

export const GET_MORE_PROGRAMS = 'GET_MORE_PROGRAMS';
export const GET_MORE_PROGRAMS_SUCCESS = 'GET_MORE_PROGRAMS_SUCCESS';
export const GET_MORE_PROGRAMS_FAILURE = 'GET_MORE_PROGRAMS_FAILURE';

export const GET_PROGRAM = 'GET_PROGRAM';
export const GET_PROGRAM_SUCCESS = 'GET_PROGRAM:SUCCESS';
export const GET_PROGRAM_FAILURE = 'GET_PROGRAM:FAILURE';

export const GET_CHAPTER = 'GET_CHAPTER';
export const GET_CHAPTER_SUCCESS = 'GET_CHAPTER:SUCCESS';
export const GET_CHAPTER_FAILURE = 'GET_CHAPTER:FAILURE';

export const CLEAR_PROGRAMS = 'CLEAR_PROGRAMS';

export const SET_PROGRAMS_FILTERS = 'SET_PROGRAMS_FILTERS';
export const SET_PROGRAMS_SORT_BY = 'SET_PROGRAMS_SORT_BY';
