import React, { useState, useEffect, useRef } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { toast } from 'shared/alerts';
import jwt_decode from 'jwt-decode';

import { ReactComponent as Logo } from 'assets/icons/common/logo_2@2x.svg';
import { ReactComponent as Google } from 'assets/icons/social/google_colored.svg';
import { ReactComponent as Facebook } from 'assets/icons/social/facebook_colored.svg';

import Apple from 'assets/icons/social/apple_white.png';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import * as userSelectors from 'store/user/selector';
import { MAIN_URLS } from 'shared/urlList';

import { 
    Button,
    Text,
    Input,
    Form,
    Loader
} from 'UI';
import { facebookLoginRequest, loginRequest, loginReset, 
    googleLoginRequest, appleLoginRequest } from 'store/user/actions';
import { toggleModal } from 'store/util/actions';

const Login = ({ onLogin }) => {
    const [step, setStep] = useState('login');
    const [appleAuth, setAppleAuth] = useState(null);
    const history = useHistory();
    const [fbToken, setFbToken] = useState(null);
    const [googleUser, setGoogleUser] = useState(null);
    const userAccessToken = useSelector(userSelectors.getUserAccessToken);
    const googleBtn = useRef();
    const dispatch = useDispatch();

    const { inProgress, error, errorType, isSuccessful, data: loginData } = useSelector(
        userSelectors.getLoginRequest,
        shallowEqual
    );

    const onSubmit = (data) => {
        const payload = {
            username: data.email.trim(),
            password: data.password.trim(),
        };

        dispatch(loginRequest(payload));
    };

    useEffect(() => {
        let _error = null;
        if (error && error.data && error.data.error) {
            _error = error.data.error;
        } else if (error && error.response && error.response.data) {
            _error = error.response.data.error ? error.response.data.error : null;
        }

        if (_error && _error === 'user_doesnt_exist') {
            if (googleUser && errorType === 'google') {
                const data = {
                    google_token: googleUser.getAuthResponse().id_token,
                    email: googleUser.getBasicProfile().getEmail(),
                    // firstname: googleUser.getBasicProfile().getGivenName(),
                    // lastname: googleUser.getBasicProfile().getFamilyName(),
                };
                localStorage._k_sqr_go_dta = JSON.stringify(data);
                localStorage._k_sqr_go_at = data.google_token;
                history.push(MAIN_URLS.REGISTER.replace(':type', 'google'));
            } else if (fbToken && errorType === 'facebook') {
                window.FB.api(
                    '/me',
                    {
                        locale: 'en_US',
                        fields: 'first_name, last_name, email',
                    },
                    (response) => {
                        delete response.id;

                        const data = {
                            facebook_token: fbToken,
                            // firstname: response.first_name,
                            // lastname: response.last_name,
                            email: response.email,
                        };

                        localStorage._k_sqr_fb_dta = JSON.stringify(data);
                        localStorage._k_sqr_fb_at = fbToken;
                        history.push(MAIN_URLS.REGISTER.replace(':type', 'facebook'));
                    }
                );
            } else if (appleAuth && errorType === 'apple') {
                const decoded = jwt_decode(appleAuth.id_token);
                const data = {
                    apple_token: appleAuth.code,
                    email: decoded.email,
                };

                localStorage._k_sqr_a_dta = JSON.stringify(data);
                localStorage._k_sqr_a_at = appleAuth.code;
                history.push(MAIN_URLS.REGISTER.replace(':type', 'apple'));
            }
        } else if (_error && _error === 'user_wrong_password') {
            toast.error({ 
                type: 'login', 
                action: 'create',
            });
        } else if (_error) {
            toast.error({ 
                type: 'login', 
                action: 'create',
                key: 'api',
                APIError: _error,
            });
        }
    }, [error, errorType]);

    useEffect(() => {
        if (isSuccessful) {
            dispatch(loginReset());
            onLogin();
        }
    }, [isSuccessful]);

    useEffect(() => {
        if (window.googleAuth) {
            window.googleAuth.attachClickHandler(
                googleBtn.current,
                {},
                (googleUser) => {
                    setGoogleUser(googleUser);
                    googleLogin(googleUser);
                },
                (error) => {
                    // eslint-disable-next-line
                    console.warn(error);
                }
            );
        }
        // eslint-disable-next-line
    }, [window.googleAuth]);

    const facebook = () => {
        window.FB.login(
            ({ authResponse }) => {
                if (authResponse) {
                    const { accessToken } = authResponse;
                    setFbToken(accessToken);
                    facebookLogin(authResponse.accessToken);
                } else {
                    // eslint-disable-next-line
                    console.log(
                        'User cancelled login or did not fully authorize.'
                    );
                }
            },
            { scope: 'public_profile, email' }
        );
    };

    const facebookLogin = (token) => {
        dispatch(
            facebookLoginRequest({
                access_token: token,
            })
        );
    };

    const googleLogin = (googleUser) => {
        dispatch(
            googleLoginRequest({
                id_token: googleUser.getAuthResponse().id_token,
            })
        );
    };

    const appleLogin = (token) => {
        dispatch(
            appleLoginRequest({
                code: token,
                platform: 'mobile-web'
            })
        );
    };

    const appleSignup = async () => {
        try {
            const response = await window.AppleID.auth.signIn();

            setAppleAuth(response.authorization);
            appleLogin(response.authorization.code);
        } catch (error) {
            // handle error.

            // eslint-disable-next-line no-console
            console.warn(error);
        }
    };

    const register = () => {
        dispatch(toggleModal('register'));
    };

    useEffect(() => {
        if (window.googleAuth) {
            window.googleAuth.attachClickHandler(
                googleBtn.current,
                {},
                (googleUser) => {
                    setGoogleUser(googleUser);
                    googleLogin(googleUser);
                },
                (error) => {
                    // eslint-disable-next-line
                    console.warn(error);
                }
            );
        }
        // eslint-disable-next-line
    }, [window.googleAuth]);

    useEffect(() => {
        if (window.AppleID) {
            window.AppleID.auth.renderButton();
        }
    }, [window.AppleID]);

    return (
        <>
            {step === 'login' &&
                <div className="pt-20 flex flex-col flex-grow items-center min-h-screen justify-between bg-gradient-to-r from-purple-dark to-purple-light relative" 
                    role="tab" aria-selected={step === 'login' ? true : false}>
                    <Loader loading={inProgress ? inProgress : false} bgColor="white" className="bg-opacity-40" />
                    <section className="flex flex-col items-center justify-center h-full w-full px-8">
                        <Logo />
                        <Form onSubmit={onSubmit} className="w-full pt-4" noValidate>
                            <Input label="E-mail" type="email" name="email" placeholder="Your email"
                                rules={{required: true}} />
                            <Input label="Password" type="password" name="password" 
                                placeholder="Your password" rules={{required: true}} 
                                visibilityButton={true} autoComplete="on" />

                            <Button bgColor="none" className="underline w-full" size="md"
                                name="Recover your password" 
                                clicked={() => setStep('forgotPassword')}>Forgot your password?</Button>
                            <Button bgColor="white" textColor="purple-dark" size="lg" type="submit"
                                rounded="md" weight="bold" name="Submit the form and log in">Log in</Button>
                        </Form>
                    </section>

                    <div className="py-4 relative">
                        <div className="text-center">OR</div>
                    </div>

                    <section className="px-8 pb-6 flex flex-col w-full h-full gap-3">
                        <Button rounded="md" bgColor="fake-black" size="xl" name="Login with Facebook" 
                            className="px-4 flex items-center text-center" textColor="white" onClick={facebook}>
                            <Facebook className="flex-shrink" />
                            <div className="text-center flex-grow font-bold">Continue with Facebook</div>
                        </Button>
                        <Button rounded="md" bgColor="fake-black" size="xl" name="Login with Google" 
                            className="px-4 flex items-center text-center" textColor="white" ref={googleBtn}
                            disabled={window.googleAuth ? false:true}>
                            <Google className="flex-shrink" />
                            <div className="text-center flex-grow font-bold">Continue with Google</div>
                        </Button>
                        <Button rounded="md" bgColor="fake-black" size="xl" name="Login with Apple" 
                            className="px-4 flex items-center text-center" textColor="white" clicked={appleSignup}>
                            <img src={Apple} className="flex-shrink" aria-hidden="true" alt="" />
                            <div className="text-center flex-grow font-bold">Continue with Apple</div>
                        </Button>
                    </section>

                    <section className="flex flex-col items-center pb-4">
                        <Text>Don't have an account?</Text>
                        <Button className="underline" bgColor="none" size="sm" name="Create an account" 
                            clicked={() => register()}>Sign up</Button>
                    </section>
                </div>
            }

            {step === 'forgotPassword' &&
                <ForgotPassword onCancel={() => setStep('login')} role="tab" aria-selected={step === 'forgotPassword' ? true : false} />
            }
        </>
    );
};

export default withRouter(injectIntl(Login));
