import { SET_TABLE_HEIGHT, TOGGLE_MODAL, UPDATE_META, INIT_COOKIES_CONSENT, 
    UPDATE_COOKIES_CONSENT, ACTIVATE_WEBVIEW } from './constants';
import staticMeta from 'shared/meta';
import { setCookiesConsent } from 'shared/localStorage';
import { setAccessToken } from 'store/user/actions';

const initialState = {
    modalKey: null,
    meta: staticMeta,
    cookiesConsent: {
        media: false,
        statistics: false
    },
    webview: {
        active: false,
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TABLE_HEIGHT:
            return {...state, tableHeight: action.height};
        case TOGGLE_MODAL:
            return {...state, modalKey: action.modalKey};
        case UPDATE_META:
            return updateMeta(state, action.newMeta);
        case INIT_COOKIES_CONSENT:
            return initCookiesConsent(state, action.localConsent);
        case UPDATE_COOKIES_CONSENT:
            return updateCookiesConsent(state, action.data.category, action.data.choice);
        case ACTIVATE_WEBVIEW:
            return activateWebview(state, action.token);
        default:
            return state;
    }
};

const updateMeta = (state, newMeta) => {
    const meta = state.meta;
    meta[newMeta.route] = {
        title: newMeta.title,
        metaDesc: newMeta.metaDesc
    };
    return {
        ...state,
        meta: {
            ...meta
        }
    };
};

const initCookiesConsent = (state, localConsent) => {
    if (JSON.parse(localConsent)) {
        state.cookiesConsent = JSON.parse(localConsent);
    }
    return state;
};

const updateCookiesConsent = (state, category, choice) => {
    const cookiesConsent = {};
    Object.assign(state.cookiesConsent, cookiesConsent);
    if (cookiesConsent) {
        cookiesConsent[category] = choice;
    }
    setCookiesConsent(JSON.stringify(cookiesConsent));
    return {
        ...state,
        cookiesConsent
    };
};

const activateWebview = (state, token) => {
    return {
        ...state,
        webview: {
            active: true,
        }
    };
};

export default reducer;
