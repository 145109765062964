import React, { useEffect } from 'react';

import { Head } from 'UI';
import API from 'API';

import { ReactComponent as Logo } from 'assets/icons/common/logo_2@2x.svg';

export default function ProductPaymentCancel() {
    return (
        <div className="flex items-center justify-center flex-col h-screen">
            <Logo />
            <Head size="lg">Something went wrong! <br />
                Please try again!</Head>
        </div>
    );
}
