import React, { useState, useEffect, useRef } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { facebookLoginRequest, googleLoginRequest, appleLoginRequest } from 'store/user/actions';
import { getLoginRequest } from 'store/user/selector';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';
import qs from 'query-string';

import { ReactComponent as Logo } from 'assets/icons/common/logo_2@2x.svg';
import { ReactComponent as Google } from 'assets/icons/social/google_colored.svg';
import { ReactComponent as Facebook } from 'assets/icons/social/facebook_colored.svg';
import { ReactComponent as Email } from 'assets/icons/social/email.svg';

import Apple from 'assets/icons/social/apple_white.png';

import {
    Button,
    ButtonLink,
    Head,
    Text
} from 'UI';

import {
    MAIN_URLS
} from 'shared/urlList';

const Register = ({ onClose }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [googleUser, setGoogleUser] = useState(null);
    const [appleAuth, setAppleAuth] = useState(null);
    const [fbToken, setFbToken] = useState(null);
    const { error, errorType } = useSelector(getLoginRequest, shallowEqual);
    const [ redirection, setRedirection ] = useState(MAIN_URLS.HOME);
    const googleBtn = useRef();

    const facebookSignup = () => {
        window.FB.login(
            ({ authResponse }) => {
                if (authResponse) {
                    setFbToken(authResponse.accessToken);
                    dispatch(facebookLoginRequest({
                        access_token: authResponse.accessToken,
                    }));
                } else {
                    // eslint-disable-next-line
                    console.log(
                        'User cancelled login or did not fully authorize.'
                    );
                }
            },
            { scope: 'public_profile, email' }
        );
    };

    const googleLogin = (googleUser) => {
        dispatch(
            googleLoginRequest({
                id_token: googleUser.getAuthResponse().id_token,
            })
        );
    };

    const appleLogin = (token) => {
        dispatch(
            appleLoginRequest({
                code: token,
                platform: 'mobile-web'
            })
        );
    };

    const appleSignup = async () => {
        try {
            const response = await window.AppleID.auth.signIn();

            setAppleAuth(response.authorization);
            appleLogin(response.authorization.code);
        } catch (error) {
            // handle error.

            // eslint-disable-next-line no-console
            console.warn(error);
        }
    };

    useEffect(() => {
        const { search } = location;
        if (search) {
            const parsed = qs.parse(search);
            if (parsed.redirect) {
                setRedirection(parsed.redirect);
            }
        } else {
           setRedirection(location.pathname);
        }
    }, [location]);

    useEffect(() => {
        if (window.AppleID) {
            window.AppleID.auth.renderButton();
        }
    }, [window.AppleID]);

    useEffect(() => {
        if (window.googleAuth) {
            window.googleAuth.attachClickHandler(
                googleBtn.current,
                {},
                (googleUser) => {
                    setGoogleUser(googleUser);
                    googleLogin(googleUser);
                },
                (error) => {
                    // eslint-disable-next-line
                    console.warn(error);
                    toast.error({ 
                        type: 'register', 
                        action: 'create',
                        key: 'google',
                    });
                }
            );
        }
        // eslint-disable-next-line
    }, [window.googleAuth]);

    useEffect(() => {
        const _error =
            error && error.data && error.data.error ? error.data.error : '';

        if (_error && _error === 'user_doesnt_exist') {
            if (googleUser && errorType === 'google') {
                const data = {
                    google_token: googleUser.getAuthResponse().id_token,
                    email: googleUser.getBasicProfile().getEmail()
                };
                localStorage._k_sqr_go_dta = JSON.stringify(data);
                localStorage._k_sqr_go_at = data.google_token;
                history.push(MAIN_URLS.REGISTER.replace(':type', 'google')+`?redirect=${redirection}`);
            }

            if (fbToken && errorType === 'facebook') {
                window.FB.api(
                    '/me',
                    {
                        locale: 'en_US',
                        fields: 'email',
                    },
                    (response) => {
                        delete response.id;

                        const data = {
                            facebook_token: fbToken,
                            email: response.email,
                        };

                        localStorage._k_sqr_fb_dta = JSON.stringify(data);
                        localStorage._k_sqr_fb_at = fbToken;
                        history.push(MAIN_URLS.REGISTER.replace(':type', 'facebook')+`?redirect=${redirection}`);
                    }
                );
            }

            if (appleAuth && errorType === 'apple') {
                const decoded = jwt_decode(appleAuth.id_token);
                const data = {
                    apple_token: appleAuth.code,
                    email: decoded.email,
                };

                localStorage._k_sqr_a_dta = JSON.stringify(data);
                localStorage._k_sqr_a_at = appleAuth.code;
                history.push(MAIN_URLS.REGISTER.replace(':type', 'apple')+`?redirect=${redirection}`);
            }
        } else if (_error) {
            toast.error({ 
                type: 'register', 
                action: 'create',
                APIError: _error
            });
        }
        // eslint-disable-next-line
    }, [
        fbToken,
        error,
        errorType,
    ]);

    return (
        <div className="pt-20 flex flex-col flex-grow items-center min-h-screen justify-between bg-gradient-to-r from-purple-dark to-purple-light">
            <section className="flex flex-col items-center justify-center h-full w-full px-8 text-center ">
                <Logo />
                <Head size="md" weight="bold" level="2">Sign up to Kasparovchess</Head>
            </section>

            <section className="px-8 pb-6 flex flex-col w-full h-full gap-3">
                <Button rounded="md" bgColor="fake-black" size="xl" clicked={facebookSignup}
                    className="px-4 flex items-center text-center" textColor="white">
                    <Facebook className="flex-shrink" />
                    <div className="text-center flex-grow font-bold">Sign up with Facebook</div>
                </Button>
                <Button rounded="md" bgColor="fake-black" size="xl" disabled={window.googleAuth ? false:true}
                    className="px-4 flex items-center text-center" textColor="white" ref={googleBtn}>
                    <Google className="flex-shrink" />
                    <div className="text-center flex-grow font-bold">Sign up with Google</div>
                </Button>
                <Button rounded="md" bgColor="fake-black" size="xl" clicked={appleSignup}
                    className="px-4 flex items-center text-center" textColor="white">
                    <img src={Apple} className="flex-shrink" aria-hidden="true" alt="" />
                    <div className="text-center flex-grow font-bold">Sign up with Apple</div>
                </Button>
                <ButtonLink rounded="md" bgColor="fake-black" size="xl" textColor="white" to={MAIN_URLS.REGISTER.replace(':type', 'email')+`?redirect=${redirection}`}>
                    <div className="px-4 flex items-center text-center">
                        <Email className="flex-shrink" />
                        <div className="text-center flex-grow font-bold">Sign up with Email</div>
                    </div>
                </ButtonLink>
            </section>

            <section className="flex flex-col items-center pb-4">
                <Text>Already have an account?</Text>
                <Button className="underline" bgColor="none" size="sm" clicked={() => onClose('login')}>Log in</Button>
            </section>
        </div>
    );
};

export default withRouter(Register);
