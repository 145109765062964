import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { subscribeNewsletterRequest } from 'store/footer/actions';
import { getSubscribeNewsletter } from 'store/footer/selector';
import { Loader, Text } from 'UI';
import { ReactComponent as CheckmarkWhite } from 'assets/icons/common/checkmark_white.svg';
import { toast } from 'shared/alerts';
import { checkValidity } from 'shared/utility';

const NewsletterForm = ({ className, subscribed }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState();
    const [errorMessage, setErrorMessage] = useState(null);
    const { inProgress, error, isSuccessful } = useSelector(getSubscribeNewsletter, shallowEqual);

    const subscribe = () => {
        dispatch(subscribeNewsletterRequest({ email }));
    };

    const submit = (e) => {
        e.preventDefault();
        validateForm();
        if (email && !errorMessage) {
            subscribe();
        }
    };

    const validateForm = () => {
        const { isValid, message } = checkValidity(email, {required: true, isEmail: true});
        if (!isValid) {
            setErrorMessage(message);
        } else {
            setErrorMessage(null);
        }
    };

    useEffect(() => {
        if (email) {
            validateForm();
        }
    }, [email]);

    useEffect(() => {
        if (error) {
            toast.error({ 
                type: 'newsletter', 
                action: 'create'
            });
        } 
    }, [error]);

    return (
        <div className="relative w-full">
            <Loader loading={inProgress ? inProgress : false} />
            {(subscribed || isSuccessful) ?
                <>
                    <div className="block bg-success-light w-full p-6 rounded-md flex flex-col items-center justify-center">
                        <CheckmarkWhite />
                    </div>
                    <Text weight="bold" className="text-center mt-3" textColor="success-light">Thank you for subscribing !</Text>
                </> :
                <>
                    <form className={`h-12 w-full flex ${className}`} onSubmit={submit}>
                        <input type="text" placeholder="Your e-mail" onChange={(value) => setEmail(value.target.value)}
                            className={`w-full px-4 border-white ring-primary outline-primary text-white text-opacity-60 text-lg bg-transparent border border-opacity-20`} />
                        <input type="submit" value="Subscribe" 
                            className="bg-primary px-2 font-bold rounded-sm" />
                    </form>
                    {errorMessage && <Text textColor="red-500">{errorMessage}</Text>}
                </>
            }
        </div>
    );
};

export default NewsletterForm;
