export const replaceWildcards = (url, valueArray) => {
    return url.replace(/\/:[a-zA-Z]+\??/g, () => {
        const shiftedValue = valueArray.shift();
        return shiftedValue ? '/' + shiftedValue : '';
    });
};

export const MAIN_URLS = {
    HOME: '/home',
    REGISTER: '/register/:type',
    RESET_PASSWORD: '/reset_password',
    NOT_FOUND: '/404',
    LOGIN: '/login',
    ONBOARDING: '/onboarding'
};

export const MASTERCLASS_URLS = {
    ALL: '/masterclass',
    ONE: '/masterclass/chapter/:id',
};

export const ARTICLES_URLS = {
    ALL: '/articles',
    ONE: '/articles/:id',
};

export const FALLBACK = {
    FOUR_O_FOUR: '/404',
    SCHEDULE_MAINTENANCE: '/schedule-maintenance',
};

export const LESSONS_URLS = {
    ALL: '/lessons',
    ONE: '/lessons/:id',
};

export const VIDEOS_URLS = {
    ONE: '/videos/:id',
    GRAND_CHESS_TOUR: '/grand-chess-tour'
};

export const SUBSCRIPTION_URLS = {
    SUBSCRIPTION_SUCCESS: '/payment/success',
    SUBSCRIPTION_CANCEL: '/payment/cancel',
    UPGRADE_ACCOUNT: '/upgrade-account',
    WELCOME_YEARLY: '/welcome-you',
    WELCOME_MONTHLY: '/welcome-me',
};

export const PROGRAMS_URLS = {
    ALL: '/programs',
    ONE: '/program/:id',
    CHAPTER: '/program-chapter/:id',
};

export const PRODUCT_PAYMENT_URLS = {
    PRODUCT_PAYMENT_SUCCESS: '/product-payment/success',
    PRODUCT_PAYMENT_CANCEL: '/product-payment/cancel',
};
