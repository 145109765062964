import React, { useState, useEffect } from 'react';
import { getSubscriptionPlans } from 'store/subscriptions/selector';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import API from 'API';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { getUser, userIsFree } from 'store/user/selector';

import { Head, Text, Button, Loader, ErrorMessage } from 'UI';
import { toggleModal } from 'store/util/actions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ChoosePlan = ({ onClose }) => {
    const { data: subscriptionPlans, inProgress, error } = useSelector(
        getSubscriptionPlans,
        shallowEqual
    );
    const user = useSelector(
        getUser,
        shallowEqual
    );
    const isFree = useSelector(
        userIsFree,
        shallowEqual
    );
    const [ loading, setLoading ] = useState(false);
    const dispatch = useDispatch();

    const createSubscription = async (planId) => {
        try {
            const response = await API.post(
                '/subscriptions/web/checkout/new_subscription',
                {
                    plan_id: planId
                }
            );
            return response.data.data;
        } catch (error) {
            toast.error();
            //eslint-disable-next-line
            console.warn(error);
            setLoading(false);
        }
    };

    const handleSubscription = async (planId) => {
        if (window.dataLayer && window.dataLayer.push) {
            window.dataLayer.push({'event': 'kc-payment-init'});
        }
        setLoading(true);
        const data = await createSubscription(planId);
        const stripe = await stripePromise;
        try {
            await stripe.redirectToCheckout({
                sessionId: data && data.id,
            });
        } catch (error) {
            //eslint-disable-next-line
            console.warn(error);
            toast.error();
        }
        setLoading(false);
    };

    useEffect(() => {
        if (error) {
            toast.error({ 
                type: 'subscription', 
                action: 'fetch',
                key: 'plans',
            });
        }
    }, [error]);

    return (
        <>
            {subscriptionPlans && subscriptionPlans.length ?
                <ul className="grid gap-3 grid-cols-2 justify-between relative">
                    <Loader loading={loading || (inProgress ? inProgress : false)} className="bg-white bg-opacity-20" />
                    {subscriptionPlans.map((plan, i) => {
                        if (!user && plan.currency !== 'USD') {
                            return null;
                        } else {
                            return (
                                <li key={i} className="bg-primary-dark p-4 rounded-md divide-y divide-grey-light items-center">
                                    <div className="h-36 flex flex-col justify-between pb-2">
                                        <Text size="xxs" className="uppercase">{plan.subtitle}</Text>
                                        <Head level="3" size="xs" weight="medium">{plan.name}</Head>
                                    </div>
                                    <div className="h-38 pt-4 flex flex-col justify-between">
                                        <Text size="xs">{plan.price_text}</Text>
                                        {user ?
                                            <>    
                                                {isFree ?
                                                    <Button size="lg" rounded="md" className="text-normal mt-2" 
                                                    clicked={() => handleSubscription(plan.id)}>Upgrade</Button> :
                                                    <Button size="lg" rounded="md" className="text-normal mt-2 p-2" disabled={true}>You are already a Pro member</Button>
                                                }
                                            </>:
                                             <Button size="lg" rounded="md" className="text-normal mt-2" 
                                            clicked={() => dispatch(toggleModal('register'))}>Sign up</Button>
                                        }
                                    </div>
                                </li>
                            );
                        }
                    })}
                </ul> :
                <ErrorMessage>
                    No subscription plans available. <br/> Please refresh the page or come back later.
                </ErrorMessage>
            }
        </>
    );
};

export default ChoosePlan;
