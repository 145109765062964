import React from 'react';

import { Link } from 'react-router-dom';
import { formatDuration } from 'shared/time';
import PropTypes from 'prop-types';

import { ReactComponent as PlayAlt } from 'assets/icons/player/play@2x.svg';
import { Text, Lock, Head } from 'UI';

const MasterclassPreview = ({ 
    masterclass,
    headingLevel
}) => {
    return (
        <Link to={`/masterclass/chapter/${masterclass.masterclass_episode_id || masterclass.id}`} 
            className="flex flex-col mr-2 w-60" onClick={(e) => masterclass.locked && e.preventDefault()}>
            <div className="h-40 w-60 bg-contain bg-no-repeat bg-center relative" style={{
                backgroundImage: `url(${masterclass.thumbnail?.formats.thumbnail.url})`
            }}>
                {masterclass.locked ?
                    <Lock /> :
                    <div className="absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 h-full z-10">
                        <PlayAlt />
                    </div>
                }
                {/*<div className="w-full h-1 absolute bottom-0 left-0 bg-grey-dark z-index-5"></div>
                <div className="h-1 absolute bottom-0 left-0 bg-primary z-index-10" style={{ width: `${masterclass.progress}%` }}></div>*/}
            </div>
            <div className="mt-3">
                <Head weight="normal" level={headingLevel} paddingY={0}>{masterclass.title}</Head>
                <Text size="sm">{ formatDuration(masterclass.duration || masterclass.dm_data?.duration) }</Text>
            </div>
        </Link>
    );
};

MasterclassPreview.propTypes = {
    masterclass: PropTypes.object.isRequired,
    headingLevel: PropTypes.number.isRequired
};

MasterclassPreview.defaultProps = {
    headingLevel: 2
};

export default MasterclassPreview;
