import PropTypes from 'prop-types';
import React from 'react';

const Head = ({
    children,
    className,
    weight,
    level,
    paddingY,
    size,
    ...props
}) => {

    const TagName = `h${level}`;

    if (!className) {
        className = "";
    }

    className += ` font-${weight}${paddingY ? (' py-'+paddingY) : undefined} tracking-wide block`;

    if (size === 'xs') {
        className += " text-xl"; //20px
    } else if (size === 'sm') {
        className += " text-2xl leading-7"; //24px
    } else if (size === 'md') {
        className += " text-3xl"; //30px
    } else if (size === 'lg') {
        className += " text-4xl"; //36px
    } else if (size === 'xl') {
        className += " text-4.5xl"; //44px
    } else if (size === '2xl') {
        className += " text-5xl"; //48px
    } else if (size === '3xl') {
        className += " text-6xl"; //60px
    } else {
        className += ` ${size}`;
    }

    return (
        <TagName
            className={className}
            {...props}
        >
            {children}
        </TagName>
    );
};

Head.propTypes = {
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array])
        .isRequired,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl']),
    level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    weight: PropTypes.oneOf(['thin', 'normal', 'medium', 'bold', 'black']),
    paddingY: PropTypes.number
};

Head.defaultProps = {
    weight: 'bold',
    level: 1,
    paddingY: 2
};

export default Head;
