import React, { useEffect } from 'react';
import qs from 'query-string';

import { Spinner, Head } from 'UI';
import { toast } from 'react-toastify';
import API from 'API';

import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/icons/common/logo_2@2x.svg';
import { MAIN_URLS } from 'shared/urlList';

export default function ProductPaymentSuccess() {
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);

    const validateStripeSession = async (sessionId) => {
        try {
            const response = await API.post(
                '/products/purchase/validate-payment', {
                    session_id: sessionId,
                }
            );
            toast.success('Thank yo for your puchase!');
            history.replace(response?.data?.data?.final_url ?? '/home');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error();
        }
    };

    useEffect(() => {
        const { session_id } = qs.parse(location.search);

        if (!session_id) {
            history.replace(MAIN_URLS.HOME);
        } else {
            validateStripeSession(session_id);
        }
    }, []);

    useEffect(() => {
        document.title = "Processing your request...";
    });

    return (
        <div className="flex items-center justify-center flex-col h-screen">
            <Logo />
            <Head size="lg">Processing your request</Head>
            {loading ? <Spinner /> : <div style={{ visibility: 'hidden', height: '42px' }}/>}
        </div>
    );
}
