import API from 'API';

const API_LIST = {
    GET_FOOTER_DATA: '/content/footer',
    SUBSCRIBE_NL: '/content/subscribe'
};

export function getFooterData() {
    return API.get(API_LIST.GET_FOOTER_DATA);
}

export function subscribeNewsletter({ email }) {
    return API.post(API_LIST.SUBSCRIBE_NL, {
        email
    });
}
