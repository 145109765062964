import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import qs from 'query-string';

import {
    Page
} from 'components';

import { connect, useDispatch } from 'react-redux';

import {
    Head,
    Input,
    Select,
    Checkbox,
    Button,
    Form,
    Loader
} from 'UI';

import API from 'API';
import { checkEmailRequest, checkUsernameRequest, registerNoConfirmRequest, registerRequest } from 'store/user/actions';
import { getEmailCheckRequest, getRegisterRequest, getUsernameCheckRequest } from 'store/user/selector';
import { toggleModal } from 'store/util/actions';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { MAIN_URLS } from 'shared/urlList';
import { subscribeNewsletterRequest } from 'store/footer/actions';
import { getSubscriptionPlansRequest } from 'store/subscriptions/actions';

const RegisterPage = ({ getRegisterRequest, checkUsername, checkEmail }) => {
    const [countries, setCountries] = useState([]);
    const [levels] = useState([
        { value: 'beginner', label: 'Beginner - 800' },
        { value: 'casual', label: 'Casual - 1000' },
        { value: 'club', label: 'Club - 1200' },
        { value: 'league', label: 'League - 1400' },
        { value: 'tournament', label: 'Tournament - 1600' },
        { value: 'advanced', label: 'Advanced - 1800' },
        { value: 'expert', label: 'Expert - 2000' },
        { value: 'master', label: 'Master - 2200+' },
    ]);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [user, setUser] = useState(null);
    const { type: registerType } = useParams();
    const { error, isSuccessful, inProgress } = getRegisterRequest;
    const { error: checkUsernameError } = checkUsername;
    const { error: checkEmailError } = checkEmail;

    const onSubmit = (data) => {
        if (registerType === 'email') {
            dispatch(registerNoConfirmRequest(data));
        } else {
            data = {
                ...user,
                ...data
            };
            dispatch(registerRequest(data, registerType));
        }
        if (data.subscribe) {
            dispatch(subscribeNewsletterRequest({ email: data.email }));
        }
    };

    const fetchCountries = async () => {
        try {
            const { data } = await API.get('/users/countries');

            setCountries(data.data);
        } catch (error) {
            // eslint-disable-next-line
            console.warn(error);
        }
    };

    const onUsernameInputChange = (e) => {
        if (e.target.value.length > 5 && e.target.value.length < 31) {
            dispatch(checkUsernameRequest(e.target.value));
        }
    };

    const onEmailInputChange = (e) => {
        if (e.target.value.length > 5 && e.target.value.length < 63) {
            dispatch(checkEmailRequest(e.target.value));
        }
    };

    const redirectAfterRegister = () => {
        const { search } = location;
        if (window.dataLayer && window.dataLayer.push) {
            window.dataLayer.push({'event': 'kc-registration'});
        }
        dispatch(getSubscriptionPlansRequest());
        if (search) {
            const parsed = qs.parse(search);
            if (parsed && parsed.redirect) {
                history.push(parsed.redirect);
            }
        } else {
            history.push(MAIN_URLS.HOME);
        }
    };

    useEffect(() => {
        fetchCountries();
        if (registerType === 'facebook') {
            if (localStorage._k_sqr_fb_dta) {
                setUser(JSON.parse(localStorage._k_sqr_fb_dta));
            } else {
                redirectAfterRegister();
            }
        } else if (registerType === 'google') {
            if (localStorage._k_sqr_go_dta) {
                setUser(JSON.parse(localStorage._k_sqr_go_dta));
            } else {
                redirectAfterRegister();
            }
        } else if (registerType === 'apple') {
            if (localStorage._k_sqr_a_dta) {
                setUser(JSON.parse(localStorage._k_sqr_a_dta));
            } else {
                redirectAfterRegister();
            }
        }
    }, []);

    useEffect(() => {
        if (isSuccessful) {
            redirectAfterRegister();
        }
    }, [isSuccessful]);

    return (
        <Page className="flex-grow p-8" title={`Register with ${registerType}`}
            withPartials wrapperClassName="flex flex-col flex-auto bg-gradient-to-r from-purple-dark to-purple-light">
            <Loader loading={inProgress} isFixed={true} className="bg-opacity-40" />
            <section>
                <Head size="md" weight="medium">Sign up with {registerType}</Head>
                <Form className="flex flex-col gap-4" onSubmit={onSubmit} noValidate>
                    <Input
                        label="Username"
                        placeholder="Your username"
                        name="username"
                        onInputChange={debounce(onUsernameInputChange, 400)}
                        checkError={checkUsernameError ? 'That username is already taken' : null}
                        rules={{
                            required: true,
                            minLength: 5,
                            maxLength: 31,
                            isNoSpace: true,
                            isNoSpecial: true,
                        }} />

                    {registerType && registerType === 'email' ?
                        <Input
                            label="E-mail"
                            placeholder="Your e-mail"
                            name="email"
                            onInputChange={debounce(onEmailInputChange, 400)}
                            checkError={checkEmailError ? 'That email is already taken' : null}
                            rules={{
                                required: true,
                                minLength: 5,
                                maxLength: 63,
                                isEmail: true,
                            }} /> : <></>
                    }

                    <Select
                        label="Your country"
                        displayKeyName="name"
                        valueKeyName="code"
                        defaultOption={{code: null, name: 'Your country'}}
                        options={countries}
                        name="country"
                        rules={{required: true}}  />

                    <Select
                        label="Your level"
                        displayKeyName="label"
                        valueKeyName="value"
                        defaultOption={{value: null, label: 'Your level'}}
                        options={levels}
                        name="skill_level"
                        rules={{required: true}} />

                    {registerType && registerType === 'email' ?
                        <Input
                            label="Your password"
                            placeholder="Your password"
                            type="password"
                            autoComplete="off"
                            visibilityButton
                            name="password"
                            rules={{
                                required: true,
                                minLength: 8,
                                maxLength: 20,
                                isPassword: true,
                            }} />:<></>
                    }

                    <Checkbox
                        name="terms"
                        value={false}
                        rules={{isChecked: true}}>
                        <>By continuing, you agree to Keysquare's <span className="underline" onClick={() => dispatch(toggleModal('terms'))}>terms and conditions</span> and <span className="underline" onClick={() => dispatch(toggleModal('privacy'))} >privacy policy</span>.</>
                    </Checkbox>

                    <Checkbox
                        name="subscribe"
                        value={false}>
                        <>Subscribe to get chess tips and updates on new lessons by email. You can unsubscribe at anytime.</>
                    </Checkbox>

                    <Button size="xl" type="submit" textColor="purple-dark" bgColor="white"
                        rounded="sm" weight="medium">Complete sign-up</Button>
                </Form>
            </section>
        </Page>
    );
};

const mapStateToProps = (state) => {
  return {
    getRegisterRequest: getRegisterRequest(state),
    checkUsername: getUsernameCheckRequest(state),
    checkEmail: getEmailCheckRequest(state),
  };
};

export default connect(mapStateToProps)(RegisterPage);
