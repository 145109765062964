import React, { useState, useEffect } from 'react';
import { Button, Modal, Text } from 'UI';

import { ReactComponent as ChevronRight } from 'assets/icons/arrows/arrow_right_purple.svg';
import { ReactComponent as Check } from 'assets/icons/common/checkmark_ok.svg';
import { connect } from 'react-redux';
import { selectDifficultyLevels, selectLessonsCategories, selectTeachers } from 'store/strapi/selector';

const Filters = ({ onFilter, showFilters, filters, onFilterClose, selectedFilters }) => {
    const [modalStep, setModalStep] = useState(null);
    const [newFilters, setNewFilters] = useState(selectedFilters);

    const selectFilter = (item) => {
        setNewFilters({
            ...newFilters,
            [modalStep] : item.id === newFilters[modalStep]?.id ? null : item,
        });
    };

    const resetFilters = () => {
        setNewFilters({
            themes: null,
            teachers: null,
            levels: null
        });
    };

    useEffect(() => {
        setNewFilters(selectedFilters);
    }, [showFilters]);

    return (
        <Modal isVisible={showFilters} close={() => onFilterClose()} 
            title={modalStep ? filters[modalStep].title : undefined} 
            back={modalStep ? (() => setModalStep(null)) : undefined}
            label="lessonFilters"
            description="Filter lessons with a list of toggable criteria">
            <div className="flex flex-col flex-grow h-screen h-screen-mobile transition-all pt-12 overflow-hidden">
                <div className="flex-shrink h-4/5 overflow-scroll">
                    {filters && !modalStep &&
                        <ul className="divide-y divide-white divide-opacity-10">
                            <li className="py-8 px-8 flex justify-between items-center">
                                <Text>Filter</Text>
                                <Button size="sm" rounded="md" bgColor="transparent" 
                                    className="border border-white border-opacity-40"
                                    clicked={(resetFilters)}>Reset</Button>
                            </li>
                            {Object.keys(filters).map((keyName, i) => {
                                return (
                                    <li key={i} className="p-8 flex justify-between items-center" onClick={() => setModalStep(keyName)}>
                                        <Text size="lg">{filters[keyName].title}</Text>
                                        <div className="flex items-center gap-2">
                                            {newFilters[keyName] && 
                                                <Text size="lg" textColor="primary">{newFilters[keyName].value ?? newFilters[keyName].name}</Text>
                                            }
                                            <ChevronRight className="h-4"/>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    }

                    {modalStep && 
                        <ul className="divide-y divide-white divide-opacity-10">
                            {filters[modalStep].items?.map((item, i) => {
                                const isActive = newFilters[modalStep] && newFilters[modalStep].id === item.id;
                                return (
                                    <li key={i} 
                                        className={`active:font-bold p-8 flex justify-between items-center${(isActive) ? ' active':''}`} 
                                        onClick={() => selectFilter(item)}>
                                        <Text size="lg" weight={isActive ? 'bold':undefined}>{item.name ?? item.value}</Text>
                                        <Check className={!isActive ? 'hidden':undefined} />
                                    </li>
                                );
                            })}
                        </ul>
                    }
                </div>
                <div className="p-8 flex-shrink">
                    <Button size="xl" clicked={() => onFilter(newFilters)}>View results</Button>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        filters: {
            themes: {
                title: 'Theme',
                items: selectLessonsCategories(state)
            },
            teachers: {
                title: 'Trainer',
                items: selectTeachers(state)
            },
            levels: {
                title: 'Level',
                items: selectDifficultyLevels(state)
            }
        }
    };
};

export default connect(mapStateToProps)(Filters);
