import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { toggleModal } from 'store/util/actions';
import { getIsGuest } from 'shared/localStorage';
import { userIsFree } from 'store/user/selector';

import { ReactComponent as LockIcon } from 'assets/icons/common/lock.svg';

const Lock = ({ className }) => {
    const dispatch = useDispatch();
    const isGuest = getIsGuest();
    const isFree = useSelector(userIsFree, shallowEqual);

    const promptUser = () => {
        if (!isGuest && isFree) {
            dispatch(toggleModal('upgrade'));
        } else {
            dispatch(toggleModal('guest'));
        }
    };

    return (
        <div 
            onClick={promptUser}
            className={`${className ? className:""} absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 h-full z-10`}>
            <LockIcon width="42" height="42" />
        </div>
    );
};

export default Lock;
