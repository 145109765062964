import { all } from 'redux-saga/effects';
import { localeSaga } from 'store/locale/saga';
import { strapiSaga } from 'store/strapi/saga';
import { utilSaga } from 'store/util/saga';
import { subscriptionSaga } from 'store/subscriptions/saga';
import { userSaga } from 'store/user/saga';
import { footerSaga } from 'store/footer/saga';
import { programSaga } from 'store/program/saga';

export default function* rootSaga() {
    yield all([
        localeSaga(),
        utilSaga(),
        strapiSaga(),
        subscriptionSaga(),
        userSaga(),
        footerSaga(),
        programSaga()
    ]);
}
