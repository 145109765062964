import React, { useState, useEffect, useRef } from 'react';
import { Selector } from 'UI';
import { useClickOutside } from 'shared/utility';

const Sorters = ({ showSorters, sorters, onSelectedSorterChange, onSorterClose, selectedSorter }) => {
    const [style, setStyle] = useState({transform: 'scaleY(0)'});
    const ref = useRef();
    const handler = (e) => {
        if (!e.target.dataset || !e.target.dataset.ref || 
            (e.target.dataset.ref !== 'sorters' && e.target.dataset.ref !== 'filters')){
            onSorterClose();
        }
    };

    useClickOutside(ref, handler);

    const select = (item) => {
        onSelectedSorterChange(item);
    };

    useEffect(() => {
        if (showSorters) {
            setStyle({
                transform: 'scaleY(1)',
                transformOrigin: 'top center'
            });
        } else {
            setStyle({
                transform: 'scaleY(0)',
                transformOrigin: 'top center'
            });
        }
    }, [showSorters]);

    return (
        <div style={style} className="absolute z-20 top-0 right-0 bg-grey-dark overflow-hidden transition-all" ref={ref}>
            <Selector items={sorters} displayKeyName="name" onSelection={select} 
                selected={selectedSorter} itemListClass="px-8" />
        </div>
    );
};

export default Sorters;
