import { Page } from 'components';
import { Head, Text, ButtonLink } from 'UI';
import HeroUpgrade from 'assets/images/hero_upgrade.jpeg';
import { withRouter, Link } from 'react-router-dom';

import KasparovChip from 'assets/images/kasparov-chip.png';
import UpgradeLearn from 'assets/images/upgrade/teacher-learn.png';
import UpgradeWatch from 'assets/images/upgrade/video-watch.png';
import UpgradePlay from 'assets/images/upgrade/play.png';
import { ReactComponent as CameraChip } from 'assets/icons/upgrade/camera_chip.svg';
import { ReactComponent as ChessWinChip } from 'assets/icons/upgrade/chess_win_chip.svg';
import { ReactComponent as PuzzleChip } from 'assets/icons/upgrade/puzzle_chip.svg';
import { ReactComponent as TrophyChip } from 'assets/icons/upgrade/trophy_chip.svg';
import { ReactComponent as ReportChip } from 'assets/icons/upgrade/report_chip.svg';

import { MAIN_URLS, MASTERCLASS_URLS, PROGRAMS_URLS, VIDEOS_URLS } from 'shared/urlList';

const WelcomePage = () => {
    const blocks = [{
        label: <>Grand Masters Lessons &<br/>Improvement Programs</>,
        meta: "Grand Masters Lessons & Improvement Programs",
        icon: <div><ChessWinChip /></div>,
        buttonUri: PROGRAMS_URLS.ALL,
    },{
        label: "Masterclass with Garry Kasparov",
        icon: <img src={KasparovChip} alt="" className="w-14"/>,
        meta: "Masterclass with Garry Kasparov",
        buttonUri: MASTERCLASS_URLS.ALL,
    },{
        label: <>Unlimited puzzles on the <a href="https://apps.apple.com/us/app/kasparovchess/id1533398101" className="underline">iOS</a><br/>and <a href="https://play.google.com/store/apps/details?id=com.keysquare.kasparovchess&hl=en&gl=US&showAllReviews=true" target="_blank" className="underline">Android app</a></>,
        meta: "Unlimited puzzles on the iOS and Android app.",
        icon: <div><PuzzleChip /></div>,
    },{
        label: "All documentaries and interviews",
        meta: "All documentaries and interviews",
        icon: <div><CameraChip /></div>,
        buttonUri: VIDEOS_URLS.ONE.replace(':id', 11),
    }];

    return (
        <Page wrapperClassName="bg-black">
          <div style={{backgroundImage: `url(${HeroUpgrade})`}} 
              className="flex flex-col bg-blend-multiply pt-12 pb-8 px-8 bg-top bg-no-repeat bg-cover bg-center gap-8">
              <section className="text-center space-y-3">
                  <Head size="lg" weight="medium" className="text-center" className="text-primary">
                      Congratulations!
                  </Head>
                  <Head size="xs" weight="medium" level={2} className="text-center">
                      Welcome to Pro Membership
                  </Head>
              </section>

              <section className="my-2">
                <ul className="grid grid-cols-1 space-y-12 text-center font-medium">
                  {blocks.map(block => {
                    if (block.buttonUri) {
                      return (
                        <li>
                          <Link
                            to={block.buttonUri}
                            className="block flex flex-col space-y-6 items-center w-full"
                            title={block.meta}
                          >
                            {block.icon}
                            <div className="text-md">{block.label}</div>
                          </Link>
                        </li>
                      );
                    } else {
                      return (
                        <li className="block flex flex-col space-y-6 items-center w-full">
                          {block.icon}
                          <div className="text-md">{block.label}</div>
                        </li>
                      );
                    }
                  })}
                </ul>
                <div className="flex items-center justify-center mt-16">
                  <ButtonLink weight="medium" size="lg" rounded="md" to={MAIN_URLS.HOME}>Get started</ButtonLink>
                </div>
              </section>
          </div>
        </Page>
    );
};

export default withRouter(WelcomePage);
