import { 
    MAIN_URLS, 
    MASTERCLASS_URLS,
    ARTICLES_URLS,
    LESSONS_URLS,
    VIDEOS_URLS,
} from './urlList';

const meta = {};

meta[MAIN_URLS.HOME] = {
    title: 'Learn and improve playing chess online',
    metaDesc: 'Play chess online and improve with Garry Kasparov masterclass, lessons from grandmasters, but also relax with entertaining documentaries, interviews, podcasts covering all aspects of chess'
};

meta[MASTERCLASS_URLS.ALL] = {
    title: 'improve your chess game with Garry Kasparov’s masterclass',
    metaDesc: 'Kasparovchess - improve your chess game with Garry Kasparov’s masterclass'
};


meta[MASTERCLASS_URLS.ALL] = { 
    title: 'improve your chess game with Garry Kasparov’s masterclass',
    metaDesc: 'Kasparovchess - improve your chess game with Garry Kasparov’s masterclass',
};

meta[ARTICLES_URLS.ALL] = {
    title: 'news and articles',
    metaDesc: 'Read every news and learn about chess with Kasparovchess',
};

meta[LESSONS_URLS.ALL] = { 
    title: 'learn chess online with the best!',
    metaDesc: 'Enjoy more than 800 lessons covering all aspects of the game with Kasparovchess',
};

meta[MAIN_URLS.REGISTER.replace(':type', 'email')] = {
    title: 'learn chess online - register with email',
    metaDesc: 'Register to play chess online and improve with Garry Kasparov masterclass, lessons from grandmasters, but also relax with entertaining documentaries, interviews, podcasts covering all aspects of chess',
};

meta[MAIN_URLS.REGISTER.replace(':type', 'facebook')] = {
    title: 'learn chess online - register with Facebook',
    metaDesc: 'Register to play chess online and improve with Garry Kasparov masterclass, lessons from grandmasters, but also relax with entertaining documentaries, interviews, podcasts covering all aspects of chess',
};

meta[MAIN_URLS.REGISTER.replace(':type', 'goole')] = {
    title: 'learn chess online - register with Google',
    metaDesc: 'Register to play chess online and improve with Garry Kasparov masterclass, lessons from grandmasters, but also relax with entertaining documentaries, interviews, podcasts covering all aspects of chess',
};

meta[MAIN_URLS.REGISTER.replace(':type', 'apple')] = {
    title: 'learn chess online - register with Apple',
    metaDesc: 'Register to play chess online and improve with Garry Kasparov masterclass, lessons from grandmasters, but also relax with entertaining documentaries, interviews, podcasts covering all aspects of chess',
};

meta[VIDEOS_URLS.GRAND_CHESS_TOUR] = {
    title: 'Learn and improve chess online - Grand Chess Tour',
    metaDesc: 'Elevate your chess game with Garry Kasparov masterclass, lessons from grandmasters, entertaining documentaries and interviews.',
};

export default meta;
