import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { PropTypes } from 'prop-types';
import * as userActions from 'store/user/actions';
import * as utilActions from 'store/util/actions';

import { getIsGuest } from 'shared/localStorage';
import { getAccesstoken } from 'API';

import Layout from './components/Common/Layout/Layout.jsx';
import ScreenSizeWrapper from './components/Wrappers/ScreenSizeWrapper.jsx';
import RouterWrapper from './components/Wrappers/RouterWrapper.jsx';
import version from './version';

import {
    dailymotionSDK,
    facebookSDK,
    googleSDK,
    appleSDK
} from './SDK';

// import version from './version';
import messages from './assets/translations/messages';

// Set these vars in DOM to activate webview mode
console.log('kcJwtToken', document.kcJwtToken); // eslint-disable-line no-undef
console.log('kcEmbeddedView', document.kcEmbeddedView); // eslint-disable-line no-undef

const App = ({ store, getUser, activateWebview, lang, ...props }) => {
    window.keyPush = props.history.push;
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        initUser();
        googleSDK();
        facebookSDK(lang);
        appleSDK();
        dailymotionSDK();
        initWebview();

        // Print version in the console
        console.log('***********'); // eslint-disable-line no-console
        console.log('App Version'); // eslint-disable-line no-console
        console.log(version);       // eslint-disable-line no-console
        console.log('***********'); // eslint-disable-line no-console

        if (process.env.REACT_APP_IS_PRODUCTION) {
            window.console.log = () => {};
            window.console.warn = () => {};
            window.console.info = () => {};
            window.console.error = () => {};
        }
    }, [lang]);

    const initUser = () => {
        const isGuest = getIsGuest();

        // Do this only if there is no isGuest param already
        if (isGuest === undefined) {
            getAccesstoken().then((response) => {
                getUser();
                setIsReady(true);
            });
        } else {
            setIsReady(true);
        }
    };

    const initWebview = () => {
        if (document.kcEmbeddedView == true && document.kcJwtToken) {
            activateWebview(document.kcJwtToken);
        }
    };

    return (
        <IntlProvider locale={lang} messages={messages[lang]}>
            <Provider store={store}>
                {isReady &&
                    <Layout>
                        <ScreenSizeWrapper>
                            <RouterWrapper />
                        </ScreenSizeWrapper>
                    </Layout>
                }
            </Provider>
        </IntlProvider>
    );
};

App.propTypes = {
    guest: PropTypes.bool,
    lang: PropTypes.string.isRequired,
    logout: PropTypes.func,
    activateWebview: PropTypes.func
};

const mapDispatchToProps = {
    getUser: (props) => userActions.getUser(props),
    logout: (props) => userActions.logout(props),
    activateWebview: (props) => utilActions.activateWebview(props)
};

const mapStateToProps = (state) => {
    return {
        guest: state.user.guestUser,
        lang: state.locale.lang,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
