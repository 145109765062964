import React, { useEffect } from 'react';

import {useSelector, shallowEqual} from "react-redux";

import { ReactComponent as Logo } from 'assets/icons/common/logo_2@2x.svg';

import {getUser, getUserAccessToken} from 'store/user/selector';

import * as Sentry from '@sentry/react';
import { MAIN_URLS } from 'shared/urlList';
import {getIsGuest} from "shared/localStorage";
import { ButtonLink, Head } from 'UI';

const FourOFour = () => {
    const user = useSelector(getUser, shallowEqual);
    const isGuest = getIsGuest();
    const token = useSelector(getUserAccessToken, shallowEqual);
    // TODO Add additional data: token, userId, username,
    Sentry.withScope(scope => {
        scope.setExtra("isGuest", isGuest);
        scope.setExtra("user", user);
        scope.setExtra("accessToken", token);
        Sentry.captureMessage("page 404");
    });
    // Sentry.captureMessage("Test 1");
    // Sentry.captureException(new Error('404 page'), {
    //     tags: {
    //         section: "articles",
    //     }
    // });
    

    useEffect(() => {
        document.title = "404 page not found";
    });

    return (
        <div className="flex flex-col items-center justify-center h-screen p-8">
            <div>
                <Logo />
            </div>
            <Head level="2">
                Oops, that’s a blunder! We couldn’t find what you were
                looking for.
            </Head>
            <Head level="1" size="xl">404 ERROR</Head>
            <ButtonLink to={MAIN_URLS.HOME} size="md">
                <div>Back to home</div>
            </ButtonLink>
        </div>
    );
};

export default FourOFour;
