import React, { useRef } from 'react';
import Slider from 'react-slick';
import merge from 'lodash/merge';

import SlideOne from 'assets/images/home/slide-one.png';
import SlideTwo from 'assets/images/home/slide-two.png';
import SlideThree from 'assets/images/home/slide-three.png';
import SlideFour from 'assets/images/home/slide-four.png';

const CarouselHome = ({ ...props }) => {
    const slider = useRef(null);
    const slides = [
        SlideOne,
        SlideTwo,
        SlideThree,
        SlideFour
    ];
    const slideSettings = {
        autoplay: true,
        infinite: true,
        fade: true,
        autoplaySpeed: 4000,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: false,
        swipe: false,
        touchThreshold: 3,
        arrows: false,
        // centerMode: true,
        // centerPadding: '0px',
        mobileFirst: true
    };

    // merge default settings with component settings
    if (typeof props.settings === 'undefined') {
        props.settings = {};
    }
    merge(slideSettings, props.settings);

    const calculateStyles = () => {
        return {
            width: props.screenWidth
        };
    };


    return (
        <section
            style={calculateStyles()}
        >
            <Slider {...slideSettings} ref={slider}>
                {slides.map((slide, i) => {
                    return (
                        <div key={i}>
                            <div style={{backgroundImage: `url(${slide})`, overflow: 'hidden'}}
                                className="w-screen bg-cover bg-center bg-no-repeat bg-no-repeat h-96"></div>
                        </div>
                    );
                })}
            </Slider>
        </section>
    );
};

export default CarouselHome;
