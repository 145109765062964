import { updateObject } from 'shared/utility';
import {
    setUser,
    removeUser,
    removeGuestUser,
    removeIsGuest,
} from 'shared/localStorage';
import * as constants from './constants';
import { USER_ROLES } from 'shared/userRoles';
// import { removeAccessToken } from './actions';
// import { setAccessToken as APISetAccessToken } from 'API';

const initialState = {
    token: null,
    user: null,
    error: null,
    guestUser: false,
    loginRequest: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    logoutRequest: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    checkUsernameRequest: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    checkEmailRequest: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    registerRequest: {
        inProgress: false,
        isSuccessful: null,
        data: null,
        error: null,
    },
    startPasswordRecoveryRequest: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    recoverPasswordRequest: {
        inProgress: null,
        isSuccessful: null,
        data: null,
        error: null,
    },
    setUserState: {
        inProgress: false,
        success: null,
        error: null,
    },
    accessToken: null,
    refreshToken: null,
    userColors: {},
    showReaction: true,
};

const userLogout = (state) => {
    removeUser();
    return updateObject(state, {
        token: null,
        accessToken: null,
        refreshToken: null,
        user: null,
        guestUser: false,
        userColors: {},
    });
};

const authFail = (state, { error }) => {
    removeUser();
    return updateObject(state, {
        token: null,
        user: null,
        error,
    });
};

const setAccessToken = (state, { token }) => {
    return {
        ...state,
        accessToken: token,
    };
};

const removeAccessToken = (state, action) => {
    return {
        ...state,
        accessToken: null,
    };
};

const setRefreshToken = (state, { token }) => {
    return updateObject(state, {
        refreshToken: token,
    });
};

const setUserColors = (state, action) => {
    switch (action.data) {
        case USER_ROLES.BEGINNER:
            return {
                ...state,
                userColors: { color1: '#3dbb75', color2: '#bedf79' },
            };
        case USER_ROLES.CASUAL:
            return {
                ...state,
                userColors: { color1: '#099d90', color2: '#08e5a7' },
            };
        case USER_ROLES.CLUB:
            return {
                ...state,
                userColors: { color1: '#104f9f', color2: '#0bbebf' },
            };
        case USER_ROLES.LEAGUE:
            return {
                ...state,
                userColors: { color1: '#614bef', color2: '#113e9a' },
            };
        case USER_ROLES.TOURNAMENT:
            return {
                ...state,
                userColors: { color1: '#73116f', color2: '#732ede' },
            };
        case USER_ROLES.ADVANCED:
            return {
                ...state,
                userColors: { color1: '#b64952', color2: '#761164' },
            };
        case USER_ROLES.EXPERT:
            return {
                ...state,
                userColors: { color1: '#bfb050', color2: '#be4f4f' },
            };
        case USER_ROLES.MASTER:
            return {
                ...state,
                userColors: { color1: '#151515', color2: '#363636' },
            };
        default:
    }
};

const getUserSuccess = (state, { user }) => {
    removeGuestUser();
    removeIsGuest();
    setLocalStorageLogin(user);

    return updateObject(state, {
        user,
        guestUser: false,
    });
};

const setGuestUser = (state) => {
    return updateObject(state, {
        guestUser: true,
        userColors: null,
    });
};

const resetUser = (state) => {
    return updateObject(state, {
        guestUser: false,
    });
};

const loginRequest = (
    state,
    inProgress,
    isSuccessful,
    data,
    error,
    errorType
) => {
    return updateObject(state, {
        user: data,
        loginRequest: {
            inProgress,
            isSuccessful,
            data,
            error,
            errorType,
        },
    });
};

const registerRequest = (state, inProgress, isSuccessful, data, error) => {
    return updateObject(state, {
        registerRequest: {
            inProgress,
            isSuccessful,
            data,
            error,
        },
    });
};

const checkUsernameRequest = (state, inProgress, isSuccessful, data, error) => {
    return updateObject(state, {
        checkUsernameRequest: {
            inProgress,
            isSuccessful,
            data,
            error,
        },
    });
};

const checkEmailRequest = (state, inProgress, isSuccessful, data, error) => {
    return updateObject(state, {
        checkEmailRequest: {
            inProgress,
            isSuccessful,
            data,
            error,
        },
    });
};

const startPasswordRecoveryRequest = (
    state,
    inProgress,
    isSuccessful,
    data,
    error
) => {
    return updateObject(state, {
        startPasswordRecoveryRequest: {
            inProgress,
            isSuccessful,
            data,
            error,
        },
    });
};
const recoverPasswordRequest = (
    state,
    inProgress,
    isSuccessful,
    data,
    error
) => {
    return updateObject(state, {
        recoverPasswordRequest: {
            inProgress,
            isSuccessful,
            data,
            error,
        },
    });
};

const setLocalStorageLogin = (user) => {
    setUser(JSON.stringify(user));
};

const registrationCleanup = () => {
    localStorage.removeItem('_k_sqr_go_dta');
    localStorage.removeItem('_k_sqr_go_at');
    localStorage.removeItem('_k_sqr_fb_dta');
    localStorage.removeItem('_k_sqr_fb_at');
    localStorage.removeItem('_k_sqr_a_dta');
    localStorage.removeItem('_k_sqr_a_at');
    localStorage.removeItem('colors');
    localStorage.removeItem('guest');
    localStorage.removeItem('is_guest');
};
const emailRegistrationCleanup = () => {
    localStorage.removeItem('_k_sqr_m_dta');
};

const setUserData = (state, action) => {
    return {
        ...state,
        setUserState: {
            inProgress: false,
            success: true,
        },
        user: {
            ...state.user,
            firstname: action.data.firstname,
            lastname: action.data.lastname,
            // timezone: action.data.timezone,
        },
        redirectAfterLogin: null,
    };
};

// TODO Bojan refresh token
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.USER_LOGOUT:
        case constants.CLEAR_USER:
            return userLogout(state);
        case constants.SET_ACCESS_TOKEN:
            return setAccessToken(state, action);
        case constants.REMOVE_ACCESS_TOKEN:
            return removeAccessToken(state, action);
        case constants.SET_REFRESH_TOKEN:
            return setRefreshToken(state, action);
        case constants.GET_USER_FAILURE:
            return authFail(state, action);
        case constants.GET_USER_SUCCESS:
            return getUserSuccess(state, action);
        case constants.SET_USER_COLORS:
            return setUserColors(state, action);
        case constants.SET_GUEST_USER:
            return setGuestUser(state);
        case constants.RESET_USER:
            return resetUser(state);
        case constants.LOGIN_REQUEST:
        case constants.FACEBOOK_LOGIN_REQUEST:
        case constants.GOOGLE_LOGIN_REQUEST:
        case constants.APPLE_LOGIN_REQUEST:
            return loginRequest(state, true, null, null, '');
        case constants.LOGIN_SUCCESS:
        case constants.FACEBOOK_LOGIN_SUCCESS:
        case constants.GOOGLE_LOGIN_SUCCESS:
        case constants.APPLE_LOGIN_SUCCESS:
            setLocalStorageLogin(action.data);
            return loginRequest(state, false, true, action.data, null);
        case constants.LOGIN_FAILURE:
        case constants.FACEBOOK_LOGIN_FAILURE:
        case constants.GOOGLE_LOGIN_FAILURE:
        case constants.APPLE_LOGIN_FAILURE:
            return loginRequest(
                state,
                false,
                false,
                null,
                action.error,
                action.errorType
            );
        case constants.REGISTER_REQUEST:
        case constants.EMAIL_REGISTER_REQUEST:
            return registerRequest(state, true, null, null, '');
        case constants.REGISTER_NO_CONFIRM_REQUEST:
            return registerRequest(state, true, null, null, '');
        case constants.REGISTER_NO_CONFIRM_SUCCESS:
        case constants.REGISTER_SUCCESS:
            registrationCleanup();
            return registerRequest(state, false, true, action.data, null);
        case constants.EMAIL_REGISTER_SUCCESS:
            emailRegistrationCleanup();
            return registerRequest(state, false, true, action.data, null);
        case constants.REGISTER_FAILURE:
        case constants.EMAIL_REGISTER_FAILURE:
            return registerRequest(state, false, false, null, action.error);
        case constants.CHECK_USERNAME_REQUEST:
            return checkUsernameRequest(state, true, null, null, null);
        case constants.CHECK_USERNAME_SUCCESS:
            return checkUsernameRequest(state, false, true, action.data, null);
        case constants.CHECK_USERNAME_FAILURE:
            return checkUsernameRequest(
                state,
                false,
                false,
                null,
                action.error
            );
        case constants.CHECK_EMAIL_REQUEST:
            return checkEmailRequest(state, true, null, null, null);
        case constants.CHECK_EMAIL_SUCCESS:
            return checkEmailRequest(state, false, true, action.data, null);
        case constants.CHECK_EMAIL_FAILURE:
            return checkEmailRequest(state, false, false, null, action.error);
        case constants.START_PASSWORD_RECOVERY_REQUEST:
            return startPasswordRecoveryRequest(state, true, null, null, null);
        case constants.START_PASSWORD_RECOVERY_SUCCESS:
            return startPasswordRecoveryRequest(
                state,
                false,
                true,
                action.data,
                null
            );
        case constants.START_PASSWORD_RECOVERY_FAILURE:
            return startPasswordRecoveryRequest(
                state,
                false,
                false,
                null,
                action.error
            );
        case constants.RESET_PASSWORD_RECOVERY:
            return startPasswordRecoveryRequest(state, null, null, null, null);
        case constants.RECOVER_PASSWORD_REQUEST:
            return recoverPasswordRequest(state, true, null, null, null);
        case constants.RECOVER_PASSWORD_SUCCESS:
            return recoverPasswordRequest(
                state,
                false,
                true,
                action.data,
                null
            );
        case constants.RECOVER_PASSWORD_FAILURE:
            return recoverPasswordRequest(
                state,
                false,
                false,
                null,
                action.error
            );
        case constants.LOGIN_RESET:
            return loginRequest(state, null, null, null, null);
        case constants.RESET_RECOVER_PASSWORD:
            return recoverPasswordRequest(state, null, null, null, null);
        case constants.SHOW_REACTIONS:
            return {
                ...state,
                showReaction: true,
            };
        case constants.HIDE_REACTIONS:
            return {
                ...state,
                showReaction: false,
            };
        case constants.SET_USER_REQUEST:
        case constants.SET_USER_AVATAR_REQUEST:
            return {
                ...state,
                setUserState: {
                    inProgress: true,
                    success: null,
                    error: null,
                },
            };
        case constants.SET_USER_SUCCESS:
            return setUserData(state, action);
        case constants.SET_USER_AVATAR_SUCCESS:
            return {
                ...state,
                setUserState: {
                    inProgress: false,
                    success: true,
                },
                user: {
                    ...state.user,
                    avatar: { src: action.data.avatar.src },
                },
            };
        case constants.SET_USER_FAILURE:
        case constants.SET_USER_AVATAR_FAILURE:
            return {
                ...state,
                setUserState: {
                    inProgress: false,
                    success: null,
                    error: action.error,
                },
            };
        case constants.LOGOUT_REQUEST:
            return {
                ...state,
                logoutRequest: {
                    inProgress: true,
                },
            };
        case constants.LOGOUT_SUCCESS:
            return {
                ...state,
                logoutRequest: {
                    inProgress: false,
                    data: action.data,
                    success: true,
                },
                token: null,
                accessToken: null,
                refreshToken: null,
                user: null,
                guestUser: false,
                userColors: {},
                redirectAfterLogin: null,
            };
        case constants.LOGOUT_FAILURE:
            return {
                ...state,
                logoutRequest: {
                    inProgress: false,
                    error: action.error,
                },
            };
        case constants.REDIRECT_AFTER_LOGIN:
            return {
                ...state,
                redirectAfterLogin: action.url,
            };
        default:
            return state;
    }
};

export default reducer;
