import { withRouter } from 'react-router-dom';
import { Register, Page } from 'components';

const OnBoardingPage = ({ history }) => {
    const onClose = (action) => {
        history.push('login');
    };

    return (
        <Page>
            <Register onClose={onClose} />
        </Page>
    );
};

export default withRouter(OnBoardingPage);
