// import STRAPI from 'API_STRAPI';
import API from 'API';
export const STRAPI_URL_EXTENSION = '/cms/';
export const STRAPI_URL_EXTENSION_HUBS = '/cms/hubs/';

export const STRAPI_URLS = {
    HOME: 'home',
    ARTICLES: 'articles',
    ARTICLE: 'articles/',
    MOST_VIEWED_ARTICLES: 'articles/mostviewed?_limit=3',
    VIDEOS: 'videos/',
    LESSONS_COUNT: 'lessons/count',
    LESSONS: 'lessons',
    TEACHERS: 'teachers',
    DIFFICULTY_LEVELS: 'difficulty-levels',
    LESSONS_CATEGORIES: 'lesson-categories',
};

export const getHomePage = () => {
    return API.get(STRAPI_URL_EXTENSION_HUBS + STRAPI_URLS.HOME);
};

export const getAllArticles = (params) => {
    const apiCall =
        STRAPI_URL_EXTENSION +
        STRAPI_URLS.ARTICLES +
        `?${params.data.limit ? `_limit=${params.data.limit}` : ''}${
            params.data.offset || params.data.offset === 0 ? `&_start=${params.data.offset}` : ''
        }`;

    return API.get(apiCall);
};

export const getSingleArticle = (params) => {
    const apiCall = STRAPI_URL_EXTENSION + STRAPI_URLS.ARTICLE + params.id;

    return API.get(apiCall);
};

export const getLessons = ({ filters, sortBy, limit, start }) => {
    const apiCall = `${STRAPI_URL_EXTENSION}${STRAPI_URLS.LESSONS}?` +
        `${limit ? `_limit=${limit}` : ''}` +
        `${start ? `&_start=${start}` : ''}` +
        `${sortBy ? `&_sortBy=${sortBy.key}:asc` : ''}` +
        `${filters?.themes ? `&lesson_category=${filters.themes.id}` : ''}` +
        `${filters?.levels ? `&difficulty_level=${filters.levels.id}` : ''}` +
        `${filters?.teachers ? `&teacher=${filters.teachers.id}` : ''}`;
    return API.get(apiCall);
};

export const getTeachers = () => {
    const apiCall = STRAPI_URL_EXTENSION + STRAPI_URLS.TEACHERS;
    return API.get(apiCall);
};

export const getLessonsCategories = () => {
    const apiCall = STRAPI_URL_EXTENSION + STRAPI_URLS.LESSONS_CATEGORIES;
    return API.get(apiCall);
};

export const getDifficultyLevels = () => {
    const apiCall = STRAPI_URL_EXTENSION + STRAPI_URLS.DIFFICULTY_LEVELS;
    return API.get(apiCall);
};

export const getTotalLessonsCount = () => {
    const apiCall = STRAPI_URL_EXTENSION + STRAPI_URLS.LESSONS_COUNT;
    return API.get(apiCall);
};

export const getMostViewedArticles = () => {
    const apiCall = STRAPI_URL_EXTENSION + STRAPI_URLS.MOST_VIEWED_ARTICLES;

    return API.get(apiCall);
};

export const getSingleVideo = (params) => {
    const apiCall = STRAPI_URL_EXTENSION + STRAPI_URLS.VIDEOS + params.id;

    return API.get(apiCall);
};
