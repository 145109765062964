import React from 'react';
import PropTypes from 'prop-types';

import TC_OG_IMAGE from 'assets/images/kchess_tc_og_image.png';

import { Helmet as ReactHelmet } from 'react-helmet-async';

const Helmet = ({ title, metaDesc, url }) => {
    return (
        <ReactHelmet defaultTitle="Kasparovchess" titleTemplate="Kasparovchess - %s" encodeSpecialCharacters={true}
            onChangeClientState={(newState, addedTags, removedTags) => console.log(newState, addedTags, removedTags)}
            >
            <title itemProp="name" lang="en">{title}</title>
            {/* basic meta data */}
            <meta name="description" content={metaDesc} />
            <meta
                name="keywords"
                content="chess, Kasparov, play chess online, learn, improve at chess, lessons from grandmasters, Garry Kasparov masterclass"
            />
            <meta name="robots" content="index, follow" />
            <meta
                http-equiv="Content-Type"
                content="text/html; charset=utf-8"
            />
            <meta name="language" content="English" />

            {/* open graph parameters */}

            <meta property="og:title" content={title} />
            <meta property="og:url" content={url} />
            <meta property="og:description" content={metaDesc} />
            <meta property="og:type" content="website" />
            <meta property="og:image:secure_url" content={TC_OG_IMAGE} />

            {/* twitter card parameters */}

            <meta property="twitter:title" content={title} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={`@${url}`} />
            <meta property="twitter:description" content={metaDesc} />
            <meta name="twitter:image" content={TC_OG_IMAGE} />
        </ReactHelmet>
    );
};

Helmet.propTypes = {
    title: PropTypes.string.isRequired,
    metaDesc: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

Helmet.defaultProps = {
    title: "Learn and improve playing chess online",
    metaDesc: "Play chess online and improve with Garry Kasparov masterclass, lessons from grandmasters, but also relax with entertaining documentaries, interviews, podcasts covering all aspects of chess",
    url: "https://m.kasparovchess.com"
};

export default Helmet;
