import { Page } from 'components';
import { VideoPlayer, Head } from 'UI';
import GrandChessTourImage from 'assets/images/grand-chess-tour.png';

const GrandChessTourPage = () => {
  return (<Page withPartials>
    <section className="mb-5 text-center"> 
        <VideoPlayer
            video="x7zmy17"
            autoplay={true}
        />
        <div className="flex justify-center my-5">
          <img src={GrandChessTourImage} 
            title="Watch Grand chess tour live"
            alt="Grand chess tour logo" />
        </div>
        <div className="mx-14 leading-6 space-y-5">
          <p>
            <span className="font-bold">Superbet Chess Classic Romania</span><br/>
            May 3 - 15, 2022<br/>
            Bucharest, Romania
          </p>
          <p>
            <span className="font-bold">Superbet Rapid & Blitz Poland</span><br/>
            May 17 - 24, 2022<br/>
            Warsaw, Poland
          </p>
          <p>
            <span className="font-bold">SuperUnited Rapid & Blitz Croatia</span><br/>
            July 18 - 25, 2022<br/>
            Zagreb, Croatia
          </p>
          <p>
            <span className="font-bold">Saint Louis Rapid & Blitz </span><br/>
            August 24 - 31, 2022<br/>
            St. Louis, Missouri USA
          </p>
          <p>
            <span className="font-bold">Sinquefield Cup</span><br/>
            August 31 – September 13, 2022<br/>
             St. Louis, Missouri USA
          </p>
        </div>
    </section>

    <section className="text-center leading-7 mx-14 space-y-6 mb-5">
      <Head size="sm" weight="medium" className="mt-20 mb-10">About GCT</Head>
      <p>
        The Grand Chess Tour is a circuit of international events, each demonstrating the highest level of organization for the world's best players. The legendary Garry Kasparov, one of the world's greatest ambassadors for chess, inspired the Grand Chess Tour and helped solidify the partnership between the organizers.
      </p>
      <p>
        The inaugural Grand Chess Tour was held in 2015 as a circuit of three classical events in which the format and conditions of each tournament were identical.
      </p>
      <p>
        In 2016, the tour was enhanced by adding rapid and blitz events and the tour has expanded every year since. To date, GCT tournaments have been held in Norway, France, Belgium, USA, UK, Cote d’Ivoire, India, Romania, and Croatia. In 2022, the city of Warsaw in Poland will host a GCT tournament for the first time.
      </p>
    </section>
  </Page>);
};

export default GrandChessTourPage;