import { Head, Text, Button } from 'UI';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { PROGRAMS_URLS } from 'shared/urlList';
import { ReactComponent as Check } from 'assets/icons/common/checkmark_ok.svg';
import { ReactComponent as LockWhite } from 'assets/icons/common/lock_white.svg';
import LockWhiteImg from 'assets/icons/common/lock_white.png';
import { getUser } from 'store/user/selector';
import { toggleModal } from 'store/util/actions';
import { useHistory } from 'react-router-dom';

const ProgramPreview = ({ program, headingLevel }) => {
    const dispatch = useDispatch();
    const user = useSelector(getUser, shallowEqual);
    const history = useHistory();

    const startProgram = () => {
        if (program.locked) {
            if (user) {
                dispatch(toggleModal('upgrade'));
            } else {
                dispatch(toggleModal('login'));
            }
        } else {
            history.push(PROGRAMS_URLS.ONE.replace(':id', program.id));
        }
    };

    return <div className="rounded-b-xl overflow-hidden text-sm">
        { program &&
            <>
                <div 
                    className="block bg-no-repeat bg-cover px-4 py-4"
                    style={{ backgroundImage: `linear-gradient(255.79deg, rgba(0, 0, 0, 0.0001) 23.13%, #000000 79.68%), url(${program.hero_image?.formats?.medium?.url})` }}>
                    <Head level={headingLevel} weight="medium" size="sm" 
                        className="max-w-full w-2/3">
                        {program.locked && 
                                <img src={LockWhiteImg} className="inline-block w-7 h-7 align-text-bottom" alt="" />
                        }
                        {program.title}
                    </Head>
                    <div className="flex gap-2 pt-6 pb-4">
                        {program.trainers?.map((trainer, i) => {
                            return (
                            <div key={i} className="relative h-14 w-14 flex items-center justify-center">
                                <div className="relative z-20 h-12 w-12 rounded-full overflow-hidden shadow-trainer-thumb bg-grey-darker p-3 bg-cover"
                                style={{ backgroundImage: `url(${trainer.thumbnail?.formats?.thumbnail?.url})` }}></div>
                                <div className="absolute top-0 h-14 w-14 z-10 rounded-full overflow-hidden shadow-trainer-thumb bg-grey-darker p-3 bg-cover"></div>
                            </div>);
                        })}
                    </div>
                    <Text tagName="p" className="pb-3">
                        Hosted by&nbsp;
                        {program.trainers?.map((trainer, i) => {
                            return (<span key={i}>
                                <span className="font-medium">{trainer.name}</span>
                                {program.trainers.length > (i + 1) && 
                                    <><br/>&&nbsp;</>
                                }
                            </span>);
                        })}
                    </Text>
                    <Text tagName="p" weight="medium" className="border-t pt-3 border-opacity-40">
                        {program.level.value}{program.chapter_count ? (' • '+program.chapter_count+' chapters') : ''}
                    </Text>
                </div>
                <div className="px-4 pt-6 pb-4 bg-grey-dark">
                    {program.bullet_points && program.bullet_points?.length ?
                        <>
                            <Text weight="medium" size="lg">What's inside ?</Text>
                            <ul className="grid grid-cols-2 gap-3 pb-6 pt-4">
                                {program.bullet_points?.map((point, i) => {
                                    return <li className="w-full flex items-baseline gap-2" key={i}>
                                        <div className="flex items-center shrink-0"><Check height="10" width="15" /></div>
                                        <div className="grow">{point}</div>
                                    </li>;
                                })}
                            </ul>
                        </> : <></>
                    }
                    <Button 
                        clicked={() => startProgram()}
                        to={PROGRAMS_URLS.ONE.replace(':id', program.id)}
                        size="xl"
                        weight="medium">
                        {program.progress ? 'Continue':'Start'}
                    </Button>
                </div>
            </>
        }
    </div>;
};

ProgramPreview.propTypes = {
    program: PropTypes.object.isRequired,
    headingLevel: PropTypes.number.isRequired
};

ProgramPreview.defaultProps = {
    headingLevel: 2
};

export default ProgramPreview;
