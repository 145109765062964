import { takeLatest, put as dispatch, select} from 'redux-saga/effects';
import { redirect } from 'store/util/actions';
import * as actions from './actions';
import * as api from './api';
import * as constants from './constants';
import { selectLessonsData, selectLessonsFilters, selectLessonsSortBy } from './selector';

export function* strapiSaga() {
    yield takeLatest(constants.GET_HOME_REQUEST, getHomePage);
    yield takeLatest(constants.GET_ALL_ARTICLES_REQUEST, getAllArticles);
    yield takeLatest(constants.GET_SINGLE_ARTICLE_REQUEST, getSingleArticle);
    yield takeLatest(
        constants.GET_MOST_VIEWED_ARTICLES_REQUEST,
        getMostViewedArticles
    );
    yield takeLatest(constants.GET_SINGLE_VIDEO_REQUEST, getSingleVideo);
    yield takeLatest(constants.GET_LESSONS, getLessons);
    yield takeLatest(constants.GET_MORE_LESSONS, getMoreLessons);
    yield takeLatest(constants.GET_TOTAL_LESSONS_COUNT, getTotalLessons);
    yield takeLatest(constants.GET_TEACHERS, getTeachers);
    yield takeLatest(constants.GET_DIFFICULTY_LEVELS, getDifficultyLevels);
    yield takeLatest(constants.GET_LESSONS_CATEGORIES, getLessonsCategories);
    yield takeLatest(constants.SET_LESSONS_FILTERS, setLessonsFilters);
    yield takeLatest(constants.SET_LESSONS_SORT_BY, setLessonsSortBy);
}

function* getHomePage() {
    try {
        const resp = yield api.getHomePage();
        yield dispatch(actions.getHomeSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getHomePage failed', error);
        yield dispatch(actions.getHomeFailure(error));
    }
}

function* getTotalLessons() {
    try {
        const resp = yield api.getTotalLessonsCount();
        yield dispatch(actions.getTotalLessonsCountSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getTotalLessonsCountFailure failed', error);
        yield dispatch(actions.getTotalLessonsCountFailure(error));
    }
}

function* getTeachers() {
    try {
        const resp = yield api.getTeachers();
        yield dispatch(actions.getTeachersSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getTeachersFailure failed', error);
        yield dispatch(actions.getTeachersFailure(error));
    }
}

function* getDifficultyLevels() {
    try {
        const resp = yield api.getDifficultyLevels();
        yield dispatch(actions.getDifficultyLevelsSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getDifficultyLevelsFailure failed', error);
        yield dispatch(actions.getDifficultyLevelsFailure(error));
    }
}

function* getLessonsCategories() {
    try {
        const resp = yield api.getLessonsCategories();
        yield dispatch(actions.getLessonsCategoriesSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getLessonsCategoriesFailure failed', error);
        yield dispatch(actions.getLessonsCategoriesFailure(error));
    }
}

function* getLessons(params) {
    try {
        const filters = yield select(selectLessonsFilters);
        const sortBy = yield select(selectLessonsSortBy);
        const resp = yield api.getLessons({ filters, sortBy, limit: 24, start: 0 });
        yield dispatch(actions.getLessonsSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getLessonsFailure failed', error);
        yield dispatch(actions.getLessonsFailure(error));
    }
}


function* getMoreLessons(params) {
    try {
        const filters = yield select(selectLessonsFilters);
        const sortBy = yield select(selectLessonsSortBy);
        const lessons = yield select(selectLessonsData);
        const resp = yield api.getLessons({ filters, sortBy, limit: 24, start: lessons.length });
        yield dispatch(actions.getMoreLessonsSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getMoreLessonsFailure failed', error);
        yield dispatch(actions.getMoreLessonsFailure(error));
    }
}

function* setLessonsFilters() {
    yield dispatch(actions.getLessons());
}

function* setLessonsSortBy() {
    yield dispatch(actions.getLessons());
}

function* getAllArticles(params) {
    try {
        const resp = yield api.getAllArticles(params);
        yield dispatch(actions.getAllArticlesSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getAllArticles failed', error);
        yield dispatch(actions.getAllArticlesFailure(error));
    }
}

function* getSingleArticle(params) {
    try {
        const resp = yield api.getSingleArticle(params);
        yield dispatch(actions.getSingleArticleSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getSingleArticle failed', error);
        yield dispatch(actions.getSingleArticleFailure(error));
    }
}

function* getMostViewedArticles() {
    try {
        const resp = yield api.getMostViewedArticles();
        yield dispatch(actions.getMostViewedArticlesSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getMostViewedArticles failed', error);
        yield dispatch(actions.getMostViewedArticlesFailure(error));
    }
}

function* getSingleVideo(params) {
    try {
        const resp = yield api.getSingleVideo(params);
        yield dispatch(actions.getSingleVideoSuccess(resp.data));
    } catch (error) {
        //eslint-disable-next-line
        console.warn('getSingleVideo failed', error);
        yield dispatch(actions.getSingleVideoFailure(error));
        if (error.response.data.statusCode === 403) {
            yield dispatch(redirect('/videos'));
        }
        if (error.response.data.statusCode === 404) {
            yield dispatch(redirect('/404'));
        }
    }
}
