import HeroUpgrade from 'assets/images/hero_upgrade.jpeg';
import { ReactComponent as Check } from 'assets/icons/common/checkmark_ok.svg';
import {
   Head,
   Text,
   Button
} from 'UI';

const GuestPrompt = ({ onClose }) => {
    return (
        <div style={{backgroundImage: `url(${HeroUpgrade})`}} 
            className="flex flex-col bg-blend-multiply py-12 px-8 bg-top bg-no-repeat bg-cover bg-center gap-8">
            <div className="text-center">
                <Head size="md" weight="medium" className="text-center" level="2">
                    Sign-up for free
                </Head>
                <Text className="mt-2">As a guest, the content you can access and your playing experience are limited. Sign-up to enjoy members features.</Text>
            </div>

            <section className="my-2">
                <ul className="flex flex-col gap-5 pb-5">
                    <li className="flex items-baseline gap-3">
                        <div><Check /></div>
                        <Text>Track your progress and play against friends and other rated players</Text>
                    </li>
                    <li className="flex items-baseline gap-3">
                        <div><Check /></div>
                        <Text>Free video lessons and puzzles everyday</Text>
                    </li>
                    <li className="flex items-baseline gap-3">
                        <div><Check /></div>
                        <Text>Watch documentaries, interviews, listen to podcasts, read all our articles</Text>
                    </li>
                    <li className="flex items-baseline gap-3">
                        <div><Check /></div>
                        <Text>Access trial of new content such as the Masterclass of Garry Kasparov</Text>
                    </li>
                </ul>
                <div className="text-center mt-5">
                    <Button size="xl" rounded="md" className="my-5" weight="medium"
                        clicked={() => onClose('register')}>Sign up</Button>
                    <Button bgColor="transparent" textColor="white" size="lg" weight="medium" 
                        clicked={() => onClose()}>Not now</Button>
                </div>
            </section>
        </div>
    );
};

export default GuestPrompt;
