import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Filters, Sorters, UpgradeBlock } from 'components';
import { Head, Text, Button } from 'UI';
import { setProgramsFilters, setProgramsSortBy } from 'store/program/actions';
import { ReactComponent as ChevronBottom } from 'assets/icons/arrows/arrow_bot.svg';
import { getProgramsSortBy, getProgramsFilters } from 'store/program/selector';

const ProgramsPageHeader = () => {
    const [showSorters, setShowSorters] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const selectedSorter = useSelector(getProgramsSortBy);
    const selectedFilters = useSelector(getProgramsFilters);

    const [sorters] = useState([
        { id: 1, name: 'Alphabetically', key: 'title' },
        { id: 2, name: 'Release date', key: 'created_at' },
        { id: 3, name: 'Availability - Free to Pro', key: 'subscription_level' }
    ]);

    const dispatch = useDispatch();

    const filter = (filters) => {
        setShowFilters(false);
        dispatch(setProgramsFilters(filters));
    };

    const sort = (sorter) => {
        setShowSorters(false);
        dispatch(setProgramsSortBy(sorter));
    };

    const closeFilters = () => {
        setShowFilters(false);
    };

    const closeSorters = () => {
        setShowSorters(false);
    };

    return <div className="flex flex-col gap-6">
        <div className="mb-10 text-center">
            <Head size="xl" weight="medium" style={{fontSize: '40px'}}>
                Improve your chess skills with our academy of chess masters
            </Head>
            <Text size="sm" className="mt-4 mb-10 leading-relaxed">
                Make your way up to the top with more than 40 improvement programs and become a chess master!
            </Text>
            <UpgradeBlock />
        </div>
        <div className="flex justify-between py-6 relative border-b border-white border-opacity-20">
            <Button size="sm" bgColor="transparent" data-ref="filters"
                className="border border-white border-opacity-40" clicked={() => setShowFilters(true)}>Filter</Button>
            <Button noStyle={true} withIcon={true} clicked={() => setShowSorters(true)} data-ref="sorters" >
                Sort
                <ChevronBottom />
            </Button>
            
            <Filters 
                showFilters={showFilters}
                onFilter={filter}
                onFilterClose={closeFilters}
                selectedFilters={selectedFilters}
            />

            <Sorters showSorters={showSorters} sorters={sorters}
                onSelectedSorterChange={sort} onSorterClose={closeSorters}
                selectedSorter={selectedSorter}  />
        </div>
    </div>;
};

export default ProgramsPageHeader;