import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionPlansRequest } from 'store/subscriptions/actions';
import { initCookiesConsent } from 'store/util/actions';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { CookiesBanner, Helmet } from 'components';
import { getCookiesConsent } from 'shared/localStorage';
import { getMeta } from 'store/util/selector';

const Layout = ({ children, location, ...props }) => {
    const dispatch = useDispatch();
    const meta = useSelector(getMeta);

    useEffect(() => {
        dispatch(getSubscriptionPlansRequest());
        dispatch(initCookiesConsent(getCookiesConsent()));
    }, [dispatch]);

    return (
        <div className="relative">
            <ToastContainer
                position={toast.POSITION.TOP_CENTER}
                closeOnClick={true}
                autoClose={3400}
                newestOnTop={true}
                transition={Slide} />
            <Helmet url={window.location.origin + window.location.pathname} title={meta[window.location.pathname]?.title} metaDesc={meta[window.location.pathname]?.metaDesc} />
            <div className="bg-black min-h-screen
                             flex flex-auto flex-col items-stretch
                             text-white font-primary tracking-wide font-normal">
                {children}
            </div>
            <CookiesBanner />
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.element,
    location: PropTypes.object,
};

export default withRouter(Layout);
