import axios from 'axios';

import store from 'store/root/store';

import {
    removeIsGuest,
    setIsGuest,
} from './shared/localStorage';
import {
    clearUser,
    removeAccessToken,
    setAccessToken,
    setGuestUser,
} from 'store/user/actions';


const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(
    (config) => {
        if (store.getState().user.accessToken) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${store.getState().user.accessToken}`,
            };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// If response status 401 (forbiden) remove item from localStorage (logout user)
instance.interceptors.response.use(
    (response) => {
        // console.log(response, '__RESPONSE__'); // eslint-disable-line
        if (response.data.error) {
            return Promise.reject(response);
        }
        return response;
    },
    (error) => {
        // console.log(error, '__ERROR__'); // eslint-disable-line
        // console.log(error.response, '__ERROR_RESPONSE__'); // eslint-disable-line
        if (error.response) {
            if (error.response.status === 401 && !store.getState().util.webview.active) {
                return getAccesstoken(error);
            }
        }
        return Promise.reject(error);
    }
);

export function getAccesstoken(error) {
    return instance
        .get('/users/token_refresh', {
            withCredentials: true,
        })
        .then((response) => {
            if (response.data.data.access) {
                // TODO Set AT in redux
                // localStorage.setItem('_k_sqr_at', response.data.data.access);
                store.dispatch(setAccessToken(response.data.data.access));
                if (error) {
                    // Request failed sevice again
                    return instance.request(error.config);
                }
            }
            // Remove is guest flag if AT is successfully aquired
            removeIsGuest();

            return response;
        })
        .catch((e) => {
            // Set is guest in LS
            setIsGuest(true);
            // Set is guest in redux
            store.dispatch(setGuestUser());
            // Set guest token (there might be no need since it will be loaded after redirect)
            // getGuestWSToken(store.dispatch);
            // Delete tokens
            localStorage.removeItem('_k_sqr_at');
            store.dispatch(removeAccessToken());
            // Remove user
            store.dispatch(clearUser());
            return e;
        });
}

export default instance;
