import React from 'react';

import { ReactComponent as Logo } from 'assets/icons/common/logo_2@2x.svg';

const Fallback = ({ text, mobile, children }) => {
    let content;

    switch (text) {
        case 'browser version':
            content =
                'That’s a blunder. Kasparovchess is not yet supported on this version of your browser. Please try with another browser or download our app';
            break;
        case '404':
            content =
                'Oops, that’s a blunder! We couldn’t find what you were looking for';
            break;
        default:
            return '';
    }

    return (
        <>
            {mobile ? (
                <>
                    <div style={{ display: 'none' }}>{children}</div>
                    <div>
                        <div>
                            <Logo />
                        </div>
                        <div>
                            <h4>
                                Stalemate. To use Kasparovchess on a mobile
                                device, you’ll need to download the app, which
                                is available for both iPhone and Android
                                devices.
                            </h4>
                            <div>
                                {/*<a
                                    href="https://apps.apple.com/us/app/kasparovchess/id1533398101"
                                    target="_bank"
                                    rel="noopener noreferrer"
                                ></a>
                                <a href="https://play.google.com/store/apps/details?id=com.keysquare.kasparovchess">
                                    <div />
                                </a>*/}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div style={{ display: 'none' }}>{children}</div>
                    <div>
                        <h1>{content}</h1>
                        {text === 'browser version' && (
                            <div>
                                <div>
                                    <a
                                        href="https://apps.apple.com/us/app/kasparovchess/id1533398101"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div />
                                    </a>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.keysquare.kasparovchess"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div />
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default Fallback;
