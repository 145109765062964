import { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { selectTeachers, selectDifficultyLevels, selectLessonsCategories } from 'store/strapi/selector';
import { withRouter } from 'react-router-dom';
import { getLessonsCategories, getTeachers, getDifficultyLevels } from 'store/strapi/actions';
import { getAllProgramsSelector, getProgramsStateSelector } from 'store/program/selector';
import { getAllPrograms, getMorePrograms } from 'store/program/actions';
import { Page, ProgramPreview } from 'components';
import { Loader, Button } from 'UI';
import { getUser } from 'store/user/selector';
import ProgramsPageHeader from './ProgramsPageHeader';
import { MAIN_URLS } from 'shared/urlList';
import { toast } from 'shared/alerts';

const ProgramsPage = ({ history, location }) => {
    const dispatch = useDispatch();
    const categories = useSelector(selectLessonsCategories, shallowEqual);
    const teachers = useSelector(selectTeachers, shallowEqual);
    const difficultyLevels = useSelector(selectDifficultyLevels, shallowEqual);
    const programs = useSelector(getAllProgramsSelector, shallowEqual);
    const user = useSelector(getUser, shallowEqual);
    const { inProgress, noMoreData, error } = useSelector(getProgramsStateSelector, shallowEqual);

    useEffect(() => {
        if (!programs || !programs.length) {
            dispatch(getAllPrograms());
        }
        if (!categories) {
            dispatch(getLessonsCategories());
        }
        if (!teachers) {
            dispatch(getTeachers());
        }
        if (!difficultyLevels) {
            dispatch(getDifficultyLevels());
        }
    }, []);

    useEffect(() => {
        if (error) {
            toast.error({
                type: 'program',
                action: 'fetch',
                key: 'all'
            });
        }
    }, [error]);

    return <Page wrapperClassName="bg-programs bg-top bg-no-repeat bg-contain bg-fake-black">
        <section className="p-7 flex flex-col gap-8">
            <Loader loading={inProgress ? inProgress : false} isFixed={true} className="bg-opacity-40"/>
            <ProgramsPageHeader />
            <ul className="grid gap-8">
                {programs && programs.map((program, i) => 
                    <ProgramPreview key={i} program={program} />)}
            </ul>
            { !noMoreData ? <Button 
                size="md"
                weight="medium"
                clicked={() => dispatch(getMorePrograms())}
                className="mt-4 border w-2/3 m-auto" bgColor="transparent">Load more</Button> : null }
        </section>
    </Page>;
};

export default withRouter(ProgramsPage);
