import React from 'react';

import { Link } from 'react-router-dom';
import { formatDuration } from 'shared/time';
import PropTypes from 'prop-types';

import { ReactComponent as PlayAlt } from 'assets/icons/player/play@2x.svg';
import { Text, Head, Lock } from 'UI';

const DocumentaryPreview = ({ 
    documentary,
    headingLevel
}) => {
    return (
        <Link to={`/videos/${documentary.id}`} 
            className="flex flex-col mr-5 w-60" onClick={(e) => documentary.locked && e.preventDefault()}>
            <div className="h-40 w-60 bg-contain bg-no-repeat bg-center relative" style={{
                backgroundImage: `url(${documentary.hero_image?.formats?.medium?.url})`
            }}>
                {documentary.locked ?
                    <Lock /> :
                    <div className="absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 h-full z-10">
                        <PlayAlt />
                    </div>
                }
            </div>
            <div className="mt-3">
                <Text size="sm">{documentary.subhead} . { formatDuration(documentary.duration) }</Text>
                <Head level={headingLevel} weight="normal" paddingY={0}>{documentary.title}</Head>
            </div>
        </Link>
    );
};

DocumentaryPreview.propTypes = {
    documentary: PropTypes.object.isRequired,
    headingLevel: PropTypes.number.isRequired,
};

DocumentaryPreview.defaultProps = {
    headingLevel: 2,
};

export default DocumentaryPreview;
