// NAV PAGES
const getStrapiContentBase = (state) => state.strapiContent;
export const getHomePageData = (state) => getStrapiContentBase(state).home;
export const getPlayhubPageData = (state) =>
    getStrapiContentBase(state).playhub.data;
export const getLearnPageData = (state) =>
    getStrapiContentBase(state).learn.data;
export const getWatchPageData = (state) =>
    getStrapiContentBase(state).watch.data;
export const selectTeachers = (state) => getStrapiContentBase(state).teachers;
export const selectDifficultyLevels = (state) => getStrapiContentBase(state).difficultyLevels;

// ARTICLES
export const getAllArticlesSelector = (state) =>
    getStrapiContentBase(state).articles;
export const getSingleArticleSelector = (state) =>
    getStrapiContentBase(state).articles.singleArticle.data;
export const getMostViewedArticleSelector = (state) =>
    getStrapiContentBase(state).articles.allArticles.mostViewed;

// VIDEOS
export const getVideoSelector = (state) =>
    getStrapiContentBase(state).videos;

// LESSONS
export const getLessonsSelector = (state) => getStrapiContentBase(state).lessons;
export const getLessonsCounterSelector = (state) => getLessonsSelector(state).totalCounter;

const selectLessons = state => getStrapiContentBase(state).lessons;
export const selectLessonsTotalCounter = state => selectLessons(state).totalCounter;
export const selectLessonsLimit = state => selectLessons(state).limit;
export const selectLessonsStart = state => selectLessons(state).start;
export const selectLessonsFilters = state => selectLessons(state).filters;
export const selectLessonsSortBy = state => selectLessons(state).sortBy;
export const selectLessonsData = (state) => getLessonsSelector(state).data;
export const selectLessonsProgress = (state) => getLessonsSelector(state).inProgress;
export const selectLessonsNoMoreData = (state) => getLessonsSelector(state).noMoreData;
export const selectLessonsCategories = (state) => getLessonsSelector(state).categories;
export const selectLessonsError = (state) => selectLessons(state).error;

