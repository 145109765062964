import {default as ReactToggle} from 'react-toggle';
import React, { forwardRef } from 'react';

const Toggle = forwardRef(({ 
  label = null, 
  id, 
  defaultCheck = false, 
  onChange,
  disabled = false,
  icons = false,
  ...props
}, ref) => {
  return (
    <>
      <ReactToggle 
        id={id} 
        defaultChecked={defaultCheck}
        onChange={onChange}
        ref={ref}
        disabled={disabled}
        icons={icons}
        {...props} />
      {label && <label htmlFor={id}>{label}</label>}
    </>
  );
});

export default Toggle;