const error = {
    en: {
        'error.user_doesnt_exist': "We can't find that email address",
        'error.user_cant_login':
            "Hey. You still need to confirm your email. Check your inbox for the link. If you think you haven't received it, please contact us at support@keysquare.io",
        'error.user_username_or_password_missing':
            'Username or password missing',
        'error.user_username_exists': 'This username is already taken.',
        'error.User deactivated': 'User deactivated',
        'error.user_wrong_password': 'Password is incorrect. Please try again.',
        "error.Passwords don't match": "Passwords don't match",
        'error.Invalid token': 'Invalid token',
        'error.undefined': 'Something went wrong',
        'error.Forbidden': 'Forbidden',
        'error.user_email_exists': 'That email is already taken',
        'error.user_email_required': 'User email required',
        'error.user_password_required': 'User password required',
        'error.user_invalid_auth_code': 'Invalid auth code',
        'error.user_invalid_token': 'Invalid token',
        'error.user_refresh_token_expired':
            'Your session has expired. Login again.',
        'error.user_passwords_dont_match': "Password you provided dosn't exist",
        'error.password_recovery_invalid_token':
            'Password recovery invalid token',
        'error.email_verification_invalid_token':
            'Email verification invalid token',
        'error.server_error': 'Something went wrong',
        'error.user_username_profane': 'Keep it clean, please',
        'error.input_valid_date': 'Please input a valid date',
        'error.user_username_contains_spaces': "Username can't contain blank spaces",
        'error.user_username_contains_special_characters': "Username can't contain special characters",
        'error.already_subscribed': "This email address is already used for subscription",
    },
};

export default error;
