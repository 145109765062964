import { updateObject } from 'shared/utility';
import * as CONST from './constants';

const initialState = {
    lang: 'en',
};

const localeSet = (state, { lang }) => {
    return updateObject(state, {
        lang,
    });
};

const reducer = (state = localStorage.getItem('_k_sqr_lang') || initialState, action) => {
    switch (action.type) {
        case CONST.LOCALE_SET:
            return localeSet(state, action);
        default:
            return state;
    }
};

export default reducer;
