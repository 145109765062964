import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Head, Button } from 'UI';
import Filters from './Filters';
import Sorters from './Sorters';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ChevronBottom } from 'assets/icons/arrows/arrow_bot.svg';
import { setLessonsFilters, setLessonsSortBy } from 'store/strapi/actions';
import { selectLessonsTotalCounter, selectLessonsSortBy, selectLessonsFilters } from 'store/strapi/selector';

const LessonsPageHeader = () => {
    const [showSorters, setShowSorters] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const totalLessonsCounter = useSelector(selectLessonsTotalCounter);
    const selectedSorter = useSelector(selectLessonsSortBy);
    const selectedFilters = useSelector(selectLessonsFilters);

    const [sorters] = useState([
        { id: 1, name: 'Alphabetically', key: 'title' },
        { id: 2, name: 'Release date', key: 'created_at' },
        { id: 3, name: 'Availability - Free to Pro', key: 'subscription_level' }
    ]);

    const dispatch = useDispatch();

    const filter = (filters) => {
        setShowFilters(false);
        dispatch(setLessonsFilters(filters));
    };

    const sort = (selectedSorter) => {
        setShowSorters(false);
        dispatch(setLessonsSortBy(selectedSorter));
    };

    const closeFilters = () => {
        setShowFilters(false);
    };

    const closeSorters = () => {
        setShowSorters(false);
    };

    return (
        <section>
            <div className="divide-y divide-white divide-opacity-20 p-4">
                <Head className="text-center mb-4" size="lg">{totalLessonsCounter} Lessons</Head>
                <div className="flex justify-between pt-4 relative">
                    <Button size="sm" bgColor="transparent" data-ref="filters"
                        className="border border-white border-opacity-40" clicked={() => setShowFilters(true)}>Filter</Button>
                    <Button noStyle={true} withIcon={true} clicked={() => setShowSorters(true)} data-ref="sorters" >
                        Sort
                        <ChevronBottom />
                    </Button>
                    
                    <Filters 
                        showFilters={showFilters}
                        // onSelectedFiltersChange={onSelectedFiltersChange}
                        selectedFilters={selectedFilters} 
                        onFilter={filter}
                        onFilterClose={closeFilters}
                    />

                    <Sorters showSorters={showSorters} sorters={sorters}
                        onSelectedSorterChange={sort} onSorterClose={closeSorters}
                        selectedSorter={selectedSorter}  />
                </div>
            </div>
        </section>
    );
};

LessonsPageHeader.propTypes = {
    lessonsCount: PropTypes.number
};

LessonsPageHeader.defaultProps = {
    lessonsCount: 0
};

export default LessonsPageHeader;
