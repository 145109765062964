import PropTypes from 'prop-types';

import AppleBadge from 'assets/images/app_store_badge.png';
import GoogleBadge from 'assets/images/google_play_badge.png';

import { Head, Text } from 'UI';

const DownloadApp = ({ headingLevel }) => {
    return (
        <div className="bg-app-mobile sm:bg-app-desktop p-8">
            <Head level={headingLevel} size="2xl" className="py-8" weight="medium">Get<br />the app</Head>
            <Text className="tracking-wide">Learn and improve<br/>everywhere thanks to our iOS<br/>and Android apps.</Text>
            <div className="flex flex-col my-8 gap-2">
                <a href="https://apps.apple.com/us/app/kasparovchess/id1533398101" 
                    target="_blank" title="Link to App Store" rel="noreferrer">
                    <img
                        src={AppleBadge}
                        alt="Download app on Apple Store"
                        className="block mx-auto"
                    />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.keysquare.kasparovchess&hl=fr&gl=US" 
                    target="_blank" title="Link to Google Play" rel="noreferrer">
                    <img
                        src={GoogleBadge}
                        alt="Download app on Google Play"
                        className="block mx-auto"
                    />
                </a>
            </div>
        </div>
    );
};

DownloadApp.propTypes = {
    headingLevel: PropTypes.number,
};

DownloadApp.defaultProps = {
    headingLevel: 2,
};

export default DownloadApp;
