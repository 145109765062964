import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DailyMotion from 'react-dailymotion';
import ReactPlayer from 'react-player';

// import { getCookiesConsent } from 'store/util/selector';
// import { useSelector, useDispatch, shallowEqual } from 'react-redux';

function VideoPlayer({
    video,
    autoplay,
    width,
    height,
    uiHighlightColor,
    showQueue,
    autoplayQueue,
    sharing,
    start,
    mute,
    onVideoEnd,
	subtitles,
	controls
}) {
    // const cookieConsent = useSelector(getCookiesConsent, shallowEqual);
    // const dispatch = useDispatch();
    // 
    const [fullscreen, setFullscreen] = useState(false);
    const [playing, setPlaying] = useState(false);
    const playerRef = createRef();

    const ready = () => {
        if(playerRef.current) {
            const ip = playerRef.current.getInternalPlayer();
            ip.addEventListener('fullscreenchange', (e) => {
                e.preventDefault();
                if (!e.returnValue) {
                    setFullscreen(false);
                }
            })
        }
    };
    const clickOnPlayer = () => {
        if (!playing) {
            setPlaying(true); 
            setFullscreen(true);
        } else {
            setPlaying(false);
        }
    };

    const toggleFullscreen = () => {
        setFullscreen(!fullscreen);
    };

    return (
        <div style={{ minHeight: "100px" }} className={`w-full ${fullscreen ? 'fixed h-screen z-50 top-0 bottom-0 left-0 right-0' : 'relative'}`}>
            {/*{ cookieConsent?.media ? */}
           {/*     <DailyMotion
                    video={`https://www.dailymotion.com/video/x87hxid`}
                    uiShowLogo={false}
                    uiHighlightColor={uiHighlightColor}
                    showQueue={showQueue}
                    autoplayQueue={autoplayQueue}
                    onVideoEnd={onVideoEnd}
                />*/}
                {!fullscreen && <div 
                    onClick={clickOnPlayer}
                    className="absolute h-full w-full z-10"></div>}
                <ReactPlayer
                    url={`https://www.dailymotion.com/video/${video}`}
                    controls={controls}
                    playing={playing}
                    muted={mute}
                    width={width}
                    height={height}
                    config={{dailymotion: {
                        start: start,
                        sharingEnable: sharing,
                        autoplay: autoplay,
                        subtitlesDefault: subtitles,
                    }}}
                    // onStart={() => setPlaying(true)}
                    // onPause={() => setPlaying(false)}
                    onEnded={() => {toggleFullscreen();setPlaying(false);}}
                    onReady={ready}
                    ref={playerRef}
                />
                <div className="fullscreen-override absolute bottom-0 right-0 h-10 w-10" 
                    onClick={toggleFullscreen}></div>
{/*            : <div className="text-center px-8 py-4 bg-white">
                <ConsentButton category="media" serviceName="Dailymotion" />
            </div>}*/}
        </div>
    );
}

VideoPlayer.propTypes = {
    video: PropTypes.string.isRequired,
    autoplay: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    start: PropTypes.number,
    showQueue: PropTypes.bool,
    autoplayQueue: PropTypes.bool,
};

VideoPlayer.defaultProps = {
    autoplay: false,
    width: '100%',
    height: '100%',
    uiHighlightColor: '#7446FF',
    sharing: true,
	subtitles: 'en',
	controls: true,
};

export default VideoPlayer;
