import { useEffect } from 'react';

// Fn for unmutable object cloning
export const updateObject = (oldObject, newProps) => {
    return {
        ...oldObject,
        ...newProps,
    };
};

// Seconds to HH:mm:ss
export const toHHMMSS = (seconds) => {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
};

export const truncate = (str, n) => {
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
};

// Validation check Fn
export const checkValidity = (value, rules) => {
    const val = {
        isValid: true,
        message: '',
    };

    if (!rules) {
        return val;
    }

    if (rules.required) {
        if (value) {
            val.isValid = value.trim() !== '' && val.isValid;
        } else {
            val.isValid = false;
        }
        if (val.message === '' && !val.isValid) {
            val.message = 'This field is required.';
        }
    }

    if (rules.isChecked) {
        if (!value) {
            val.isValid = false;
            val.message = 'You must select this checkbox';
        }
    }

    if (rules.isNumeric) {
    	const pattern = /^-?[0-9]+$/;
    	val.isValid = pattern.test(value) && val.isValid;
    	if (val.message === '' && !val.isValid) {
    		val.message = 'Numbers only.';
    	}
    }

    if (rules.isPositiveNumber) {
    	const pattern = /^[0-9]+$/;
    	val.isValid = pattern.test(value) && val.isValid && value >= 0;
    	if (val.message === '' && !val.isValid) {
    		val.message = 'Positive numbers only.';
    	}
    }
    
    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        val.isValid = pattern.test(value) && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'Email format is invalid.';
        }
    }

    if (rules.isPassword) {
        const pattern = RegExp(
            `^(?=.*[0-9~!@#$%^&*]).{${rules.minLength || 8},${rules.maxLength ||
                20}}$`
        );
        val.isValid = pattern.test(value) && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = `Password length must be between ${rules.minLength || 8} and ${rules.maxLength || 20} and have at least one number and one special character (~!@#$%^&*).`;
        }
    }

    if (rules.isNoSpace) {
        const pattern = /^[\S]+$/;
        val.isValid = pattern.test(value) && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'Username can\'t contain white spaces.';
        }
	}

    if (rules.isNoSpecial) {
        const pattern = /^[a-zA-Z0-9.'_-]+$/;
        val.isValid = pattern.test(value) && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'Username can\'t contain special characters.';
        }
    }

    if (rules.isName) {
        const pattern = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
        val.isValid = pattern.test(value) && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'Name can\'t contain special characters.';
        }
    }

    if (rules.isPhone) {
    	const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    	val.isValid = pattern.test(value) && val.isValid;
    	if (val.message === '' && !val.isValid) {
    		val.message = 'Phone number is invalid.';
    	}
    }

    if (rules.minLength) {
        val.isValid = value.length >= rules.minLength && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = `Minimum length of ${rules.minLength} is not met.`;
        }
    }

    if (rules.maxLength) {
        val.isValid = value.length <= rules.maxLength && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = `Maximum length of ${rules.maxLength} exceeded.`;
        }
    }

    if (rules.minValue) {
        val.isValid = parseInt(value) >= rules.minValue && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = `Minimum value of ${rules.minValue} is not met.`;
        }
    }

    if (rules.maxValue) {
        val.isValid = parseInt(value) <= rules.maxValue && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = `Maximum value of ${rules.minValue} exceeded.`;
        }
    }

    return val;
};

export const auth = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

export const emptyFunction = () => {};

// get browser name, version and OS
export const getBrowserInfo = () => {
    // eslint-disable-next-line prefer-const
    let unknown = '-';

    // screen
    let screenSize = '';
    if (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    ) {
        const width =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth
                ? window.innerWidth
                : '';
        const height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight
                ? window.innerHeight
                : '';
        screenSize += '' + width + ' x ' + height;
    }

    // browser
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browser = navigator.appName;
    let version = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // Opera
    if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf('Version')) !== -1) {
            version = nAgt.substring(verOffset + 8);
        }
    }
    // Opera Next
    if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 4);
    }
    // Legacy Edge
    else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
        browser = 'Microsoft Legacy Edge';
        version = nAgt.substring(verOffset + 5);
    }
    // Edge (Chromium)
    else if ((verOffset = nAgt.indexOf('Edg')) !== -1) {
        browser = 'Microsoft Edge';
        version = nAgt.substring(verOffset + 4);
    }
    // MSIE
    else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(verOffset + 5);
    }
    // Chrome
    else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
        browser = 'Chrome';
        version = nAgt.substring(verOffset + 7);
    }
    // Safari
    else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
        browser = 'Safari';
        version = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf('Version')) !== -1) {
            version = nAgt.substring(verOffset + 8);
        }
    }
    // Firefox
    else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
        browser = 'Firefox';
        version = nAgt.substring(verOffset + 8);
    }
    // MSIE 11+
    else if (nAgt.indexOf('Trident/') !== -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(nAgt.indexOf('rv:') + 3);
    }
    // Other browsers
    else if (
        (nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))
    ) {
        browser = nAgt.substring(nameOffset, verOffset);
        version = nAgt.substring(verOffset + 1);
        if (browser.toLowerCase() === browser.toUpperCase()) {
            browser = navigator.appName;
        }
    }
    // trim the version string
    if ((ix = version.indexOf(';')) !== -1) {
        version = version.substring(0, ix);
    }
    if ((ix = version.indexOf(' ')) !== -1) {
        version = version.substring(0, ix);
    }
    if ((ix = version.indexOf(')')) !== -1) {
        version = version.substring(0, ix);
    }

    majorVersion = parseInt('' + version, 10);
    if (isNaN(majorVersion)) {
        version = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    // mobile version
    const mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    // cookie
    let cookieEnabled = navigator.cookieEnabled ? true : false;

    if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
        document.cookie = 'testcookie';
        cookieEnabled =
            document.cookie.indexOf('testcookie') !== -1 ? true : false;
    }

    // system
    let os = unknown;
    const clientStrings = [
        { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        {
            s: 'Windows NT 4.0',
            r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/,
        },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Chrome OS', r: /CrOS/ },
        { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        {
            s: 'Search Bot',
            r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
        },
    ];
    for (const id in clientStrings) {
        const cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
            os = cs.s;
            break;
        }
    }

    let osVersion = unknown;

    if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        os = 'Windows';
    }

    switch (os) {
        case 'Mac OS':
        case 'Mac OS X':
        case 'Android':
            // eslint-disable-next-line no-useless-escape
            osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(
                nAgt
            )[1];
            break;

        case 'iOS':
            osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
            osVersion =
                osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
            break;
        default:
    }

    return {
        screen: screenSize,
        browser: browser,
        browserVersion: version,
        browserMajorVersion: majorVersion,
        mobile: mobile,
        os: os,
        osVersion: osVersion,
        cookies: cookieEnabled,
    };
};

export const useClickOutside = (ref, handler) => {
	useEffect(() => {
		const listener = (e) => {
			if (!ref.current || ref.current.contains(e.target)) {
				return;
			}

			handler(e);
		};

		document.addEventListener('click', listener);

		return () => {
			document.removeEventListener('click', listener);
		};
	}, [ref, handler]);
};
